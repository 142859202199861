import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIconProps, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { ITheme } from 'src/app/App';
import menuConfig from './config';

interface MenuConfigItem { id: string, title: string, href: string, exact?: boolean, icon: (props: SvgIconProps) => JSX.Element }

const useStyles = makeStyles((theme: ITheme) => ({
  list: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    listStyle: 'none',
    '& > *': {
      marginLeft: `${theme.spacing(0.25)}rem`,
    },
    '& > *:first-child': {
      marginLeft: 0,
    },
  },
  item: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    textDecoration: 'none',
    color: theme.palette.grey[600],
    '& *': {
      display: 'inline',
    },
    '&.active': {
      color: `${theme.palette.typical.accent} !important`,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const Menu = () => {
  const classes = useStyles();

  return (
    <ul className={classes.list}>
      {menuConfig.map(({ id, title, href, exact, icon: IconComponent }: MenuConfigItem) => (
        <li key={id}>
          <NavLink
            className={classes.item}
            exact={exact}
            data-seleniumid={`menu-button-${id}`}
            to={href}
          >
            <IconComponent className={classes.icon} />
            <Typography>
              {title}
            </Typography>
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
export default Menu;
