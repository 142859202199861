import React, { useRef, memo, useCallback, FC, ChangeEvent } from 'react';
import debounce from 'p-debounce';
import { TextField } from '@material-ui/core';
import { ITheme } from 'src/app/App';
import { makeStyles } from '@material-ui/core/styles';
import { VALIDATION_DELAY } from 'src/app/constants';
import { EMPTY_HEADER, INVALID_HEADER } from '../constants';
import { ICell } from '../interfaces';
import CellWrap from './CellWrap';

const useStyles = makeStyles((theme: ITheme) => ({
  input: {
    backgroundColor: theme.palette.typical.white,
  },
}));

export interface IHeaderCell extends ICell {
  onChange(id: string, value: ICell['value'], meta: ICell['meta']): void;
  onRemove(id: string): void;
  isDragging: boolean,
  index: number,
  isTheOnlyHeader: boolean
}

const HeaderCell: FC<IHeaderCell> = (props) => {
  const { id, meta, value, onChange, onRemove, isDragging, index, isTheOnlyHeader } = props;
  const classes = useStyles();
  const onRemoveHeader = useCallback(() => onRemove(id), [id]);
  const validate = useRef(debounce((newValue: string) => {
    let error = null;
    if (!newValue) error = EMPTY_HEADER;
    if (newValue.length > 500) error = INVALID_HEADER;

    if (error) onChange(id, newValue, { error });
  }, VALIDATION_DELAY)).current;

  const { error, pristine } = meta;
  const seleniumId = `header-cell-${index}`;

  return (
    <CellWrap
      seleniumId={seleniumId}
      onRemove={isTheOnlyHeader ? undefined : onRemoveHeader}
      error={error}
      isDragging={isDragging}
    >
      <TextField
        className={classes.input}
        error={!!error}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const inputValue = e.target.value || '';
          if (inputValue !== value) {
            onChange(id, inputValue, { pristine: false, error: null });
            validate(inputValue);
          }
        }}
        variant="outlined"
        fullWidth
        required
        multiline
        id="header"
        label="Header"
        onFocus={(e) => {
          const inputValue = e.target.value || '';
          if (!(inputValue === value && !pristine)) {
            onChange(id, value, { pristine: false });
          }
        }}
        onBlur={() => !value && validate(value)}
        data-seleniumid={seleniumId}
      />
    </CellWrap>
  );
};

export default memo(HeaderCell);
