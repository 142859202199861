import { logout, getIsAuthorized } from 'src/app/pages/Login/redux/AuthDucks';

export const SUCCESS = '_SUCCESS';
export const FAILURE = '_FAILURE';

const createActionCreator = actionType => type => ({ payload, ...params }) => ({
  type: `${type}${actionType}`,
  payload,
  ...params,
});

export const successActionCreator = createActionCreator(SUCCESS);
export const failureActionCreator = createActionCreator(FAILURE);

const asyncMiddleware = ({ defaultTransformError }) => ({ getState }) => next => async action => {
  const { type, payload, asyncCall, selfCall = true, transformResult, transformError = defaultTransformError, ...params } = action;
  const state = getState();
  if (!asyncCall || !getIsAuthorized(state)) {
    return next(action);
  }

  const successAction = successActionCreator(type);
  const failAction = failureActionCreator(type);

  const [call, url] = asyncCall();
  if (selfCall) {
    next({ type, payload, ...payload, url });
  }

  try {
    const res = await call;

    return next(
      successAction({
        ...payload,
        ...params,
        payload: transformResult ? transformResult(res) : res,
        url,
      }),
    );
  }
  catch (e) {
    // eslint-disable-next-line no-console
    console.log('asyncMiddleware', e);

    if (e?.response?.status === 401) {
      return next(logout(window.location.pathname));
    }
    return next(
      failAction({
        ...payload,
        ...params,
        payload: transformError ? transformError(e) : e,
        url,
      }),
    );
  }
};

export default asyncMiddleware;
