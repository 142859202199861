import { SUCCESS, FAILURE } from 'src/app/redux/asyncMiddleware';
import _ from 'lodash';
import { fetchJournals } from 'src/app/redux/api';
import { Journal } from 'src/mock/db';
import { NO_ACTION_MESSAGE, TRY_LATER } from '../../constants';

export const initialState = {
  isLoading: false,
  error: null,
  items: [],
};

export const LOAD_JOURNALS = 'LOAD_JOURNALS';

export default (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_JOURNALS:
      return { ...state, error: null, isLoading: true };
    case LOAD_JOURNALS + SUCCESS:
      return { items: payload, error: null, isLoading: false };
    case LOAD_JOURNALS + FAILURE:
      return { items: [], error: payload, isLoading: false };
    default:
      return state;
  }
};

export const loadJournals = () => ({
  type: LOAD_JOURNALS,
  asyncCall: () => fetchJournals(),
  transformError: (e:any) => (typeof e === 'string' ? e : e?.response?.data?.message || NO_ACTION_MESSAGE('load journals. ') + TRY_LATER),
});

export const getJournals = (state: any): Journal[] => state.journals.items;
export const getJournalsByIds = (state: any, journalsIds: string[]): Journal[] => {
  if (!journalsIds.length) return [];
  const journals = getJournals(state);
  return _.compact(journalsIds.map((id: string) => journals.find((journal: Journal) => journal.id === id)));
};
export const getJournal = (id: string | null) => (state: any) => (id ? (state.journals.items.find((journal: Journal) => journal.id === id) || null) : null);
export const getJournalsIsLoading = (state: any) => state.journals.isLoading;
export const getJournalsError = (state: any) => state.journals.error;
