import React, { memo, useCallback, useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldId } from '../interfaces';

const useStyles = makeStyles(theme => ({
  errorHelpText: {
    color: theme.palette.error.main,
  },
}));

interface IBaseTextField {
  id:FieldId;
  title:string;
  rows?:number;
  required?:boolean;
  value?:string;
  error?:boolean;
  valueLimit?:number;
  validate:(id:FieldId, value:string) => void;
  onChange:(field: FieldId, value: string) => void;
}

const BaseTextField = memo(({ id, error, value = '', validate, onChange, valueLimit, required = false, rows = 1, title }: IBaseTextField) => {
  const { errorHelpText } = useStyles();

  const change = useCallback((e) => {
    const value = e.target.value || '';
    onChange(id, value);
    validate(id, value);
  }, [onChange]);

  const helperProps = useMemo(() => {
    if (!valueLimit) return {};
    return {
      helperText: value.length > valueLimit ? `Max Title length (${valueLimit}) is exceeded` : `${value.length}/${valueLimit}`,
      FormHelperTextProps: value.length > valueLimit ? { className: errorHelpText } : {},
    };
  }, [valueLimit, value]);

  return (
    <TextField
      error={error}
      onChange={change}
      variant="outlined"
      required={required}
      rows={rows}
      value={value}
      fullWidth
      multiline
      id={id}
      label={title}
      inputProps={{ 'data-seleniumid': `${id}-input` }}
      {...helperProps}
    />
  );
});

export default BaseTextField;
