import { takeLatest, call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { SET_NETWORK } from 'src/app/redux/reducers/network';
import { fetchDetailedIssues, fetchTOC, getArticles } from 'src/app/redux/api';
import { FAILURE, SUCCESS } from 'src/app/redux/asyncMiddleware';
import { NO_ACTION_MESSAGE, TRY_LATER } from 'src/app/constants';
import { SAVE_ARTICLES } from 'src/app/redux/reducers/articles';
import { LOAD_TOC } from '../Tabs/TOC/redux/tocDucks';
import { LOAD_DETAILED_ISSUE } from './detailedIssesDucks';
import { getArticleDataFromHTM } from '../Tabs/TOC/utils';

interface ActionArg {
  payload: string;
  type: string;
}

export function* loadArticles(toc: string): SagaIterator {
  const articlesFromTOC = getArticleDataFromHTM(toc);
  const articlesWithoutTitles  = articlesFromTOC.filter(({ title }) => !title);
  const articlesWithTitles  = articlesFromTOC.filter(({ title }) => title)
    .map(({ doi, title: name }) => ({ doi, name }));

  if (!articlesWithoutTitles?.length) return;
  try {
    const [promise] = yield call(getArticles, articlesWithoutTitles.map(({ doi }) => doi));
    const articles = yield promise;
    yield put({ type: SAVE_ARTICLES, payload: [...articles, ...articlesWithTitles] });
  }
  catch (e) {
    // eslint-disable-next-line no-console
    console.log("couldn't load articles");
  }
}

function* loadTOC(id: string): SagaIterator {
  let url;
  try {
    const [promise, urlTOC] = yield call(fetchTOC, id);
    url = urlTOC;
    yield put({ type: SET_NETWORK, url });
    const toc = yield promise;

    yield call(loadArticles, toc);

    yield put({ type: LOAD_TOC + SUCCESS, payload: { id, toc }, url });
  }
  catch (e) {
    const error = e.response?.data?.message || NO_ACTION_MESSAGE('load TOC. ') + TRY_LATER;
    yield put({ type: LOAD_TOC + FAILURE, payload: error, url });
  }
}

export function* loadIssue({ payload }: ActionArg): SagaIterator {
  const [promise, url] = yield call(fetchDetailedIssues, payload);

  try {
    yield put({ type: SET_NETWORK, url });
    const vi = yield promise;

    const tocId: string = vi?.hasTableOfContents?.id;
    yield call(loadTOC, tocId);

    yield put({ type: LOAD_DETAILED_ISSUE + SUCCESS, payload: vi, url });
  }
  catch (e) {
    const error = e.response?.data?.message || NO_ACTION_MESSAGE('load virtial issue. ') + TRY_LATER;
    yield put({ type: LOAD_DETAILED_ISSUE + FAILURE, payload: error, url });
  }
}

export function* watchLoadIssueManager() {
  yield takeLatest(LOAD_DETAILED_ISSUE, loadIssue);
}
