import React from 'react';
import { Typography } from '@material-ui/core';

const NotFound = () => (
  <>
    <Typography variant="h1" align="center">
      404
    </Typography>
    <Typography variant="body1" align="center">
      Page not found.
    </Typography>
  </>
);

export default NotFound;
