import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import paths from 'src/app/router/paths';
import { ITableCell } from 'src/app/components/Table/Interfaces';


const useStyles = makeStyles({
  root: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
});

const TitleCell: FC<ITableCell> = ({ id, value }) => {
  const classes = useStyles();
  return (
    <Link
      to={{
        pathname: generatePath(paths.society, { id }),
      }}
      data-seleniumid={`${id}-link`}
      className={classes.root}
    >
      {value}
    </Link>
  );
};

export default TitleCell;
