import React from 'react';
import { ITableRow } from 'src/app/components/Table/Interfaces';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  editButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
    '&:hover': {
      borderColor: '#55AA8D',
    },
  },
});

const UserActionCell = (props: ITableRow) => {
  const classes = useStyles();
  const { value: { participantId, email, id } } = props;
  const history = useHistory();
  return (
    !id ? (
      <Button
        fullWidth
        variant="contained"
        data-seleniumid={`add-user-${participantId || email}`}
        color="primary"
        disableElevation
        onClick={(e) => {
          e.stopPropagation();
          history.push(`/users/create/${participantId || email}`);
        }}
      >
        Add
      </Button>
    ) : (
      <Button
        className={classes.editButton}
        fullWidth
        variant="outlined"
        data-seleniumid={`edit-user-${id || participantId || email}`}
        color="primary"
        onClick={(e) => {
          e.stopPropagation();
          history.push(`/users/edit/${id || participantId || email}`);
        }}
      >
        Edit
      </Button>
    )
  );
};

export default UserActionCell;
