import { Action } from 'redux';
import { fetchUsers, patchUser, postUser } from 'src/app/redux/api';
import { RootState } from 'src/app/redux/store';
import { BaseUser, ISocietyIdentifier, User, VirtualIssue } from 'src/mock/db';
import { NO_ACTION_MESSAGE, TRY_LATER } from 'src/app/constants';
import { SortDirection } from 'src/app/components/Table/Interfaces';

export const LOAD_USERS: 'LOAD_USERS' = 'LOAD_USERS';
export const LOAD_USERS_SUCCESS: 'LOAD_USERS_SUCCESS' = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_BY_ID: 'LOAD_USERS_BY_ID' = 'LOAD_USERS_BY_ID';
export const LOAD_USERS_BY_ID_SUCCESS: 'LOAD_USERS_BY_ID_SUCCESS' = 'LOAD_USERS_BY_ID_SUCCESS';
export const LOAD_USERS_FAILURE: 'LOAD_USERS_FAILURE' = 'LOAD_USERS_FAILURE';
export const LOAD_USERS_BY_ID_FAILURE: 'LOAD_USERS_BY_ID_FAILURE' = 'LOAD_USERS_BY_ID_FAILURE';
export const CREATE_USER: 'CREATE_USER' = 'CREATE_USER';
export const CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS' = 'CREATE_USER_SUCCESS';
export const UPDATE_USER: 'UPDATE_USER' = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS' = 'UPDATE_USER_SUCCESS';
export const UPDATE_LIST_OPTIONS: 'UPDATE_LIST_OPTIONS' = 'UPDATE_LIST_OPTIONS';

export interface UsersState {
  rowsPerPage: number;
  currentPage: number;
  sortField: keyof Pick<User, 'firstName' | 'lastName'>;
  sortDir: SortDirection;
  isLoading: boolean;
  error: string | null;
  items: BaseUser[];
  totalItemsCount: number;
}

export const defaultStats = {
  rowsPerPage: 25,
  currentPage: 0,
  sortField: 'lastName' as keyof Pick<User, 'firstName' | 'lastName'>,
  sortDir: 'asc' as SortDirection,
};

export const initialState = {
  ...defaultStats,
  isLoading: false,
  error: null,
  items: [],
  totalItemsCount: 0,
};

export interface LoadUsersPayload {
  email?: string;
  size?: number;
  offset?: number;
}

export type LoadUsersAction = Action<typeof LOAD_USERS>;
export type LoadUsersFailAction = Action<typeof LOAD_USERS_FAILURE>;

export interface LoadUsersSuccessAction extends Action<
typeof LOAD_USERS_SUCCESS | typeof LOAD_USERS_BY_ID_SUCCESS
> {
  payload: {
    items: UsersState['items'],
    totalItemsCount: number
  }
}
export const loadUsers: (payload?: LoadUsersPayload | null) => LoadUsersAction = (
  payload = {},
) => ({
  type: LOAD_USERS,
  asyncCall: () => fetchUsers(payload),
  transformResult: ({ items = [], itemsCount = 0 }: { items: VirtualIssue[], itemsCount: number }) => ({
    items,
    totalItemsCount: itemsCount,
  }),
});

export interface LoadUsersByIdAction extends Action<typeof LOAD_USERS_BY_ID> {
  payload: string[],
}
export const loadUsersById: (payload: LoadUsersByIdAction['payload']) => LoadUsersByIdAction = (
  payload,
) => ({
  type: LOAD_USERS_BY_ID,
  payload,
});

export interface CreateUserPayload extends Pick<
BaseUser,
'userId' | 'state' | 'role' | 'participantId' | 'email'
> {
  societies: Omit<ISocietyIdentifier, 'name'>[];
}

export interface UpdateUserPayload extends Pick<
BaseUser,
'id' | 'state' | 'role'
> {
  societies: Omit<ISocietyIdentifier, 'name'>[];
}

export type UpdateListOptionsPayload = Partial<Omit<UsersState, 'items'>>

export type CreateUserAction = Action<typeof CREATE_USER>;
export type UpdateUserAction = Action<typeof UPDATE_USER>;
export type UpdateListOptions = Action<typeof UPDATE_LIST_OPTIONS>;

export interface CreateUserSuccessAction extends Action<typeof CREATE_USER_SUCCESS> {
  payload: BaseUser;
}
export interface UpdateUserSuccessAction extends Action<typeof UPDATE_USER_SUCCESS> {
  payload: BaseUser;
}
export interface UpdateListOptionsAction extends Action<typeof UPDATE_LIST_OPTIONS> {
  payload: UpdateListOptionsPayload;
}

export const createUser: (payload: CreateUserPayload) => CreateUserAction = (
  payload,
) => ({
  type: CREATE_USER,
  asyncCall: () => postUser(payload),
  transformError: () => NO_ACTION_MESSAGE('create user. ') + TRY_LATER,
});


export const updateUser: (payload: UpdateUserPayload) => UpdateUserAction = (
  payload,
) => ({
  type: UPDATE_USER,
  asyncCall: () => patchUser(payload),
  transformError: () => NO_ACTION_MESSAGE('update user. ') + TRY_LATER,
});

export const updateListOptions: (payload: UpdateListOptionsPayload) => UpdateListOptions = (
  payload,
) => ({
  type: UPDATE_LIST_OPTIONS,
  payload,
});

export default (
  state: UsersState = initialState,
  action?: LoadUsersFailAction
  | LoadUsersSuccessAction
  | CreateUserSuccessAction
  | UpdateUserSuccessAction
  | UpdateListOptionsAction,
) => {
  switch (action?.type) {
    case LOAD_USERS_FAILURE: {
      return {
        ...state,
        items: [],
      };
    }
    case LOAD_USERS_BY_ID_SUCCESS: {
      const result = { ...state,
        items: [
          ...(state?.items?.length ? state.items : []),
          ...(action.payload?.items?.length ? action.payload?.items : []),
        ],
        totalItemsCount: action.payload.totalItemsCount,
      };
      if (result.currentPage * result.rowsPerPage > action.payload.totalItemsCount) {
        result.currentPage = 0;
      }
      return result;
    }
    case LOAD_USERS_SUCCESS: {
      const result = { ...state,
        items: action.payload.items,
        totalItemsCount: action.payload.totalItemsCount,
      };
      if (result.currentPage * result.rowsPerPage > action.payload.totalItemsCount) {
        result.currentPage = 0;
      }
      return result;
    }
    case UPDATE_USER_SUCCESS:
    case CREATE_USER_SUCCESS: {
      const items = state.items.map((user) => (
        user.email === action.payload.email
          ? action.payload
          : user
      ));
      return {
        ...state,
        items,
      };
    }
    case UPDATE_LIST_OPTIONS: {
      return { ...state, ...action.payload };
    }
    default: return state;
  }
};

export const getUsers = (state: RootState): BaseUser[] => state.users.items || [];
export const getUsersById = (usersIds: string[]) => (state: RootState): BaseUser[] => (
  usersIds.length
    ? getUsers(state).filter((user: BaseUser) => usersIds.includes(user.id || user.participantId as string))
    : []
);
export const getUsersByEmail = (email: string) => (state: RootState): BaseUser[] => (
  email
    ? getUsers(state).filter((user: BaseUser) => email === user.email)
    : []
);
export const getUserListOptions = ({ users: { items, ...filters } }: RootState): Omit<UsersState, 'items'> => filters;
export const getUsersTotalCount = (state: any) => state.users.totalItemsCount;
