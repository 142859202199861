import React, { useState, useMemo, useEffect, FC } from 'react';
import { useSelector } from 'react-redux';
import { getJournalsByIds, getJournalsIsLoading } from 'src/app/redux/reducers/journals';
import Table from 'src/app/components/Table';
import { ITable, SortDirection } from 'src/app/components/Table/Interfaces';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { Journal } from 'src/mock/db';

interface ISocietyJournals {
  ids: string[],
}

const EMPTY_MESSAGE = 'There are no journals';

const getTableConfig = (journals: Record<string, Journal>) => [
  {
    field: 'id',
    title: 'JID',
    sortable: true,
    dataModifier: (id: string) => journals[id].productCode || id,
  },
  { field: 'name', title: 'Name', sortable: true },
];

const useTableStyles = makeStyles({
  table: {
    tableLayout: 'auto',
  },
});

const SocietyJournals:FC<ISocietyJournals> = ({ ids }) => {
  const journals = useSelector(state => getJournalsByIds(state, ids));
  const journalsHash = useMemo(() => _.keyBy(journals, 'id'), [journals]);
  const journalsIsLoading = useSelector(getJournalsIsLoading);
  const [sortDir, setSortDir] = useState('asc' as SortDirection);
  const [sortField, setSortField] = useState('name');

  const classes = useTableStyles();

  const items = useMemo(
    () => (_.orderBy(journals, sortField, sortDir)),
    [journals, sortDir],
  );

  useEffect(() => setSortDir('asc'), [sortField]);

  const onOrderChange = (field: string) => {
    setSortField(field);
    setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
  };

  const tableProps: ITable = {
    seleniumId: 'society-view-journals',
    classes,
    topPaginationDisplay: false,
    totalItemsCount: journals.length,
    columns: getTableConfig(journalsHash),
    items,
    isLoading: journalsIsLoading,
    sortDir,
    onOrderChange,
    sortField,
  };

  if (!journalsIsLoading && !journals.length) tableProps.error = EMPTY_MESSAGE;

  return <Table {...tableProps} />;
};

export default SocietyJournals;
