import React from 'react';

import { ReactComponent as Logo } from 'src/app/assets/logo-v1.svg';

export default (props: any) => (
  <Logo
    fill="#000"
    width="182"
    height="96"
    {...props}
  />
);
