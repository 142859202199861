import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import createMuiTheme, { ThemeOptions, Theme } from '@material-ui/core/styles/createMuiTheme';
import CssBaseline from '@material-ui/core/CssBaseline';
import PrivateRoute from 'src/app/router/PrivateRoute';
import Footer from 'src/app/components/layout/Footer';
import { routes } from 'src/app/router/routes';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'src/app/redux/store';
import ReduxToastr from 'react-redux-toastr';
import { Palette } from '@material-ui/core/styles/createPalette';

interface IPalette extends Palette {
  typical: {
    main: string;
    accent: string;
    white: string;
    black: string;
    fieldset: string;
  },
  chip: {
    color: string;
    expandIcon: {
      background: string;
      color: string;
    };
  },
}

export interface ITheme extends Theme {
  palette: IPalette;
  [key: string]: any;
}
interface IThemeOptions extends ThemeOptions {
  palette: IPalette;
  [key: string]: any;
  overrides: any;
}

export const theme = createMuiTheme({
  typography: {
    fontWeightMedium: 600,
    fontFamily: ['Open Sans', 'Arial', 'sans-serif'].join(','),
  },
  palette: {
    typical: {
      main: '#465151',
      accent: '#135AE1',
      white: '#FFFFFF',
      black: '#000000',
      fieldset: '##BDBDBD',
    },
    primary: {
      main: '#7AF3CA',
    },
    secondary: {
      main: '#43C099',
    },
    error: {
      main: '#D42C2C',
    },
    background: {
      default: '#F5F5F5',
    },
    chip: {
      color: '#C2C3C6',
      expandIcon: {
        background: '#808183',
        color: '#FFFFFF',
      },
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#43C099',
        },
        '&$disabled': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          '& input': {
            color: 'rgba(0, 0, 0, 0.54)',
          },
          '& fieldset': {
            color: 'rgba(0, 0, 0, 0.54)',
            cursor: 'default',
            background: 'rgba(0, 0, 0, 0.05)',
            borderColor: '#BDBDBD !important',
          },
        },
        '&.MuiFormControl-root': {
          '& label.Mui-focused:not(.Mui-error)': {
            color: '#43C099',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#43C099',
          },
        },
        '& .MuiInputAdornment-root': {
          '&$disabled': {
            cursor: 'default',
            background: 'rgba(0, 0, 0, 0.05)',
          },
        },
        '&.MuiInputBase-root': {
          '& fieldset': {
            borderColor: '#BDBDBD',
          },
          '&.Mui-focused:not(.Mui-error) fieldset': {
            borderColor: '#43C099',
          },
          '&.Mui-focused label': {
            color: 'red',
          },
        },
        '&:hover .MuiInputAdornment-root .MuiSvgIcon-root': {
          color: '#000',
        },
      },
    },
  },
} as IThemeOptions) as ITheme;

const useStyles = makeStyles({
  main: {
    overflow: 'hidden',
    display: 'flex',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    '& .MuiTableSortLabel-icon': {
      display: 'none',
    },
    '& .MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      display: 'inline-block',
      marginRight: 0,
      marginLeft: 2,
    },
  },
  toastr: {
    '& *': {
      fontFamily: 'Open Sans,Arial,sans-serif',
      color: '#FFFFFF',
    },
    '& .rrt-middle-container': {
      width: '80% !important',
    },
    '& .bottom-center': {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      left: 'auto',
      marginLeft: 'auto',
      flexDirection: 'column',
    },
    '& .bottom-center > div': {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .toastr': {
      maxWidth: '50%',
    },
    '& .rrt-error': {
      backgroundColor: `${theme.palette.error.main} !important`,
      '& .rrt-progressbar': {
        filter: 'brightness(85%)',
        backgroundColor: `${theme.palette.error.main} !important`,
      },
    },
    '& .rrt-warning': {
      backgroundColor: '#1976D2 !important',
      '& .rrt-progressbar': {
        filter: 'brightness(85%)',
        backgroundColor: '#1976D2 !important',
      },
    },
    '& .rrt-success': {
      backgroundColor: `${theme.palette.typical.accent} !important`,
      '& .rrt-progressbar': {
        filter: 'brightness(85%)',
        backgroundColor: `${theme.palette.typical.accent} !important`,
      },
    },
  },
});

const App: React.FC = () => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main className={classes.main}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" exact render={() => <Redirect to={routes.VI.path} />} />
            <Route {...routes.LOGIN} />
            <PrivateRoute {...routes.SOCIETIES} />
            <PrivateRoute {...routes.USERS} />
            <PrivateRoute {...routes.VI} />
            <Route {...routes.NOT_FOUND} />
          </Switch>
        </ConnectedRouter>
      </main>
      <Footer />
      <ReduxToastr
        className={classes.toastr}
        timeOut={8000}
        newestOnTop={false}
        preventDuplicates
        position="bottom-center"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </ThemeProvider>
  );
};

export default App;
