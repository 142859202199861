import React from 'react';
import { Button, Theme } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.common.black,
    minWidth: '7rem',
    '& span': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
}));

const IssueControls = ({
  onSave,
  onPublish,
  disabled,
  isLive,
} : {
  onSave: () => void,
  onPublish:() => void,
  disabled: boolean,
  isLive: boolean
}) => {
  const classes = useStyles();
  return (
    <>
      <Button
        disabled={disabled}
        disableElevation
        className={classes.button}
        variant="contained"
        color="primary"
        data-seleniumid="modal-publish-vi-button"
        onClick={() => onPublish()}
      >
        Publish
      </Button>
      <Button
        disabled={disabled}
        disableElevation
        className={classes.button}
        variant="outlined"
        color="primary"
        data-seleniumid="modal-save-vi-button"
        onClick={() => onSave()}
      >
        {isLive ? 'Save for later' : 'Save'}
      </Button>
    </>
  );
};

export default IssueControls;
