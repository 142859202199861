import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'src/app/pages/Login/redux/AuthDucks';
import { State } from 'src/app/redux/store';
import { UserRole } from 'src/mock/db/DBSchema';

interface IPermission {
  requiredRoles?: UserRole[],
  children: JSX.Element,
  Fallback?: FC,
}

export const hasPermission = (
  requiredRoles: IPermission['requiredRoles'],
  role: UserRole,
) => {
  if (!requiredRoles?.length) return true;
  if (!role) return false;

  return requiredRoles.some((requiredRole: UserRole) => role === requiredRole);
};

const Permission: FC<IPermission> = ({ children, requiredRoles, Fallback }) => {
  const { role } =  useSelector(getUser);

  const tokenEmpty =  useSelector((state: State) => state?.auth?.token ?? null) === '';

  if (tokenEmpty || hasPermission(requiredRoles, role)) return children;

  return Fallback ? <Fallback /> : null;
};

export default Permission;
