import React, { FC } from 'react';
import cn from 'classnames';
import { fade, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Typography } from '@material-ui/core';
import { ITheme } from 'src/app/App';

interface ICellWrap {
  onRemove?: () => void;
  children: any;
  error?: string | null | undefined;
  isDragging: boolean,
  seleniumId:string,
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    padding: ({ onRemove }: Partial<ICellWrap>) => theme.spacing(1, onRemove ? 0 : 1, 1, 0),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.typical.white,
  },
  dragging: {
    boxShadow: theme.shadows[4],
  },
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  loaderWrap: {
    backgroundColor: fade(theme.palette.background.paper, 0.7),
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.grey[400],
    width: '2rem',
    height: '2rem',
  },
  button: {
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    margin: theme.spacing(0.5, 8, 0, 8),
  },
}));

const CellWrap: FC<ICellWrap> = (props) => {
  const { isDragging, onRemove, children, error, seleniumId } = props;
  const classes = useStyles({ onRemove });
  return (
    <div className={cn(classes.root, { [classes.dragging]: isDragging })} data-seleniumid={`dnd-${seleniumId}`}>
      <div className={classes.container}>
        <DragIndicatorIcon className={classes.icon} />
        {children}
        {
          onRemove && (
            <CloseIcon
              onClick={onRemove}
              data-seleniumid={`${seleniumId}-remove`}
              className={cn(classes.button, classes.icon)}
            />
          )
        }
      </div>
      {error ? <Typography variant="caption" align="right" className={classes.error}>{error}</Typography> : null}
    </div>
  );
};

export default CellWrap;
