import _ from 'lodash';
import { Article } from 'src/mock/db';

export const initialState = {};

export const SAVE_ARTICLES = 'SAVE_ARTICLES';

export default (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SAVE_ARTICLES:
      return { ...state, ..._.keyBy(payload, 'doi') };
    default:
      return state;
  }
};

export const saveArticles = (articles:Article[]) => ({
  type: SAVE_ARTICLES,
  payload: articles,
});

export const getArticles = (state:any):Article[] => Object.values(state?.articles || {});

export const getArticlesByDOIs = (state:any, dois:string[]) => {
  const { articles } = state;
  return dois.map((doi:string) => articles[doi]).filter((el:Article) => el);
};
