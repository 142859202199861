import React, { useEffect, useMemo, useState, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import * as _ from 'lodash';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { toastr } from 'react-redux-toastr';
import { Modal, BODY_KEY, FOOTER_KEY } from 'src/app/components/common/Modal';
import { getJournals, getJournalsIsLoading, getJournalsError } from 'src/app/redux/reducers/journals';
import { createRecentJournal } from 'src/app/redux/reducers/profile/recentJournals';
import { createVirtualIssue, updateVirtualIssue, publishVirtualIssue, uploadCoverImage } from 'src/app/redux/api';
import { errorsByCode } from 'src/app/utils/requestErrors';
import { VirtualIssue } from 'src/mock/db';
import { logout } from 'src/app/pages/Login/redux/AuthDucks';
import WOLButton from 'src/app/components/common/WOLButton';
import { hasPublishedEvent } from 'src/app/pages/VirtualIssues/common/utils';
import { updateDetailedIssues } from './redux/detailedIssesDucks';
import DescriptionTab from './Tabs/Description';
import { FieldId } from './Tabs/Description/interfaces';
import TocTab from './Tabs/TOC';
import CoverTab from './Tabs/CoverTab';
import ErrorsTab from './Tabs/ErrorsTab';
import { IssueStatus, IssueTab, IssueTabs, TOCHelp } from './components';
import {
  getCreationData,
  showToastr,
  VIFormMessageTypes,
  preSaveValidation,
  prepareCoverCaption,
  getDataTemplate,
} from './common/utils';
import { CREATE_TITLE, EDIT_TITLE, FAILED_CREATE, FAILED_SAVE } from './common/constants';
import { VIFormData, TabsID, TabValidate } from './common/interfaces';
import IssueControls from './components/IssueControls';

export interface IIssuesManage {
  issue?: VirtualIssue;
  journalId: string | null;
  onClose(): void;
  loadingIssue?: boolean;
  isEdit?: boolean;
  isLive?: boolean;
  defaultData?: VIFormData;
  issueError?: string;
  tocId?: string;
}

const getParentUrl = (url:string) => {
  const [parentUrl] = url.split('/manage');
  return parentUrl;
};

const useTabsClasses = makeStyles({
  tabContainer: {
    display: 'flex',
  },
  tabIndicators: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
  },
});

const useModalStyles = makeStyles({
  modal: {
    alignItems: 'flex-start',
  },
  paper: ({ error }:{ error?: string }) => ({
    width: '60vw',
    margin: '10vh 0',
    height: error ? '15rem' : '80vh',
  }),
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
});

const IssuesManage = ({ issue, loadingIssue = false, isEdit = false, isLive = false, journalId, onClose, defaultData, issueError }: IIssuesManage) => {
  const { id: issueId } = issue || {};
  const [tabValue, setTabValue] = useState<number>(0);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [virtualIssuesIsLoading, setVirtualIssuesIsLoading] = useState<boolean>(false);
  const [modalContainer, setModalContainer] = useState<HTMLElement | null>(null);
  const [modalFooter, setModalFooter] = useState<HTMLElement | null>(null);
  const [data, setData] = useState<VIFormData>(defaultData || getDataTemplate(isEdit));
  const [savedData, setSavedData] = useState<VIFormData>(defaultData || getDataTemplate(isEdit));
  const [dirty, setDirty] = useState<boolean>(false);
  const [tabValidate, setTabValidate] = useState<TabValidate>({ description: true, cover: true, toc: true });
  const { location: { pathname } } = useHistory();

  const dispatch = useDispatch();
  const journals = useSelector(getJournals);
  const { description, toc, cover } = data;
  const journal = useMemo(() => _.find(journals, ['id', description.journalId || journalId]), [journals, description.journalId]);
  const loadingJournal = useSelector(getJournalsIsLoading);
  const journalsError = useSelector(getJournalsError);
  const isLoading = loadingIssue || loadingJournal;
  const error = issueError || journalsError;
  const modalClasses = useModalStyles({ error });
  const tabsClasses = useTabsClasses();
  const isValid = useMemo(() => !Object.values(tabValidate).some(el => !el), [tabValidate]);

  useEffect(() => {
    if (defaultData) {
      setData(defaultData);
      setSavedData(defaultData);
      setDirty(false);
    }
  }, [defaultData]);

  useEffect(() => {
    setTimeout(() => {
      const modalBody = document.querySelector<HTMLElement>(`.${BODY_KEY}`);
      const modalFooter = document.querySelector<HTMLElement>(`.${FOOTER_KEY}`);
      if (modalBody) setModalContainer(modalBody);
      if (modalFooter) setModalFooter(modalFooter);
    });
  }, []);

  const getCoverMeta = async () => {
    const { cover: coverData } = data;

    // Cover was removed or had not been created
    if (!coverData) {
      return null;
    }
    // Cover had not been updated
    if ('id' in coverData) {
      return coverData;
    }

    try {
      const uploadResult = await uploadCoverImage(coverData);
      return { id: uploadResult.id, caption: prepareCoverCaption(uploadResult.id) };
    }
    catch (e) {
      return null;
    }
  };

  const onSave = async (isPublish = false) => {
    setTabValue(0);
    setSubmitted(true);
    if (!preSaveValidation(data)) return null;
    try {
      setVirtualIssuesIsLoading(true);

      const coverInfo = await getCoverMeta();
      data.cover = coverInfo || undefined;
      data.journalDOI = journal?.doi;

      const requestData = getCreationData(data, isEdit);
      const [call] = isEdit && issueId
        ? await updateVirtualIssue(issueId, requestData)
        : await createVirtualIssue(requestData);
      const response = await call;
      dispatch(updateDetailedIssues(response));

      const journalId = response?.isPartOfPeriodical?.id;
      if (
        !isEdit
        && journalId
      ) {
        dispatch(createRecentJournal(journalId));
      }

      if (!isPublish) {
        showToastr(VIFormMessageTypes.createSuccess);
        onClose();
      }

      setSavedData(data);
      setDirty(false);

      return response;
    }
    catch (e) {
      if (e?.response?.status === 403) {
        dispatch(logout());
        return null;
      }
      setVirtualIssuesIsLoading(false);
      const defaultMessage = isEdit ? FAILED_SAVE : FAILED_CREATE;
      const message = typeof e === 'string' ? e : defaultMessage;
      toastr.error('Failed', message);
      return null;
    }
  };

  const onPublish = async () => {
    try {
      const { id } = await onSave(true) || {};
      if (!id) return;
      const [promise] = publishVirtualIssue(id);
      await promise;
      showToastr(VIFormMessageTypes.publishSuccess);
      onClose();
    }
    catch (e) {
      const message = typeof e === 'string' ? e : errorsByCode.FAILED_PUBLISH_ISSUE();
      toastr.error('Failed', message);
    }
    finally {
      setVirtualIssuesIsLoading(false);
    }
  };

  const checkDataIsChanged = (newData: any):void => {
    const isDirty = !_.isEqual(
      { ...savedData,
        description: { ...savedData.description,
          lastUpdated: savedData?.description?.lastUpdated?.substring(0, 10),
          firstPublished: savedData?.description?.firstPublished?.substring(0, 10),
        },
      },
      {
        ...newData,
        description: { ...newData.description,
          lastUpdated: newData?.description?.lastUpdated?.substring(0, 10),
          firstPublished: newData?.description?.firstPublished?.substring(0, 10),
        },
        toc: newData?.toc?.length === 1 && newData?.toc[0].type === 'Header' ? [] : toc,
      },
    );
    setDirty(isDirty);
  };

  useEffect(() => {
    checkDataIsChanged(data);
  }, [data]);

  const updateData = useCallback((tab:TabsID, newData:any) => {
    setData((current: VIFormData) => ({ ...current, [tab]: newData }));
  }, [setData]);

  const updateDescription = useCallback((field: FieldId, value:string) => {
    setData((current: VIFormData) => ({ ...current, description: { ...current.description, [field]: value } }));
  }, [setData]);

  const updateValidate = useCallback((tab:TabsID, value:boolean) => {
    setTabValidate((current: TabValidate) => ({ ...current, [tab]: value }));
  }, [setTabValidate]);

  const { tabContainer, tabIndicators } = tabsClasses;
  const title = `${isEdit ? EDIT_TITLE : CREATE_TITLE} ${description.title ? `- ${description.title}` : ''}`;

  const loading = isLoading || virtualIssuesIsLoading || !modalContainer;

  const { description: { doi } } = data;

  return (
    <>
      <Modal
        classes={modalClasses}
        title={title}
        parentUrl={getParentUrl(pathname)}
        isLoading={loading}
        error={error}
        disableBackdropClick={dirty && !loading}
        onBackdropClick={() => {
          if (dirty && !loading) {
            toastr.warning('Modal closing prevented', 'The form is changed, please Save changes or press Cancel');
          }
        }}
        HeaderButton={(doi && issue?.events?.length && hasPublishedEvent(issue.events) && <WOLButton doi={doi} />) || ''}
      />
      { (error || !modalContainer) ? null : ReactDOM.createPortal(
        <>
          <Box className={tabContainer}>
            <IssueTabs
              tabValue={tabValue}
              setTabValue={setTabValue}
              tabValidate={tabValidate}
              showErrorTab={issue?.uiState === 'DeliveryError'}
            />
            <div className={tabIndicators}>
              {tabValue === 2 ? <TOCHelp /> : <div />}
              {issue && <IssueStatus id={issue.id} uiState={issue.uiState} /> }
            </div>
          </Box>
          <IssueTab value={tabValue} index={0}>
            <DescriptionTab
              issueId={issue?.id}
              isEditDisabled={isEdit && isLive}
              tabId="description"
              data={description}
              journal={journal}
              onChange={updateDescription}
              onValidate={updateValidate}
              submitted={submitted}
            />
          </IssueTab>
          <IssueTab value={tabValue} index={1}>
            <CoverTab
              tabId="cover"
              cover={cover}
              setCover={updateData}
            />
          </IssueTab>
          <IssueTab value={tabValue} index={2}>
            <TocTab
              tabId="toc"
              data={toc}
              isActive={tabValue === 2}
              onValidate={updateValidate}
              onUpdate={updateData}
            />
          </IssueTab>
          <IssueTab value={tabValue} index={3}>
            <ErrorsTab
              events={issue?.events}
              isActive={tabValue === 3}
            />
          </IssueTab>
        </>,
        modalContainer)}
      { (error || !modalFooter) ? null : ReactDOM.createPortal(
        <IssueControls
          disabled={!isValid}
          onPublish={onPublish}
          onSave={onSave}
          isLive={isLive}
        />,
        modalFooter)}
    </>
  );
};

export default memo(IssuesManage);
