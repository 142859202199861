import { put, call, all, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import { fetchUser } from 'src/app/redux/api';
import { BaseUser } from 'src/mock/db';
import {
  LoadUsersByIdAction,
  LOAD_USERS_BY_ID,
  LOAD_USERS_BY_ID_SUCCESS,
} from 'src/app/pages/Users/redux/UsersDucks';
import { SET_NETWORK } from 'src/app/redux/reducers/network';

const getSuccessAction = (users: BaseUser[]) => ({
  type: LOAD_USERS_BY_ID_SUCCESS,
  payload: { items: users },
});

export function* loadUsersById({ payload }: LoadUsersByIdAction) {
  const users = yield all(payload.map((id) => call(function* g() {
    const [promise, url] = yield call(fetchUser, id);
    yield put({ type: SET_NETWORK, url });

    try {
      const result = yield promise;
      yield put({
        type: SET_NETWORK,
        url,
        payload: { status: 'success' },
      });

      return result;
    }
    catch (e) {
      yield put({
        type: SET_NETWORK,
        url,
        payload: {
          status: 'failure',
          error: e.response.data.message,
        },
      });

      return null;
    }
  })));

  if (users?.length) {
    yield put(getSuccessAction(_.compact(users)));
  }
  else {
    yield put(getSuccessAction([]));
  }
}

export function* watchUsers() {
  yield takeLatest(LOAD_USERS_BY_ID, loadUsersById);
}
