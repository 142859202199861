import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useRequest from 'src/app/utils/hooks/useRequest';
import endpoints from 'src/app/redux/api/endpoints';
import { getArticles } from 'src/app/redux/reducers/articles';
import { getHTMLContentPart, getHTMLHeader, getHeaderTemplate } from './Tabs/TOC/utils';
import { loadDetailedIssue, getDetailedIssue } from './redux/detailedIssesDucks';
import { getTOC } from './Tabs/TOC/redux/tocDucks';
import IssuesManage, { IIssuesManage } from './index';
import { getIssueFormData } from './common/utils';

interface IIssuesEdit extends IIssuesManage {
  id: string,
}

const EditIssue = ({ id, ...props }:IIssuesEdit) => {
  const dispatch = useDispatch();
  const issue = useSelector(getDetailedIssue(id));
  const tocId = issue?.hasTableOfContents?.id || null;
  const articles = useSelector(getArticles);
  const toc = useSelector(getTOC(tocId)) || getHTMLContentPart([{ header: getHTMLHeader(getHeaderTemplate(true).value), articles: [] }]);
  useEffect(() => {
    dispatch(loadDetailedIssue(id));
  }, [id]);

  const requestInfo = useRequest({ route: { path: endpoints.virtualIssue, method: 'GET', params: { id } }, remove: true });
  const issueIsLoading = requestInfo?.status === 'fetching' || (!issue && requestInfo?.status !== 'failure');

  const defaultData = useMemo(() => {
    if (!issue) return undefined;
    return getIssueFormData(issue, toc, articles);
  }, [issue]);

  return (
    <IssuesManage
      {...props}
      issue={issue || undefined}
      loadingIssue={issueIsLoading}
      isEdit
      defaultData={defaultData}
      issueError={requestInfo?.error}
    />
  );
};
export default EditIssue;
