/* eslint-disable no-console */
import he from 'he';
import { Base64 } from 'js-base64';
import _ from 'lodash';
import { v1 } from 'uuid';

import DomParser from 'dom-parser';
import { Article } from 'src/mock/db';
import { DUPLICATE_ARTICLE_DOI_ERROR, EMPTY_ARTICLE_NAME, NON_ARTICLE_DOI_ERROR } from './constants';
import { ArticleValue, CellValue, ICell } from './interfaces';

const parser = new DomParser();
export const TEMPLATE_HEADER_TITLE = 'Table of Contents';

export const getUsedArticlesDOIs = (cells:ICell[]) => {
  const articles = _.filter(cells, { type: 'article', meta: { pristine: false, validating: false, error: null } });
  const loadedArticles = articles.filter((article: ICell) => !!_.get(article, 'value.label'));
  return _.map(loadedArticles, 'value.input');
};

/**
 * Get id of new article cell which contains duplicated DOI.
 * @param removedId
 * @param cells
 * @returns string | undefined
 */
export const getDuplicatedArticleID = (removedId: string, cells:ICell[]): string | undefined => {
  const cell = _.find(cells, ['id', removedId]);
  if (cell?.type !== 'article' || cell.meta?.error === DUPLICATE_ARTICLE_DOI_ERROR) return;

  const removedValue = _.get(cell, 'value.input');
  return (cells.find((article: ICell) => _.get(article, 'value.input', '').includes(removedValue) && article.meta?.error === DUPLICATE_ARTICLE_DOI_ERROR) || {}).id;
};

const defaultHeaderID = v1();
export const getHeaderTemplate = (isDefault:boolean = false):ICell => ({
  id: isDefault ? defaultHeaderID : v1(),
  type: 'header',
  value: isDefault ? TEMPLATE_HEADER_TITLE : '',
  meta: { pristine: true, error: null },
});
export const getArticleTemplate = ():ICell => ({
  id: v1(),
  type: 'article',
  value: { label: '', input: '' },
  meta: { pristine: true, error: null, validating: false },
});

export const createArticles = (dois: ({ doi: string | undefined, title: string | undefined })[], articles:Article[]) => dois.map(({ doi, title }) => {
  const article = _.find(articles, ['doi', doi]) || (title ? { name: title } : null);
  const articleTemplate = getArticleTemplate();

  let error = null;
  if (!article) {
    error = NON_ARTICLE_DOI_ERROR;
  }
  else if (article && !article.name) {
    error = EMPTY_ARTICLE_NAME;
  }

  return {
    ...articleTemplate,
    value: { label: article?.name ? he.decode(article.name) : '', input: doi },
    meta: { ...articleTemplate.meta, pristine: false, error },
  };
});

export const getReorderedCells = (list: ICell[], startIndex:number, endIndex:number) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const TOC_STRUCTURE_REGEXP = /^(header{1}(article)+)*$/;

export const isTOCStructureValid = (data: ICell[]) => {
  const cellsTypeSequence = _.map(data.filter((cell: ICell) => !cell.meta.pristine || cell.id === defaultHeaderID), 'type').join('');
  if (cellsTypeSequence === 'header' && data.length === 1) return true;

  return TOC_STRUCTURE_REGEXP.test(cellsTypeSequence);
};

export const getParsedHeader = (header:string):ICell => {
  const template = getHeaderTemplate();
  return {
    ...template,
    value: he.decode(header),
    meta: { ...template.meta, pristine: false },
  };
};

export interface articleTOCData {
  doi?: string;
  title?: string;
}

export const getArticleDataFromHTM = (htm: string):articleTOCData[] => {
  const articleData = [] as articleTOCData[];

  let parsedHtm = htm;
  if (htm?.length && !htm.includes('<section')) {
    parsedHtm = Base64.decode(htm);
  }

  try {
    const parcedTOC = parser.parseFromString(parsedHtm);
    // @ts-ignore
    parcedTOC.getElementsByTagName('li').forEach((el: HTMLElement) => {
      const doi = _.unescape(el?.getAttribute('about')?.split('info:doi/')?.reverse()[0] || '');
      const titles = el?.getElementsByTagName('title');
      const titleEl = titles.length ? titles[0] : '';
      // @ts-ignore
      const title = titleEl?.innerHTML?.trim();
      if (doi) {
        articleData.push({ doi, title: title ? he.decode(title) : title } as articleTOCData);
      }
    });
    return articleData;
  }
  catch (e) {
    console.log('e', e);
  }
  return [];
};

export const parseHTMtoTOC = (htm:string, articles: Article[]): ICell[] => {
  if (!htm) return [];
  let sectionsHTM = htm;
  if (htm?.length && !htm.includes('<?xml')) {
    sectionsHTM = Base64.decode(htm);
  }

  const sectionsHTMArr = sectionsHTM
    .replace(/[\n\r]/g, '').match(/<section typeof="wbas:TableOfContentsSection">(.*?)<\/section>/g) || [];
  return sectionsHTMArr.reduce((acc: ICell[], sectionTHM: string) => {
    const header = (/<h1 [^>]+>(.*?)<\/h1>/.exec(sectionTHM)?.[1]);
    if (!header) {
      console.warn('TOC. Section has to have header');
      return acc;
    }
    const articleTOCData = getArticleDataFromHTM(sectionTHM);
    if (header !== TEMPLATE_HEADER_TITLE && !articleTOCData?.length) {
      console.warn('TOC. Section has to have articles after header');
      return acc;
    }
    // @ts-ignore
    return acc.concat([getParsedHeader(header), ...createArticles(articleTOCData.map(({ doi, title }) => ({ doi, title })), articles)]);
  }, []);
};

export const getHTMLHeader = (header: CellValue | ArticleValue) => `<h1 class="subjectTitle" property="schema:name" datatype="rdf:HTML">${typeof header === 'string' ? he.encode(header) : header}</h1>`;
const getHTMLArticle = (doi:string, title:string) => `<li typeof="wres:ResearchMap" about="info:doi/${_.escape(doi)}" resource="">${title ? `<title>${he.encode(title)}</title>` : ''}</li>`;

export const getHTMLContentPart = (list:any[]) => list.map((el:any) => {
  const sectionContent = `${el.header}<ol>${el.articles.join('')}</ol>`;
  return `<li typeof="wbas:ContentPart"><section typeof="wbas:TableOfContentsSection">${sectionContent}</section></li>`;
}).join('');

const getFullHTMLTemplate = (liContentPart: string) => (liContentPart ? `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <meta charset="UTF-8" />
    <title property="http://schema.org/name" lang="en" xml:lang="en">Table of Contents</title>
  </head>
  <body prefix="wbas: https://ontology.wiley.com/Base# wres: https://ontology.wiley.com/Research#" >
    <header>
    </header>
    <div class="toc" typeof="wbas:TableOfContents">
      <ol property="hasContentPart">
        ${liContentPart}
      </ol>
    </div>
  </body>
</html>
` : null);


export const parseTOCtoHTM = (data: ICell[]) => {
  const sections = data.reduce((acc:any[], cell:ICell) => {
    const { type, value } = cell;
    if (type === 'header') {
      return acc.concat({ header: getHTMLHeader(value as CellValue), articles: [] });
    }
    if (type === 'article') {
      const section = acc.pop();
      return acc.concat({
        ...section,
        articles: [...section.articles, getHTMLArticle(
          (value as ArticleValue).input,
          (value as ArticleValue).label || '',
        )],
      });
    }
    return acc;
  }, []);
  return window.btoa(getFullHTMLTemplate(getHTMLContentPart(sections)) || '') || null;
};
