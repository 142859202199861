import cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { TOKEN_KEY } from 'src/app/constants';

export const getToken = () => cookie.get(TOKEN_KEY);
export const removeToken = () => cookie.remove(TOKEN_KEY, { path: '/' });

export const isAuthorized = (token = getToken()) => {
  if (!token) {
    return false;
  }

  try {
    jwtDecode(token);

    return true;
  }
  catch (e) {
    // eslint-disable-next-line
    console.error(e);

    return false;
  }
};
