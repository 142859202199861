import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { ITableCell } from 'src/app/components/Table/Interfaces';

const useStyle = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    'justify-content': 'flex-start',
  },
});

const TypeCell = (props: ITableCell) => {
  const { value } = props;
  const classes = useStyle();

  const type = {
    'wpub:IssueVirtual': 'Virtual Issue',
    'wpub:IssueSpecial': 'Special Issue',
  };

  const key = value as keyof typeof type;

  return (
    <div className={classes.root}>
      { type[key] }
    </div>
  );
};

export default TypeCell;
