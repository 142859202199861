import React, { FC } from 'react';
import cn from 'classnames';
import { Button } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { ITheme } from 'src/app/App';
import { ModalActionsConfigInterface, ModalActionsInterface } from './interfaces';

export const FOOTER_KEY = 'modal-footer-container';

const useStyles = makeStyles(
  ({ palette, spacing, typography }: ITheme) => {
    const buttonSize = {
      width: '7rem',
      height: '2.5rem',
    };
    const buttonStyle = {
      color: palette.typical.black,
      '& span': {
        fontWeight: typography.fontWeightRegular,
      },
      ...buttonSize,
    };
    return {
      footer: {
        padding: spacing(2),
        minHeight: '5rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0px 0px 1px 1px rgba(0,0,0,0.2), 0px 0 1px 1px rgba(0,0,0,0.14), 0px 0 2px 1px rgba(0,0,0,0.12)',
      },
      actions: {
        '& button:not(:last-child)': {
          marginRight: spacing(2),
        },
      },
      buttonPrimary: {
        ...buttonStyle,
      },
      buttonSecondary: {
        ...buttonStyle,
      },
      cancelButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'uppercase',
        color: palette.typical.black,
        cursor: 'pointer',
        '&:hover': {
          fontWeight: typography.fontWeightMedium,
        },
        ...buttonSize,
      },
    };
  },
);

const ModalActions: FC<ModalActionsInterface> = ({
  actions,
  history,
  parentUrl,
  onCloseEffect,
  classes: overrideClasses = {},
}) => {
  const classes = useStyles({ classes: overrideClasses });

  return (
    <div className={classes.footer}>
      <span
        className={classes.cancelButton}
        onClick={() => {
          if (onCloseEffect) {
            onCloseEffect();
          }

          history.push(parentUrl);
        }}
        data-seleniumid="modal-cancel-button"
      >
        Cancel
      </span>
      <div className={cn(classes.actions, FOOTER_KEY)}>
        {actions.map((action: ModalActionsConfigInterface) => {
          const { id, title, type, ...rest } = action;
          return (
            <Button
              disableElevation
              key={id}
              className={type === 'primary' ? classes.buttonPrimary : classes.buttonSecondary}
              variant={type === 'secondary' ? 'contained' : 'outlined'}
              color="primary"
              data-seleniumid={`modal-${id}-button`}
              {...rest}
            >
              {title}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default ModalActions;
