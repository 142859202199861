import React, { FC, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ITableCell } from 'src/app/components/Table/Interfaces';
import { JournalCell } from 'src/app/pages/VirtualIssues/components/IssuesList/IssuesCell';
import { IdObj } from 'src/mock/db';
import { ITheme } from 'src/app/App';
import { getJournals } from 'src/app/redux/reducers/journals';

interface IArrowIcon {
  isCollapsed: boolean,
  onClick(): void,
  className: string,
  id: string,
}

export const MIN_VISIBLE_JOURNALS = 10;

const useStyles = makeStyles((theme: ITheme) => ({
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  element: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    cursor: 'pointer',
    fontSize: '2rem',
    borderRadius: '50%',
    background: theme.palette.chip.expandIcon.background,
    transition: 'all .2s',
    color: theme.palette.chip.expandIcon.color,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.chip.expandIcon.background,
    },
  },
  chip: {
    background: theme.palette.chip.color,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export const ArrowIcon: FC<IArrowIcon> = ({ id, isCollapsed, onClick, className }) => {
  const Component = isCollapsed ? ChevronRightIcon : ChevronLeftIcon;
  return (
    <Component
      data-seleniumid={`${id}-collapse`}
      className={className}
      onClick={onClick}
    />
  );
};

const JournalsCell: FC<ITableCell> = ({ id: societiesId, value }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const classes = useStyles();

  const journals = useSelector(getJournals);

  const isCollapsible = value.length > MIN_VISIBLE_JOURNALS;
  const displayedValue = (isCollapsible && isCollapsed) ? value.slice(0, MIN_VISIBLE_JOURNALS) : value;

  const orderedDisplayedValue = _.orderBy(displayedValue.map((value: { id: string, label: string }) => {
    const journal = _.find(journals, { id: value.id });
    return { ...value, label: journal?.productCode || value.id };
  }), ['label']);

  return (
    <div className={classes.wrap}>
      {orderedDisplayedValue.map((journal: IdObj) => (
        <span key={journal.id} className={classes.element}>
          <JournalCell
            id={societiesId}
            value={journal}
            isChip
            className={classes.chip}
          />
        </span>
      ))}
      {isCollapsible && (
        <span className={classes.element}>
          <ArrowIcon
            id={societiesId}
            className={classes.icon}
            isCollapsed={isCollapsed}
            onClick={() => setIsCollapsed(!isCollapsed)}
          />
        </span>
      )}
    </div>
  );
};

export default JournalsCell;
