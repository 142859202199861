import { all, fork } from 'redux-saga/effects';
import { watchAuth } from 'src/app/pages/Login/redux/AuthSagas';
import { watchUsers } from 'src/app/pages/Users/components/UsersList/redux/UsersSaga';
import { watchLoadIssueManager } from 'src/app/pages/VirtualIssues/components/IssuesManage/redux/IssueManageSagas';

export default function* root() {
  yield all([
    fork(watchAuth),
    fork(watchUsers),
    fork(watchLoadIssueManager),
  ]);
}
