import React from 'react';
import _ from 'lodash';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core/styles';
import { ITableCell } from 'src/app/components/Table/Interfaces';
import { UI_STATES_INFO } from 'src/app/pages/VirtualIssues/common/constants';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
});

const useIndicatorStyles = makeStyles(theme => ({
  root: ({ color }: { color: string }) => ({
    color: color || 'inherit',
    marginRight: '.75rem',
    marginLeft: -theme.spacing(0.5),
  }),
}));

const StatusCell = (props: ITableCell) => {
  const { value: rawValue } = props;
  const statusInfo = _.get(UI_STATES_INFO, rawValue) || {};
  const { color = '', title } = statusInfo;

  const indicatorClasses = useIndicatorStyles({ color });
  const classes = useStyles();
  if (!title) return null;

  return (
    <div className={classes.root}>
      <FiberManualRecordIcon classes={indicatorClasses} />
      {title}
    </div>
  );
};

export default StatusCell;
