import { createStore, compose, applyMiddleware, combineReducers, AnyAction } from 'redux';
import createSagaMiddleware from 'redux-saga';
import auth, { LOGOUT_SUCCESS, LOGOUT_FAILURE } from 'src/app/pages/Login/redux/AuthDucks';
import users from 'src/app/pages/Users/redux/UsersDucks';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import issues from 'src/app/pages/VirtualIssues/components/IssuesList/redux/IssuesDucks';
import journals from 'src/app/redux/reducers/journals';
import profile from 'src/app/redux/reducers/profile';
import issueManage from 'src/app/pages/VirtualIssues/components/IssuesManage/redux/issueManageDucks';
import articles from 'src/app/redux/reducers/articles';
import network, { Network } from 'src/app/redux/reducers/network';
import societies from 'src/app/pages/Societies/redux/SocietiesDucks';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import rootSaga from 'src/app/redux/sagas';
import { reducer as toastrReducer, ToastrState } from 'react-redux-toastr';
import features from './reducers/features';
import asyncMiddleware from './asyncMiddleware';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

type WindowWithDevtoolsExtension = Window & { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any };
const isDevtoolsExtensionPresent = (arg: Window | WindowWithDevtoolsExtension): arg is WindowWithDevtoolsExtension => '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' in arg;
const composeEnhancer = isDevtoolsExtensionPresent(window) ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

type Profile = {
  recentJournals: any;
}

type IssueManage = {
  TOC: any;
  Issues: any;
}

export type State = {
  auth: any;
  journals: any;
  liveIssues: any;
  nonLiveIssues: any;
  users: any;
  societies: any;
  articles: any;
  issueManage: IssueManage;
  profile: Profile;
  router: any;
  network: Network;
  toastr: ToastrState;
  features: any;
} | undefined;

export const history = createBrowserHistory();

const appReducer = combineReducers<State>({
  auth,
  journals,
  users,
  societies,
  liveIssues: issues('liveIssues'),
  nonLiveIssues: issues('nonLiveIssues'),
  profile,
  articles,
  issueManage,
  router: connectRouter(history),
  network,
  toastr: toastrReducer,
  features,
});

const rootReducer = (state: State, action: AnyAction) => {
  let appState = state;
  if ([LOGOUT_SUCCESS, LOGOUT_FAILURE].includes(action.type)) {
    appState = undefined;
    if (state?.auth?.returnUrl) {
      action.payload = { returnUrl: state.auth.returnUrl };
    }
  }

  return appReducer(appState, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export const sagaMiddleware = createSagaMiddleware();
const middleware = [
  routerMiddleware(history),
  asyncMiddleware({
    defaultTransformError: (e:any) => (typeof e === 'string' ? e : e?.response?.data?.message || ''),
  }),
  sagaMiddleware,
];

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['auth', 'toastr', 'router', 'network'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = createStore(
    persistedReducer,
    composeEnhancer(applyMiddleware(...middleware)),
  );

  sagaMiddleware.run(rootSaga);

  return { store, persistor: persistStore(store) };
};
