import React, { forwardRef, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';


export interface IPaperProps {
  className?: string;
}

export interface IPaperLayout extends IPaperProps{
  seleniumId?: string;
  children: any;
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    maxWidth: theme.breakpoints.values.xl,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.common.white}`,
  },
}));

const PaperLayout = forwardRef((props: IPaperLayout, ref) => {
  const {
    children,
    className,
    seleniumId,
  } = props;

  const classes = useStyles();

  return <Paper ref={ref} className={`${classes.root} ${className}`} elevation={3} data-seleniumid={seleniumId || ''}>{children}</Paper>;
});

export default memo(PaperLayout);
