import React, { useState, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { ITableCell } from 'src/app/components/Table/Interfaces';
import { makeStyles } from '@material-ui/core/styles';
import { ITheme } from 'src/app/App';
import WOLButton from 'src/app/components/common/WOLButton';
import { hasPublishedEvent } from 'src/app/pages/VirtualIssues/common/utils';

const useTooltipStyle = makeStyles((theme: ITheme) => ({
  tooltip: {
    backgroundColor: theme.palette.typical.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: '1rem',
  },
  popper: {
    cursor: 'pointer',
  },
}));

const useStyle = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    'justify-content': 'flex-start',
  },
  tooltipParent: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const TitleCell = (props: ITableCell) => {
  const { value, row: { doi, events } } = props;
  const [isEllipsis] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipClasses = useTooltipStyle();
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <div
        ref={containerRef}
        className={classes.tooltipParent}
      >
        <Tooltip
          classes={tooltipClasses}
          title={value}
          placement="bottom-start"
          disableHoverListener={!isEllipsis}
        >
          <span>{value}</span>
        </Tooltip>
      </div>
      {doi && events?.length && hasPublishedEvent(events) ? <WOLButton doi={doi} /> : '' }
    </div>
  );
};

export default TitleCell;
