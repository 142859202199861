/* eslint-disable react/jsx-no-duplicate-props */

import React, { FC, useEffect, useRef, useState, InputHTMLAttributes } from 'react';
import { toastr } from 'react-redux-toastr';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { BaseUser } from 'src/mock/db';

import {
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { USER_ADMIN_ROLE, USER_EDITOR_ROLE, USER_SOCIETY_EDITOR_ROLE, USERS_ROLE_TITLES } from 'src/app/constants';
import { Modal } from 'src/app/components/common/Modal';
import { getSocieties, loadSocieties } from 'src/app/pages/Societies/redux/SocietiesDucks';
import {
  createUser,
  updateUser,
  loadUsers,
  loadUsersById,
  getUsersById,
  getUsersByEmail,
} from 'src/app/pages/Users/redux/UsersDucks';
import endpoints from 'src/app/redux/api/endpoints';
import useRequest from 'src/app/utils/hooks/useRequest';
import { ITheme } from 'src/app/App';

const useStyles = makeStyles((theme: ITheme) => ({
  formWrap: {
    position: 'relative',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  loader: {
    position: 'absolute',
    backgroundColor: theme.palette.typical.white,
    opacity: '0.7',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const useSelectStyles = makeStyles((theme: ITheme) => ({
  formControl: {
    minWidth: 100,
  },
  select: {
    '&.Mui-focused .MuiSelect-root': {
      backgroundColor: theme.palette.typical.white,
    },
  },
  listbox: {
    padding: '0',
  },
  option: {
    padding: '0.5rem 1rem',
  },
}));

export const USER_MANAGE_FAILURE_TITLE = 'Failed';
export const USER_LOAD_FAILURE_TITLE = 'Failed loading user';
export const SOCIETIES_FAILURE = {
  TITLE: 'Societies list problem',
  TEXT: 'Societies list problem, please try again',
};

interface FormData extends Pick<BaseUser, 'role'> {
  active: boolean;
  society: string;
}

export type FormType = 'create' | 'edit';

const ManageUserModal: FC = () => {
  const { search } = useParams<Record<string, string>>();
  const dispatch = useDispatch();
  const history = useHistory();

  const isEmailSearchParam = /.+@.+\..{2,}/.test(search);
  const [user] = useSelector(
    isEmailSearchParam
      ? getUsersByEmail(search)
      : getUsersById([search]),
  );
  const [isVi] = useState(!!user?.id);
  const isWiley = user?.type === 'Wiley';
  const formType: FormType = isVi ? 'edit' : 'create';

  const societies = useSelector(getSocieties);

  const loadUsersRequestInfo = useRequest({
    route: isEmailSearchParam
      ? { path: endpoints.users, params: { email: search } }
      : { path: endpoints.user, params: { id: search } },
    remove: true,
  });
  const manageUserRequestInfo = useRequest({
    route: {
      path: endpoints.users,
      method: isVi ? 'PATCH' : 'POST',
    },
    remove: true,
  });
  const loadSocietiesRequestInfo = useRequest({
    route: { path: endpoints.societies },
  });

  useEffect(() => {
    const hasErrors = manageUserRequestInfo?.status === 'failure'
    || loadUsersRequestInfo?.status === 'failure'
    || loadSocietiesRequestInfo?.status === 'failure';

    if (hasErrors) {
      let toastrTitle: string = '';
      let toastrText: string = '';
      let toastrType: 'error' | 'warning' = 'error';
      const toastrOptions = { className: `user-${formType}-error` };

      switch (true) {
        case manageUserRequestInfo?.status === 'failure':
          toastrTitle = USER_MANAGE_FAILURE_TITLE;
          toastrText = manageUserRequestInfo?.error || '';
          break;
        case loadUsersRequestInfo?.status === 'failure':
          toastrTitle = USER_LOAD_FAILURE_TITLE;
          toastrText = loadUsersRequestInfo?.error || '';
          break;
        case loadSocietiesRequestInfo?.status === 'failure':
          toastrTitle = SOCIETIES_FAILURE.TITLE;
          toastrText = loadSocietiesRequestInfo?.error || SOCIETIES_FAILURE.TEXT;
          toastrType = 'warning';
          break;
        default: break;
      }

      toastr[toastrType](toastrTitle, toastrText, toastrOptions);
    }

    const shouldRedirect = manageUserRequestInfo?.status === 'success'
    || loadUsersRequestInfo?.status === 'failure'
    || loadSocietiesRequestInfo?.status === 'failure';

    if (shouldRedirect) {
      history.push('/users');
    }
    else {
      const shouldRequestUser = !user && !loadUsersRequestInfo;
      if (shouldRequestUser) {
        dispatch(isEmailSearchParam
          ? loadUsers({ email: search })
          : loadUsersById([search]),
        );
      }

      const shouldRequestSociety = !societies.length
      && loadSocietiesRequestInfo?.status !== 'fetching';
      if (shouldRequestSociety) {
        dispatch(loadSocieties());
      }
    }
  }, [
    user,
    societies,
    loadUsersRequestInfo,
    manageUserRequestInfo,
    loadSocietiesRequestInfo,
  ]);

  const isLoading = manageUserRequestInfo?.status === 'fetching'
    || loadSocietiesRequestInfo?.status === 'fetching';
  const newUserRole = isWiley
    ? USER_EDITOR_ROLE
    : USER_SOCIETY_EDITOR_ROLE;

  const submitCreateUser = ({ society, role, active }: FormData) => {
    const stateValue = active ? 'Active' : 'Inactive';
    if (search) {
      if (isVi) {
        dispatch(updateUser({
          id: user.id,
          state: stateValue,
          role,
          societies: isWiley ? [] : [{ id: society }],
        }));
      }
      else {
        dispatch(createUser({
          userId: user.userId || null,
          state: stateValue,
          role,
          societies: isWiley ? [] : [{ id: society }],
          email: user.email,
          participantId: user.participantId || null,
        }));
      }
    }
  };

  const userRoleLabel = useRef<HTMLLabelElement>(null);
  const societyLabel = useRef<HTMLLabelElement>(null);

  const classes = useStyles();
  const selectClasses = useSelectStyles();

  return (
    <Form
      onSubmit={submitCreateUser}
      initialValues={{
        society: !isWiley && !!user?.societies?.length ? user.societies[0].id : '',
        active: user?.state === 'Active',
        role: user?.role || newUserRole,
      }}
      validate={values => (isWiley || values.society ? {} : { society: 'Required' })}
      render={({ handleSubmit, dirty }) => (
        <Modal
          data-seleniumid={`${formType}-user-modal`}
          isLoading={!user || loadSocietiesRequestInfo?.status === 'fetching'}
          title={isVi ? 'Edit user' : 'Add New User'}
          parentUrl="/users"
          disableBackdropClick={dirty}
          onBackdropClick={() => {
            if (dirty) {
              toastr.warning('Modal closing prevented', 'The form is changed, please Save changes or press Cancel');
            }
          }}
          actions={[{
            disabled: isLoading,
            id: 'user-save-btn',
            title: 'save',
            type: 'primary',
            onClick: handleSubmit,
          }]}
        >
          <form data-seleniumid={`user-${formType}`}>
            <Grid container spacing={3} className={classes.formWrap}>
              {
                isLoading && <div className={classes.loader}><CircularProgress /></div>
              }
              <Grid item xs={6}>
                <TextField
                  label="First Name"
                  value={user?.firstName || ''}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  fullWidth
                  disabled
                  data-seleniumid="user-first-name"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Last Name"
                  value={user?.lastName || ''}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  fullWidth
                  disabled
                  data-seleniumid="user-last-name"
                />
              </Grid>
              <Grid item xs={6}>
                <Field name="society">
                  {({ input, meta }) => {
                    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;
                    return (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={showError}
                        disabled={isWiley}
                        data-seleniumid="user-society"
                      >
                        <InputLabel ref={societyLabel}>Society</InputLabel>
                        <Select
                          name={input.name}
                          onChange={input.onChange}
                          value={input.value}
                          labelWidth={societyLabel.current?.offsetWidth || 0}
                        >
                          {societies.map((soc: any) => <MenuItem key={soc.id} value={soc.id}>{soc.name}</MenuItem>)}
                        </Select>
                        { showError && <FormHelperText>{meta.error || meta.submitError}</FormHelperText> }
                      </FormControl>
                    );
                  }}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="role">
                  {
                    ({ input, meta }) => {
                      const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;
                      return (
                        <FormControl
                          variant="outlined"
                          disabled={!isWiley}
                          fullWidth
                          className={selectClasses.formControl}
                          data-seleniumid="user-role"
                        >
                          <InputLabel ref={userRoleLabel}>User role</InputLabel>
                          <Select
                            name={input.name}
                            onChange={input.onChange}
                            value={input.value}
                            disabled={!isWiley}
                            className={selectClasses.select}
                            classes={selectClasses}
                            labelWidth={userRoleLabel.current?.offsetWidth || 0}
                          >
                            {
                              isWiley
                                ? [USER_ADMIN_ROLE, USER_EDITOR_ROLE].map((role) => (
                                  <MenuItem key={role.toLowerCase()} value={role}>
                                    {USERS_ROLE_TITLES[role]}
                                  </MenuItem>
                                ))
                                : (
                                  <MenuItem value={USER_SOCIETY_EDITOR_ROLE}>
                                    {USERS_ROLE_TITLES[USER_SOCIETY_EDITOR_ROLE]}
                                  </MenuItem>
                                )
                            }
                          </Select>
                          { showError && <FormHelperText>{meta.error || meta.submitError}</FormHelperText> }
                        </FormControl>
                      );
                    }
                  }
                </Field>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  value={user?.email || ''}
                  InputProps={{ readOnly: true }}
                  inputProps={{ 'data-seleniumid': 'user-email' }}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Field name="active" type="checkbox">
                  {({ input }) => (
                    <FormControlLabel
                      control={(
                        <Checkbox
                          name={input.name}
                          onChange={input.onChange}
                          checked={input.checked}
                          value={input.checked}
                          color="secondary"
                          inputProps={{ 'data-seleniumid': 'user-active' } as InputHTMLAttributes<HTMLInputElement>}
                        />
                      )}
                      label="Active"
                      color="secondary"
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </form>
        </Modal>
      )}
    />
  );
};

export default ManageUserModal;
