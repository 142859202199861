import { SUCCESS } from 'src/app/redux/asyncMiddleware';
import { RootState } from 'src/app/redux/store';

export const initialState = {};
export const LOAD_TOC = 'LOAD_TOC';

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  if (type === LOAD_TOC + SUCCESS) {
    const { id, toc } = payload;
    return { ...state, [id]: toc };
  }

  return state;
};

export const getTOC = (id: string | null) => (state: RootState):string => (id ? (state.issueManage.TOC[id] || '') : '');
