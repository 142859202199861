import React, { useEffect, useState, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NO_RESULTS_MESSAGE } from 'src/app/constants';
import useWindowSize from 'src/app/utils/hooks/useWindowSize';
import Header from './Header';
import PaginationActions from './PaginationActions';
import { ITable, ITableColumn, ITableCell, ITableRow } from './Interfaces';

export const ACTIONS_KEY = 'table_actions';

interface TableCellProps {
  'data-dtrum-mask'?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flexGrow: 1,
  },
  loaderWrap: {
    backgroundColor: fade(theme.palette.background.paper, 0.7),
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    width: '100%',
    padding: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const usePaginationStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    visibility: ({ visible }: { visible: boolean }) => (visible ? 'visible' : 'hidden'),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 0,
    marginRight: theme.spacing(0.5),
  },
  caption: {
    marginRight: theme.spacing(3),
  },
  toolbar: {
    paddingRight: '0',
    minHeight: '58px',
  },
}));

const useTableStyles = makeStyles(theme => ({
  tableContainer: (props: { tableContainerHeight: number, classes?: object }) => ({ maxHeight: props.tableContainerHeight }),
  table: {
    tableLayout: 'fixed',
  },
  tbody: {},
  headerRow: {
    backgroundColor: theme.palette.grey[100],
  },
  headerCell: {
    padding: theme.spacing(2, 3),
  },
  row: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  rowCell: {
    padding: theme.spacing(2, 3),
  },
  clickableRow: {
    cursor: 'pointer',
  },
  tableTopWrapper: {
    display: 'flex',
  },
  additionalText: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 3),
    textTransform: 'uppercase',
    position: 'absolute',
    bottom: '100%',
    transform: 'translate(0, 50%)',
    zIndex: 1,
  },
}));

const DefaultCellRender = ({ value }: ITableCell) => <span>{value}</span>;

const sliceItems = (items: any, currentPage: number, rowsPerPage: number) => items.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);

const Table = ({
  seleniumId,
  columns,
  items,
  error,
  totalItemsCount = items.length,
  autoPage = false,
  topPaginationVisibility = false,
  topPaginationDisplay = true,
  isLoading = false,
  showPagination = true,
  currentPage = 0,
  rowsPerPage = 25,
  rowsPerPageOptions = [25, 50],
  classes: classesOverride,
  sortField = '',
  sortDir,
  dataType,
  onOrderChange,
  onRowClick,
  onChangePage,
  onChangeRowsPerPage,
  additionalTopText,
}: ITable) => {
  const [, windowHeight] = useWindowSize();
  const [lastWindowHeight, setLastWindowHeight] = useState(windowHeight);
  const [stats, setStats] = useState({ currentPage, rowsPerPage });
  const [tableContainerHeight, setTableContainerHeight] = useState(0);

  const emptyError = !isLoading && !items.length ? NO_RESULTS_MESSAGE : '';
  const errorMessage = error || emptyError;

  const classes = useStyles();
  const tableClasses = useTableStyles({ classes: classesOverride, tableContainerHeight });
  const paginationClasses = usePaginationStyles({ visible: !errorMessage && topPaginationVisibility });

  useEffect(() => {
    setStats({ currentPage, rowsPerPage });
  }, [currentPage, rowsPerPage]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    if (onChangeRowsPerPage) onChangeRowsPerPage(value);
    else if (autoPage) {
      setStats({ currentPage: 0, rowsPerPage: value });
    }
  };

  const handleChangePage = (_ :any, newPage: number) => {
    if (onChangePage) onChangePage(newPage);
    else if (autoPage) {
      setStats({ ...stats, currentPage: newPage });
    }
  };

  const rows: ITableRow[] = autoPage ? sliceItems(items, stats.currentPage, stats.rowsPerPage) : items;

  const onClick = useCallback((row: ITableRow) => () => {
    if (onRowClick) onRowClick(row);
  }, [onRowClick]);

  const componentRef = useRef<HTMLDivElement>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableTopWrapperRef = useRef<HTMLDivElement>(null);
  const tablePaginationRef = useRef<HTMLDivElement>(null);
  const errorMessageRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const componentHeight = componentRef?.current?.offsetHeight || 0;
    const tableTopWrapperHeight = tableTopWrapperRef?.current?.offsetHeight || 0;
    const tablePaginationHeight = tablePaginationRef?.current?.offsetHeight || 0;
    const errorMessageHeight = errorMessageRef?.current?.offsetHeight || 0;
    const progressHeight = progressRef?.current?.offsetHeight || 0;

    const resultHeight = componentHeight - tableTopWrapperHeight - tablePaginationHeight - errorMessageHeight - progressHeight;
    if (resultHeight > 0) setTableContainerHeight(resultHeight);
  }, [
    tableTopWrapperRef.current,
    componentRef.current,
    tablePaginationRef.current,
    errorMessageRef.current,
    progressRef.current,
    items,
  ]);

  useEffect(() => {
    if (tableContainerRef?.current?.scrollTo) {
      tableContainerRef.current.scrollTo(0, 0);
    }
  }, [currentPage]);

  useEffect(() => {
    if (!windowHeight) return;
    if (windowHeight && lastWindowHeight) {
      const sizeDifferent = windowHeight - lastWindowHeight;
      setTableContainerHeight(tableContainerHeight + sizeDifferent);
    }
    setLastWindowHeight(windowHeight);
  }, [windowHeight]);

  return (
    <Paper data-seleniumid={`${seleniumId || 'component'}-table`} className={classes.root} elevation={0} ref={componentRef}>
      <div className={tableClasses.tableTopWrapper} ref={tableTopWrapperRef}>
        { additionalTopText && additionalTopText.value.length > 0 && (
          <Typography variant="body2" data-seleniumid={additionalTopText.seleniumId} className={tableClasses.additionalText}>
            {additionalTopText.value}
          </Typography>
        )}
        {
          topPaginationDisplay && (
            <TablePagination
              classes={paginationClasses}
              ActionsComponent={PaginationActions}
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={totalItemsCount}
              rowsPerPage={stats.rowsPerPage}
              page={stats.currentPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              SelectProps={{
                id: 'rows-per-page-selector',
                MenuProps: { MenuListProps: { id: 'rows-per-page-list' } },
              }}
            />
          )
        }
      </div>
      <TableContainer className={tableClasses.tableContainer} ref={tableContainerRef}>
        <MuiTable
          className={tableClasses.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          stickyHeader
        >
          <Header
            classes={tableClasses}
            order={sortDir}
            orderBy={sortField}
            onRequestSort={onOrderChange}
            columns={columns}
          />
          <TableBody className={tableClasses.tbody} data-seleniumid="data-table-body">
            {rows.map((row: ITableRow, i: number) => (
              <TableRow
                className={cn(tableClasses.row, { [tableClasses.clickableRow]: !!onRowClick })}
                onClick={onClick(row)}
                data-seleniumid={`${row.id || i}-${dataType}-row`}
                tabIndex={-1}
                key={row.id ? `${row.id}-${i}` : i}
                hover={true}
              >
                {columns.map((column: ITableColumn, j: number) => {
                  const { field, render, dataModifier } = column;
                  const key = `${row.id || j}-${field}-cell`;
                  const CellRender = render || DefaultCellRender;
                  const rawValue = field === ACTIONS_KEY ? row : row[field];
                  const additionalProps: TableCellProps = {};
                  if (column?.ignoreTracking) {
                    additionalProps['data-dtrum-mask'] = true;
                  }
                  return (
                    <TableCell
                      className={tableClasses.rowCell}
                      key={key}
                      data-seleniumid={key}
                      {...additionalProps}
                    >
                      <CellRender
                        id={row.id}
                        row={row}
                        value={dataModifier ? dataModifier(rawValue) : rawValue}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {errorMessage && (
        <div className={classes.error} ref={errorMessageRef}>
          <Typography align="center" variant="h6" data-seleniumid="table-error">{errorMessage}</Typography>
        </div>
      )}
      {(!errorMessage && showPagination) && (
        <TablePagination
          classes={paginationClasses}
          ActionsComponent={PaginationActions}
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalItemsCount}
          rowsPerPage={stats.rowsPerPage}
          page={stats.currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ref={tablePaginationRef}
        />
      )}
      {isLoading && <div ref={progressRef} className={classes.loaderWrap}><CircularProgress /></div>}
    </Paper>
  );
};

export default Table;
