import _ from 'lodash';
import { SUCCESS, FAILURE } from 'src/app/redux/asyncMiddleware';
import { fetchSocieties } from 'src/app/redux/api';
import { ISociety } from 'src/mock/db';
import { NO_ACTION_MESSAGE, TRY_LATER } from 'src/app/constants';

export const initialState = {
  isLoading: false,
  error: null,
  items: [],
};

export const LOAD_SOCIETIES = 'LOAD_SOCIETIES';

export default (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_SOCIETIES:
      return { ...state, error: null, isLoading: true };
    case LOAD_SOCIETIES + SUCCESS:
      return { items: payload, error: null, isLoading: false };
    case LOAD_SOCIETIES + FAILURE:
      return { items: [], error: payload };
    default:
      return state;
  }
};

export const loadSocieties = () => ({
  type: LOAD_SOCIETIES,
  transformResult: (items: ISociety[]) => _.orderBy(items, ['name'], ['asc']).map(society => ({
    ...society,
    journals: society.journals.sort((a, b) => a.id.localeCompare(b.id)),
  })),
  transformError: (e: any) => e?.response?.data?.message || NO_ACTION_MESSAGE('load Societies. ') + TRY_LATER,
  asyncCall: () => fetchSocieties(),
});

export const getSocieties = (state: any) => state.societies.items;
export const getSociety = (state: any, id: string) => state.societies.items.find((society: ISociety) => society.id === id) || null;
export const getSocietiesError = (state: any) => state.societies.error;
export const getSocietiesIsLoading = (state: any) => state.societies.isLoading;
