import { stringDateToDateModifier } from 'src/app/utils/date';
import { JournalCell, StatusCell, TitleCell, TypeCell } from '../components/IssuesList/IssuesCell';

const dateModifier = (value: string) => stringDateToDateModifier(value);

export const issues = [
  { field: 'uiState', title: 'Status', render: StatusCell, width: 15 },
  { field: 'name', title: 'Issue Title', render: TitleCell, width: 30 },
  { field: 'publicationType', title: 'Issue Type', render: TypeCell, width: 10 },
  { field: 'isPartOfPeriodical', title: 'Journal ID', render: JournalCell, width: 10 },
  { field: 'coverDate', title: 'First Published', dataModifier: dateModifier, width: 10 },
  { field: 'dateAccepted', title: 'Last Updated', dataModifier: dateModifier, width: 10 },
  { field: 'modified',
    title: 'Actual Modified Date',
    dataModifier: dateModifier,
    width: 15,
    sortable: true,
    columnStyles: {
      tableSortLabel: {
        cursor: 'default',
      },
    },
  },
];
