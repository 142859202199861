import { routes } from 'src/app/router/routes';
import PeopleIcon from '@material-ui/icons/People';
import GrainIcon from '@material-ui/icons/Grain';
import AssignmentIcon from '@material-ui/icons/Assignment';


const { VI, SOCIETIES, USERS } = routes;
export default [
  {
    id: 'VI',
    title: 'Virtual Issues',
    href: VI.path,
    icon: AssignmentIcon,
  },
  {
    id: 'SOCIETIES',
    title: 'Societies',
    href: SOCIETIES.path,
    icon: GrainIcon,
  },
  {
    id: 'USERS',
    title: 'Users',
    href: USERS.path,
    icon: PeopleIcon,
  },
];
