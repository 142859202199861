import { Base64 } from 'js-base64';
import * as _ from 'lodash';
import { toastr, ToastrEmitter } from 'react-redux-toastr';
import { VI_TITLE_MAX_LENGTH } from 'src/app/constants';
import { errorsByCode } from 'src/app/utils/requestErrors';
import { Article, VirtualIssue } from 'src/mock/db';
import { descriptionFormData } from '../Tabs/Description';
import { Description } from '../Tabs/Description/interfaces';
import { isRequiredFieldsFilled } from '../Tabs/Description/validation';
import { getHeaderTemplate, isTOCStructureValid, parseHTMtoTOC, parseTOCtoHTM } from '../Tabs/TOC/utils';
import { VIFormData, VIModifyBody } from './interfaces';

function getYesterdayISOSDate(): string {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return new Date(yesterday).toISOString();
}

export const getDataTemplate = (isEdit:boolean): VIFormData => {
  const description = descriptionFormData;
  const currentDate = (new Date()).toISOString();
  return {
    description: isEdit
      ? { ...description, lastUpdated: currentDate }
      : { ...description, firstPublished: getYesterdayISOSDate(), lastUpdated: currentDate },
    toc: [getHeaderTemplate(true)],
  };
};

export enum VIFormMessageTypes {
  dates,
  title,
  doiExists,
  forbidden,
  serviceUnavailable,
  formatDoiInvalid,
  patternDoiInvalid,
  missingDoiInvalid,
  uniqueRequestDoiInvalid,
  description,
  date,
  doiValid,
  createSuccess,
  publishSuccess,
  formInvalid,
}

export const showToastr = (
  type: number | null = null,
  severity: string = 'error',
  title: string = 'Error',
  message: string = 'Unexpected error occured',
) => {
  switch (type) {
    case VIFormMessageTypes.dates:
      toastr[severity as keyof ToastrEmitter]('Invalid dates', 'The First Published date must not come after the Last Published date.');
      break;
    case VIFormMessageTypes.title:
      toastr[severity as keyof ToastrEmitter]('Invalid title', `The title field cannot exceed ${VI_TITLE_MAX_LENGTH} characters.`);
      break;
    case VIFormMessageTypes.doiExists:
      toastr[severity as keyof ToastrEmitter]('DOI exists', errorsByCode.FAILED_CREATE_ISSUE(409));
      break;
    case VIFormMessageTypes.serviceUnavailable:
      toastr[severity as keyof ToastrEmitter]('Service is unavailable', errorsByCode.FAILED_CREATE_ISSUE(503));
      break;
    case VIFormMessageTypes.forbidden:
      toastr[severity as keyof ToastrEmitter]('Resource forbidden', errorsByCode.FAILED_CREATE_ISSUE(403));
      break;
    case VIFormMessageTypes.missingDoiInvalid:
      toastr[severity as keyof ToastrEmitter]('DOI missing', 'You have not added a valid DOI. Please add one with a correct format.');
      break;
    case VIFormMessageTypes.patternDoiInvalid:
      toastr[severity as keyof ToastrEmitter]('DOI invalid', 'Please follow the sample DOI format');
      break;
    case VIFormMessageTypes.uniqueRequestDoiInvalid:
      toastr[severity as keyof ToastrEmitter]('Description', 'Sorry, we couldn\'t verify DOI.');
      break;
    case VIFormMessageTypes.formatDoiInvalid:
      toastr[severity as keyof ToastrEmitter]('DOI invalid', 'The format must be the journal DOI followed by a dot (.) and the VI segment.');
      break;
    case VIFormMessageTypes.description:
      toastr[severity as keyof ToastrEmitter]('Invalid description', `The description field cannot exceed ${VI_TITLE_MAX_LENGTH} characters`);
      break;
    case VIFormMessageTypes.date:
      toastr[severity as keyof ToastrEmitter]('Invalid date', 'Please make sure the date fields are valid.');
      break;
    case VIFormMessageTypes.doiValid:
      toastr[severity as keyof ToastrEmitter]('Valid DOI', 'This DOI is unique and valid.');
      break;
    case VIFormMessageTypes.createSuccess:
      toastr.success('Success', 'Issue saved successfully.');
      break;
    case VIFormMessageTypes.publishSuccess:
      toastr.success('Success', 'Issue successfully published.');
      break;
    case VIFormMessageTypes.formInvalid:
      toastr.error('Form error', 'Please check all invalid fields.');
      break;
    default:
      toastr[severity as keyof ToastrEmitter](title, message);
  }
};

export const getIssueFormData = (issue: VirtualIssue, toc: string, articles: Article[]): VIFormData => {
  const { doi, coverDate, publicationType, created, name, description = '', isPartOfPeriodical: { id: journalId } } = issue;
  const { id: coverId, caption } = issue?.hasTableOfContents?.hasCover || {};
  const dateAccepted = (new Date()).toISOString();
  return {
    description: {
      doi,
      firstPublished: coverDate,
      lastUpdated: dateAccepted,
      realLastUpdated: created,
      title: name,
      description: Base64.decode(description),
      journalId,
      publicationType: publicationType || 'pub:IssueVirtual',
    },
    toc: parseHTMtoTOC(toc, articles),
    tocId: issue?.hasTableOfContents?.id,
    cover: coverId ? { id: coverId, caption } : undefined,
  };
};


export const getCreationData = (formData: VIFormData, isEdit: boolean): VIModifyBody => {
  const { description, toc, tocId, cover: hasCover, journalDOI } = formData;
  const { firstPublished, lastUpdated, title, description: viDescription, doi, journalId } = description;
  const issueNumber = doi.replace(`${journalDOI}.`, '');
  const htmToc = parseTOCtoHTM(toc) || undefined;

  const result: VIModifyBody = {
    coverDate: firstPublished,
    dateAccepted: lastUpdated || null,
    name: title,
    issueNumber,
    doi,
    isPartOfPeriodical: { id: journalId },
    description: Base64.encode(viDescription),
    publicationType: description.publicationType,
  };

  let hasTableOfContents;
  if (isEdit) {
    hasTableOfContents = {
      id: (htmToc && tocId) ? tocId : undefined,
      htmToc,
      hasCover,
    };
  }
  else if (!isEdit && (htmToc || hasCover)) {
    hasTableOfContents = {
      hasCover,
      htmToc,
    };
  }

  result.hasTableOfContents = hasTableOfContents;
  if (result?.hasTableOfContents?.htmToc?.length) {
    result.hasTableOfContents.htmToc = result?.hasTableOfContents?.htmToc.trim();
  }

  return result;
};

const isDescriptionValid = (description: Description) => {
  const isRequired = isRequiredFieldsFilled(description);
  if (!isRequired) {
    toastr.error('Description', 'Please fill out all required fields.');
    return false;
  }

  return true;
};

export const preSaveValidation = ({ toc, description }: VIFormData) => {
  let isInvalid = false;
  const isTOCValid = isTOCStructureValid(toc);
  if (!isTOCValid) {
    const isTheOnlyHeader = !!(toc?.filter(({ type }) => type === 'header')?.length === 1);
    toastr.error('TOC', `Please add journal articles${!isTheOnlyHeader ? ' or remove extra headings' : ''}.`);
    isInvalid = true;
  }
  const isFormValid = isDescriptionValid(description);
  if (!isFormValid) {
    isInvalid = true;
  }

  return !isInvalid;
};

export const trimText = (field: any) => (typeof field === 'string' ? field.replace(/^\s+|\s+$/g, '') : field);
export const trimFields: any = (data: any) =>
  _.mapValues(data, (element) =>
    // eslint-disable-next-line no-nested-ternary
    (_.isArray(element)
      ? element.map((value) =>
        (_.isObject(value) ? trimFields(value) : trimText(value)),
      )
      : _.isObject(element)
        ? trimFields(element)
        : trimText(element)),
  );

export const prepareCoverCaption = (id: string) => `
    <li typeof="wbas:ContentPart">
        <figure class="cover" typeof="wbas:Cover" resource="https://ccp.wiley.com/Contents/${id}">
            <figcaption class="caption-titled"></figcaption>
        </figure>
    </li>
`;
