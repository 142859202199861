import React, { FunctionComponent } from 'react';
import Navbar from 'src/app/components/layout/Navbar';
import PaperLayout from 'src/app/components/layout/PaperLayout';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { IRouteProps, routes } from 'src/app/router/routes';
import { getIsAuthorized } from 'src/app/pages/Login/redux/AuthDucks';
import InfoView from 'src/app/components/InfoView';
import Permission from './Permission';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey['100'],
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const PrivateRoute: FunctionComponent<IRouteProps> = ({
  component: Component,
  requiredRoles,
  layoutProps,
  ...rest
}) => {
  const isTokenValid = useSelector(getIsAuthorized);
  const classes = useStyles();
  const location = useLocation();

  return (
    <div className={classes.root}>
      <Navbar />
      <PaperLayout {...layoutProps}>
        <Permission requiredRoles={requiredRoles} Fallback={() => <InfoView seleniumId="forbidden" title="403" text="Forbidden" />}>
          <Route
            {...rest}
            render={props =>
              (isTokenValid ? <Component {...props} /> : <Redirect to={{ pathname: routes.LOGIN.path, state: { from: location } }} />)
            }
          />
        </Permission>
      </PaperLayout>
    </div>
  );
};

PrivateRoute.defaultProps = {
  path: '/',
};

export default PrivateRoute;
