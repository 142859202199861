import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { VALID_SEARCH_INPUT_LENGTH } from '../constants';

interface ISearchInput {
  value: string;
  seleniumId: string;
  placeholder: string;
  isAfterSearch?: boolean;
  onSearch(value: string): void;
  validate?(value: string): string;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '24rem',
  },
  label: {
    background: 'white',
    paddingRight: theme.spacing(0.5),
  },
  closeIcon: {
    color: theme.palette.grey[400],
    '&.hidden': {
      display: 'none',
    },
  },
  searchIcon: {
    color: theme.palette.primary.main,
  },
}));

const SearchInput = (props: ISearchInput) => {
  const { value, onSearch, placeholder, seleniumId, validate = () => '', isAfterSearch = false } = props;

  const [localValue, setLocalValue] = useState(value);
  const [error, setError] = useState('');

  const classes = useStyles();

  useEffect(() => {
    if (!value) setLocalValue('');
  }, [value]);

  const onSubmit = () => {
    const validateError = validate(localValue);

    if (!validateError) {
      if (localValue !== value) {
        onSearch(localValue);
      }
    }
    else {
      setError(validateError);
    }
  };

  const onBlur = () => {
    if (isAfterSearch && localValue.length === 0) {
      onSearch('');
    }
  };

  const handleChange = (value: string) => {
    setLocalValue(value);
    if (value.length <= VALID_SEARCH_INPUT_LENGTH) {
      if (error) {
        setError('');
      }
    }
    else {
      setError(`Maximum ${VALID_SEARCH_INPUT_LENGTH} characters allowed`);
    }
  };

  return (
    <FormControl className={classes.root} variant="outlined" onBlur={onBlur}>
      <InputLabel
        error={!!error}
        className={classes.label}
        htmlFor="search-input"
        data-seleniumid={`${seleniumId}-label`}
      >
        {error || placeholder}
      </InputLabel>
      <OutlinedInput
        data-seleniumid={`${seleniumId}-input`}
        error={!!error}
        id="search-input"
        value={localValue}
        onChange={e => handleChange(e.target.value)}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === 'Enter') {
            if (!error) {
              setError('');
              onSubmit();
            }
          }
        }}
        endAdornment={(
          <Box display="flex">
            {localValue && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setError('');
                    setLocalValue('');
                    onSearch('');
                  }}
                  edge="end"
                  data-seleniumid={`${seleniumId}-close-button`}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </InputAdornment>
            ) }
            <InputAdornment position="end">
              <IconButton
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
                edge="end"
                data-seleniumid={`${seleniumId}-search-button`}
                disabled={!!error || !localValue}
              >
                <SearchIcon className={error || !localValue ? '' : classes.searchIcon} />
              </IconButton>
            </InputAdornment>

          </Box>
        )}
        labelWidth={70}
      />
    </FormControl>
  );
};

export default SearchInput;
