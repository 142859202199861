import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { routes } from 'src/app/router/routes';
import { Location } from 'history';
import { getIsAuthorized, getIsAuthorizing, getReturnURL, login } from './redux/AuthDucks';
import Login, { SubmitProps } from './Login';

interface ILocationRedirect {
  from?: Location
}

const LoginContainer: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsAuthorizing);
  const isAuthorized = useSelector(getIsAuthorized);
  const returnUrl = useSelector(getReturnURL);

  const location: Location<ILocationRedirect> = useLocation();

  const redirectUrl: string = returnUrl || location.state?.from?.pathname || '';

  useEffect(() => {
    if (isAuthorized) history.replace(routes.VI.path);
  }, []);

  const handleLogin = async ({ email, password }: SubmitProps) => {
    dispatch(login({ email, password, redirectUrl }));
  };

  return (
    <Login
      loading={isLoading}
      handleLogin={handleLogin}
    />
  );
};

export default LoginContainer;
