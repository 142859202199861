import React, { memo } from 'react';
import { VirtualIssue } from 'src/mock/db';
import { makeStyles } from '@material-ui/core/styles';
import StatusCell from 'src/app/pages/VirtualIssues/components/IssuesList/IssuesCell/StatusCell';

const useStyles = makeStyles(theme => ({
  status: {
    '& svg': {
      marginRight: theme.spacing(0),
    },
  },
}));

interface IssueStatus {
  id: VirtualIssue['id'];
  uiState: VirtualIssue['uiState'];
}

const IssueStatus = ({ id, uiState }: IssueStatus) => {
  const classes = useStyles();

  if (!uiState) return null;

  return (
    <div className={classes.status}>
      <StatusCell id={id} value={uiState} />
    </div>
  );
};

export default memo(IssueStatus);
