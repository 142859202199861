import React, { FC, useState, useEffect, memo, ReactNode } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import { useIsFirstRender } from 'src/app/utils/hooks/useIsFirstRender';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { FieldId } from '../interfaces';

const useStyles = makeStyles(theme => ({
  field: {
    width: '100%',
  },
}));

interface IDatePicker extends Omit<OutlinedTextFieldProps, 'onChange' | 'onBlur' | 'variant'> {
  id: FieldId;
  error?: boolean;
  label: string;
  required?: boolean;
  variant?: OutlinedTextFieldProps['variant'];
  onChange:(field: FieldId, value: string) => void;
  defaultValue: string;
  maxDate?: Date;
  onBlur?(field: FieldId, value: string, error?: boolean): void;
  maxDateMessage?: string;
}

export const PICKER_DATE_FORMAT = 'yyyy/MM/dd';
export const REQUEST_PAYLOAD_DATE_FORMAT = 'yyyy-MM-dd';

const parseValue = (date: Date | null, maxDate?: Date) => {
  let newDate = '';
  if (maxDate && date && date > maxDate) {
    return newDate;
  }
  try {
    newDate = date ? format(date, REQUEST_PAYLOAD_DATE_FORMAT) : '';
  }
  // eslint-disable-next-line no-empty
  catch (e) {}

  return newDate;
};

const DatePicker: FC<IDatePicker> = ({
  id,
  label,
  error = false,
  required,
  onChange,
  defaultValue,
  maxDate,
  onBlur,
  helperText,
  maxDateMessage,
}) => {
  const [errField, setErrField] = useState(false);
  const [selectedDate, setSelectedDate] = useState(defaultValue ? new Date(defaultValue) : null);
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    onChange(id, parseValue(date, maxDate));
  };

  const { field } = useStyles();
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    handleDateChange(selectedDate);
  }, []);

  useEffect(() => {
    if (onBlur) {
      onBlur(id, '', errField);
    }
  }, [errField]);

  useEffect(() => {
    if (!isFirstRender) {
      if (onBlur) {
        onBlur(id, parseValue(selectedDate, maxDate));
      }
    }
  }, [selectedDate]);

  useEffect(() => {
    if (defaultValue && !selectedDate) {
      handleDateChange(new Date(defaultValue));
    }
  }, [defaultValue]);

  interface ExtendedKeyboardDatePickerProps extends KeyboardDatePickerProps {
    'data-seleniumid': string;
    maxDateMessage: string;
  }
  let keyboardDatePickerProps: ExtendedKeyboardDatePickerProps = {
    error,
    className: field,
    'data-seleniumid': `${id}-date-field`,
    inputVariant: 'outlined',
    required,
    disableToolbar: true,
    variant: 'inline',
    format: PICKER_DATE_FORMAT,
    id: `date-picker-${id}`,
    placeholder: 'YYYY/MM/DD',
    label,
    value: selectedDate ?? null,
    onChange: handleDateChange,
    onError: (error: ReactNode) => setErrField(!!error),
    KeyboardButtonProps: {
      'aria-label': 'change date',
    },
    maxDateMessage: maxDateMessage ?? `${label} date should not be later than ${maxDate}`,
    maxDate,
  };

  if (helperText) {
    keyboardDatePickerProps = {
      ...keyboardDatePickerProps,
      helperText,
      TextFieldComponent: TextField,
    };
  }

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker {...keyboardDatePickerProps} />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default memo(DatePicker);
