import React, { memo } from 'react';
import { Tab, Tabs, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ITheme } from 'src/app/App';
import { TabValidate } from '../common/interfaces';

interface IssueTabs {
  tabValue: number;
  setTabValue(value:number): void;
  tabValidate: TabValidate;
  showErrorTab: boolean;
}

const useTabsStyles = makeStyles((theme: ITheme) => ({
  root: {
    color: theme.palette.typical.accent,
    height: '2rem',
  },
  indicator: {
    backgroundColor: theme.palette.typical.accent,
  },
}));

const useTabErrorStyle = makeStyles(theme => ({
  wrapper: {
    color: theme.palette.error.main,
  },
}));

const IssueTabs = memo(({ tabValue, setTabValue, tabValidate, showErrorTab }:IssueTabs) => {
  const classes = useTabsStyles();
  const tabErrorClasses = useTabErrorStyle();
  const theme = useTheme();

  const getTabIndicatorStyle = () => {
    const tabIndicatorColor = theme.palette.error.main;
    switch (tabValue) {
      case 0:
        if (!tabValidate['description' as keyof TabValidate]) return tabIndicatorColor;
        break;
      case 1:
        if (!tabValidate['cover' as keyof TabValidate]) return tabIndicatorColor;
        break;
      case 2:
        if (!tabValidate['toc' as keyof TabValidate]) return tabIndicatorColor;
        break;
      default:
        return '';
    }
  };

  return (
    <Tabs
      value={tabValue}
      onChange={(event: React.ChangeEvent<{}>, newValue: number) => setTabValue(newValue)}
      classes={classes}
      TabIndicatorProps={{
        style: {
          backgroundColor: getTabIndicatorStyle(),
        },
      }}
    >
      <Tab label="VI description" data-seleniumid="manage-vi-tab-descrption-button" classes={!tabValidate.description ? tabErrorClasses : {}} />
      <Tab label="VI cover" data-seleniumid="manage-vi-tab-cover-button" classes={!tabValidate.cover ? tabErrorClasses : {}} />
      <Tab label="Table of Contents" data-seleniumid="manage-vi-tab-toc-button" classes={!tabValidate.toc ? tabErrorClasses : {}} />
      {showErrorTab && <Tab label="Delivery Errors" data-seleniumid="manage-vi-tab-errors-button" />}
    </Tabs>
  );
});

export default IssueTabs;
