import { SUCCESS, FAILURE } from 'src/app/redux/asyncMiddleware';
import { fetchVirtualIssues } from 'src/app/redux/api';
import { VirtualIssue } from 'src/mock/db';
import { SortDirection } from 'src/app/components/Table/Interfaces';
import { NO_ACTION_MESSAGE, TRY_LATER } from 'src/app/constants';
import { IssuesListFilter } from '..';

export type Stats = {
  rowsPerPage: number,
  currentPage: number,
  sortField: string,
  sortDir: SortDirection,
  rowsPerPageOptions: number[],
}

export type IssuesStore = 'liveIssues' | 'nonLiveIssues';

export const defaultStats = {
  rowsPerPage: 25,
  currentPage: 0,
  sortField: 'modified',
  sortDir: 'desc',
  rowsPerPageOptions: [25, 50],
};

export const initialState = {
  ...defaultStats,
  isLoading: false,
  error: null,
  items: [],
  totalItemsCount: 0,
};

export const LOAD_ISSUES = 'LOAD_ISSUES_';
export const SET_DEFAULT_OPTIONS = 'SET_DEFAULT_OPTIONS_';

export default (issuesStore: IssuesStore) => (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_ISSUES + issuesStore:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case LOAD_ISSUES + issuesStore + SUCCESS: {
      const { stats } = action;
      const { items, totalItemsCount } = payload;
      return {
        ...stats,
        isLoading: false,
        error: null,
        items,
        totalItemsCount,
      };
    }
    case LOAD_ISSUES + issuesStore + FAILURE:
      return {
        ...initialState,
        isLoading: false,
        error: payload,
      };
    case SET_DEFAULT_OPTIONS + issuesStore: {
      const { rowsPerPage, currentPage } = initialState;
      return {
        ...state,
        rowsPerPage,
        currentPage,
      };
    }
    default:
      return state;
  }
};

export const loadIssues = (issuesStore: IssuesStore, filter:IssuesListFilter, stats?:Stats) => {
  const relevantStats = stats || defaultStats;
  const { sortDir, sortField, currentPage, rowsPerPage } = relevantStats;
  const offset = currentPage * rowsPerPage;
  return {
    type: LOAD_ISSUES + issuesStore,
    payload: { stats: relevantStats },
    asyncCall: () => fetchVirtualIssues({ ...filter, sortDir, sortField, size: rowsPerPage, offset }),
    transformResult: ({ items = [], itemsCount = 0 }: { items: VirtualIssue[], itemsCount: number }) => ({ items, totalItemsCount: itemsCount }),
    transformError: () => NO_ACTION_MESSAGE('load issues. ') + TRY_LATER,
  };
};

export const setDefaultPageOptions = (issuesStore: IssuesStore) => ({ type: SET_DEFAULT_OPTIONS + issuesStore });

export const getIssues = (state: any, type: string) => state[type].items;
export const getIssuesTotalCount = (state: any, type: string) => state[type].totalItemsCount;
export const getIssuesIsLoading = (state: any, type: string) => state[type].isLoading;
export const getIssuesError = (state: any, type: string) => state[type].error;
export const getIssuesStats = (state: any, type: string) => {
  const store = state[type];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading, error, items, totalItemsCount, ...stats } = store;
  return stats;
};
