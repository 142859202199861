import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Route, useHistory } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector, useDispatch } from 'react-redux';
import { loadJournals } from 'src/app/redux/reducers/journals';
import { loadRecentJournals } from 'src/app/redux/reducers/profile/recentJournals';
import { makeStyles } from '@material-ui/core/styles';
import paths from 'src/app/router/paths';
import cn from 'classnames';
import { Button } from '@material-ui/core';
import SearchInput from 'src/app/components/SearchInput';
import { ITheme } from 'src/app/App';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { loadFeatureFlags } from 'src/app/redux/reducers/features';
import IssuesList from './components/IssuesList';
import JournalSelect from './components/JournalSelect';
import { LIVE_ISSUES_FILTER, NON_LIVE_ISSUES_FILTER } from './common/constants';
import { issues } from './common/issuesTableConfigs';
import {
  getIssuesTotalCount,
  getIssuesIsLoading,
  loadIssues,
  getIssuesStats,
  setDefaultPageOptions,
} from './components/IssuesList/redux/IssuesDucks';
import IssuesManage from './components/IssuesManage';
import EditIssue from './components/IssuesManage/EditIssue';

const LIVE_LABEL = 'Live issues';
const NON_LIVE_LABEL = 'Non-Live issues';

const useTabsClasses = makeStyles((theme: ITheme) => ({
  root: {
    color: theme.palette.typical.accent,
  },
  indicator: {
    backgroundColor: theme.palette.typical.accent,
  },
}));

const useTabClasses = makeStyles((theme: ITheme) => ({
  root: {
    minWidth: 0,
  },
  wrapper: {
    padding: theme.spacing(0, 1.5),
  },
}));

const useStyles = makeStyles((theme: ITheme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginTop: 0,
  },
  contentHeader: {
    position: 'relative',
    zIndex: theme.zIndex.appBar,
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    alignItems: 'center',
  },
  tabBar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    top: '100%',
    marginTop: theme.spacing(),
  },
  tabPanel: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    '&.hidden': {
      display: 'none',
    },
  },
  createButton: {
    height: 55,
    width: '10rem',
    marginLeft: '1rem',
  },
  searchResultsTypography: {
    color: theme.palette.typical.black,
    fontWeight: theme.typography.fontWeightMedium,
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    '& svg': {
      marginRight: '.5rem',
      color: theme.palette.grey[400],
    },
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  className: string,
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, className } = props;
  return <div className={cn(className, { hidden: value !== index })}>{children}</div>;
};

const getLoadedLabel = (isLoading:boolean, label: string) => (isLoading ? '..' : label);
const getResultsLabel = (count: number) => (count === 1 ? 'result' : 'results');

const VirtualIssues = () => {
  const [selectedJournal, setSelectedJournal] = useState<string | null>(null);
  const [searchJournal, setSearchJournal] = useState<string>('');
  const classes = useStyles();
  const tabsClasses = useTabsClasses();
  const tabClasses = useTabClasses();
  const history = useHistory();
  const dispatch = useDispatch();
  const { location: { pathname } } = history;
  const liveFilters = useMemo(
    () =>
      ({
        ...LIVE_ISSUES_FILTER,
        ...(searchJournal ? { name: searchJournal } : {}),
        ...(selectedJournal ? { isPartOfPeriodical: selectedJournal } : {}),
      }), [selectedJournal, searchJournal]);
  const nonLiveFilters = useMemo(
    () =>
      ({ ...NON_LIVE_ISSUES_FILTER,
        ...(searchJournal ? { name: searchJournal } : {}),
        ...(selectedJournal ? { isPartOfPeriodical: selectedJournal } : {}),
      }), [selectedJournal, searchJournal]);

  useEffect(() => {
    dispatch(loadFeatureFlags());
    dispatch(loadJournals());
    dispatch(loadRecentJournals());
  }, []);

  const totalCountLiveIssues = useSelector((state) => getIssuesTotalCount(state, 'liveIssues'));
  const totalCountNonLiveIssues = useSelector((state) => getIssuesTotalCount(state, 'nonLiveIssues'));
  const liveIsLoading = useSelector((state) => getIssuesIsLoading(state, 'liveIssues'));
  const nonLiveIsLoading = useSelector((state) => getIssuesIsLoading(state, 'nonLiveIssues'));
  const liveStats = useSelector((state) => getIssuesStats(state, 'liveIssues'));
  const nonLiveStats = useSelector((state) => getIssuesStats(state, 'nonLiveIssues'));
  const isLoading = liveIsLoading || nonLiveIsLoading;
  const totalCount = totalCountLiveIssues + totalCountNonLiveIssues;
  const isNonLiveVI = pathname.split('/').includes('nonLive');

  const onSave = (type: 'create' | 'edit') => {
    if (!selectedJournal && !searchJournal) {
      if (isNonLiveVI) {
        dispatch(loadIssues('liveIssues', liveFilters, liveStats));
      }
      else {
        dispatch(loadIssues('nonLiveIssues', nonLiveFilters, nonLiveStats));
      }
    }
    else {
      setSelectedJournal(null);
      setSearchJournal('');
    }

    history.replace(type === 'create' || isNonLiveVI ? paths.VIsNonLive : paths.VI);
  };

  const onSelectJournal = useCallback((id: string) => {
    setSearchJournal('');
    setSelectedJournal(id);
  }, [setSearchJournal, setSelectedJournal]);

  const selectedTab = pathname
    .replace(new RegExp(`(${paths.VIsNonLive}|${paths.VI}).*`), '$1');

  const isAdditionalTextShown = !!searchJournal;

  const additionalTopText = isAdditionalTextShown
    ? `${getLoadedLabel(isLoading, totalCount)} ${getResultsLabel(totalCount)} for "${searchJournal}"`
    : '';

  return (
    <div className={classes.paper}>
      <div className={classes.contentHeader}>
        <div data-seleniumid="vi-list-filters" className={classes.filters}>
          <JournalSelect
            seleniumId="vi"
            selectedJournal={selectedJournal}
            onSelectJournal={onSelectJournal}
          />
          <div>
            <SearchInput
              seleniumId="vi-search"
              placeholder="Enter VI title"
              value={searchJournal}
              isAfterSearch={isAdditionalTextShown}
              onSearch={(searchValue: string) => {
                setSelectedJournal('');
                setSearchJournal(searchValue);
                if (!searchValue) {
                  dispatch(setDefaultPageOptions('liveIssues'));
                  dispatch(setDefaultPageOptions('nonLiveIssues'));
                }
              }}
            />
            <Button
              startIcon={<AddCircleOutlineIcon />}
              variant="contained"
              color="primary"
              data-seleniumid="create-vi-button"
              className={classes.createButton}
              onClick={() => history.push(`${pathname}/manage`)}
            >
              Create Issue
            </Button>
          </div>
        </div>
        <div className={classes.tabBar}>
          <Tabs
            value={selectedTab}
            onChange={(event: React.ChangeEvent<{}>, newValue: string) => history.replace(newValue)}
            classes={tabsClasses}
          >
            <Tab
              classes={tabClasses}
              label={`${LIVE_LABEL} (${getLoadedLabel(liveIsLoading, totalCountLiveIssues)})`}
              value={paths.VI}
              data-seleniumid="live-issues-tab-btn"
            />
            <Tab
              label={`${NON_LIVE_LABEL} (${getLoadedLabel(nonLiveIsLoading, totalCountNonLiveIssues)})`}
              value={paths.VIsNonLive}
              data-seleniumid="nonLive-issues-tab-btn"
            />
          </Tabs>
        </div>
      </div>
      <TabPanel className={classes.tabPanel} value={selectedTab} index={paths.VI}>
        <IssuesList
          filter={liveFilters}
          type="liveIssues"
          tableConfig={issues}
          searchResultTitle={additionalTopText}
        />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={selectedTab} index={paths.VIsNonLive}>
        <IssuesList
          filter={nonLiveFilters}
          type="nonLiveIssues"
          tableConfig={issues}
          searchResultTitle={additionalTopText}
        />
      </TabPanel>
      <Route
        exact
        path={[`${paths.VI}/manage`, `${paths.VIsNonLive}/manage`]}
        render={() => (
          <IssuesManage
            journalId={selectedJournal}
            onClose={() => onSave('create')}
          />
        )}
      />
      <Route
        exact
        path={[
          `${paths.VI}/manage/:id`,
          `${paths.VIsNonLive}/manage/:id`,
        ]}
        render={({ match: { params: { id } } }) => (
          <EditIssue
            id={id}
            isLive={!isNonLiveVI}
            journalId={null}
            onClose={() => onSave('edit')}
          />
        )}
      />
    </div>
  );
};

export default VirtualIssues;
