import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ITableCell } from 'src/app/components/Table/Interfaces';
import { green, red } from '@material-ui/core/colors';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';


const useStyles = makeStyles(theme => ({
  active: {
    color: green[700],
  },
  inactive: {
    color: red[200],
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const UserStatusCell = (props: ITableCell) => {
  const classes = useStyles();
  const { value } = props;

  return (
    <div className={classes.center}>
      { value === 'Active'
        ? <CheckCircleOutline className={classes.active} fontSize="large" />
        : <HighlightOffIcon className={classes.inactive} fontSize="large" />
      }
    </div>
  );
};

export default UserStatusCell;
