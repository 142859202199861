import { fetchFeatureFlags } from 'src/app/redux/api';
import { SUCCESS, FAILURE } from 'src/app/redux/asyncMiddleware';

export const initialState = {
  isLoading: false,
  error: null,
  items: [],
};

export const LOAD_FEATURE_FLAGS = 'LOAD_FEATURE_FLAGS';

export default (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_FEATURE_FLAGS:
      return { ...state, error: null, isLoading: true };
    case LOAD_FEATURE_FLAGS + SUCCESS:
      return { items: payload, error: null, isLoading: false };
    case LOAD_FEATURE_FLAGS + FAILURE:
      return { items: [], error: payload, isLoading: false };
    default:
      return state;
  }
};

export const loadFeatureFlags = () => ({
  type: LOAD_FEATURE_FLAGS,
  asyncCall: () => fetchFeatureFlags(),
  transformError: (e: any) => (typeof e === 'string' ? e : e?.response?.data?.message || 'Failed to load feature flags. Please try again later.'),
});

export const getFeatureFlags = (state: any) => state.features.items;
export const getFeatureFlagByName = (state: any, flagName: string) => {
  if (!state.features) return false;
  const flag = state.features.items.find((flag: any) => flag.name === flagName);
  return flag ? flag.active : false;
};
export const getFeatureFlagsIsLoading = (state: any) => state.features.isLoading;
export const getFeatureFlagsError = (state: any) => state.features.error;
