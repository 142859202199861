/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'src/app/router/routes';
import { logout } from 'src/app/pages/Login/redux/AuthDucks';
import AppMenu from 'src/app/components/Menu';
import SvgLogo from 'src/app/components/common/SvgLogo';
import { RootState } from 'src/app/redux/store';
import Permission, { hasPermission } from 'src/app/router/Permission';
import {
  USERS_ROLE_TITLES,
  ROLE_NAME_LIMIT,
  USER_EXTERNAL_TYPE,
  USER_ADMIN_ROLE,
  USER_EDITOR_ROLE,
  USER_SOCIETY_EDITOR_ROLE,
} from 'src/app/constants';
import { BaseUser } from 'src/mock/db/DBSchema';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    toolbarWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      maxWidth: theme.breakpoints.values.xl,
    },
    logo: {
      cursor: 'pointer',
    },
    account: {
      position: 'relative',
    },
    accountBtn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: 22 + theme.spacing() / 2,
    },
    accountIcon: {
      position: 'absolute',
      top: 10,
      left: theme.spacing(),
    },
    accountRole: {
      alignSelf: 'flex-start',
      color: theme.palette.grey[600],
      fontSize: '0.7rem',
    },
  }),
);

const Navbar = () => {
  const classes = useStyles();
  const anchorRef = useRef(null);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    firstName = '',
    lastName = '',
    societies = [],
    type,
    role,
  } = useSelector((state: RootState) => state?.auth?.user || {}) as BaseUser;
  const [open, setOpen] = useState(false);
  const [showAccountIcon, setShowAccountIcon] = useState(false);

  useEffect(() => {
    setShowAccountIcon(location.pathname !== (routes.LOGIN.path as string));
  }, [location]);

  const handleToggle = () => setOpen(prevOpen => !prevOpen);
  const handleClose = () => setOpen(false);
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className={classes.root}>
      <AppBar color="inherit" position="static">
        <div className={classes.toolbarWrapper}>
          <Toolbar className={classes.toolbar}>
            <SvgLogo width={140} className={classes.logo} onClick={() => history.push(routes.VI.path as string)} />
            <Permission requiredRoles={[USER_ADMIN_ROLE]}>
              <AppMenu />
            </Permission>
            {showAccountIcon && (
              <div className={classes.account}>
                <Button
                  ref={anchorRef}
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleToggle}
                  startIcon={<AccountCircle classes={{ root: classes.accountIcon }} />}
                  size="large"
                  classes={{ label: classes.accountBtn }}
                  data-seleniumid="user-info-btn"
                  data-dtrum-mask
                >
                  {firstName} {lastName}
                  {
                    hasPermission([USER_ADMIN_ROLE, USER_EDITOR_ROLE, USER_SOCIETY_EDITOR_ROLE], role)
                    && (() => {
                      const roleName = USER_SOCIETY_EDITOR_ROLE === role && societies.length
                        ? societies[0].name
                        : `${type !== USER_EXTERNAL_TYPE ? `${type} / ` : ''}${USERS_ROLE_TITLES[role]}`;

                      const extendsMaxLength = roleName.length > ROLE_NAME_LIMIT;

                      return (
                        <Typography
                          noWrap
                          variant="subtitle2"
                          classes={{ root: classes.accountRole }}
                          title={extendsMaxLength ? roleName : ''}
                          component="div"
                          data-seleniumid="user-info-role"
                        >
                          {`${roleName.slice(0, ROLE_NAME_LIMIT)}${extendsMaxLength ? '...' : ''}`}
                        </Typography>
                      );
                    })()
                  }
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList>
                            <MenuItem onClick={handleLogout}><ExitToAppIcon /> Logout</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            )}
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
};

export default Navbar;
