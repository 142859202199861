import { takeLatest, call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { routes } from 'src/app/router/routes';
import { replace } from 'connected-react-router';
import { getToken } from 'src/app/services/auth';
import { TOKEN_KEY, USER_KEY } from 'src/app/constants';
import { authenticate, logout } from 'src/app/redux/api';
import ls from 'src/app/services/localStorage';
import { SET_NETWORK } from 'src/app/redux/reducers/network';
import { errorsByCode } from 'src/app/utils/requestErrors';
import {
  LOGOUT_REQUEST,
  LOGIN_REQUEST,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  Payload,
} from './AuthDucks';

interface ActionArg {
  payload: Payload;
  type: string;
}

export function* loginWorker({ payload }: ActionArg): SagaIterator {
  try {
    const [promise, url, redirectUrl] = yield call(authenticate, payload);
    yield put({ type: SET_NETWORK, url });
    const user = yield promise;
    const token = getToken();
    yield put({
      ...loginSuccess({
        user,
        token,
      }),
      url,
    });
    yield call(ls.setJSON, TOKEN_KEY, token);
    yield call(ls.setJSON, USER_KEY, user);
    yield put(replace(redirectUrl ?? routes.VI.path));
  }
  catch (e) {
    yield put(loginFailure(errorsByCode.FAILED_AUTHENTICATE_USER(e.response?.status)));
  }
}
export function* logoutWorker(): SagaIterator {
  try {
    const [promise] = yield call(logout);
    yield promise;
    yield put(logoutSuccess());
  }
  catch (e) {
    yield put(logoutFailure());
  }
  finally {
    yield call(ls.clear);
    yield put(replace(routes.LOGIN.path));
  }
}

export function* watchAuth() {
  yield takeLatest(LOGIN_REQUEST, loginWorker);
  yield takeLatest(LOGOUT_REQUEST, logoutWorker);
}
