import { Checkbox, FormControlLabel, FormLabel, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ITheme } from 'src/app/App';
import { VI_TITLE_MAX_LENGTH } from 'src/app/constants';
import { State } from 'src/app/redux/store';
import { Journal } from 'src/mock/db';
import { getFeatureFlagByName } from 'src/app/redux/reducers/features';
import JournalSelect from '../../../JournalSelect';
import { TabsID } from '../../common/interfaces';
import BaseTextField from './Fields/BaseTextField';
import DOIField from './Fields/DOIField';
import DatePicker, { PICKER_DATE_FORMAT } from './Fields/DatePickerField';
import { Description, FieldId, FieldMeta, FormMeta } from './interfaces';
import { requiredField, validationConfig } from './validation';

export const descriptionFormData: Description = {
  journalId: '',
  doi: '',
  firstPublished: '',
  lastUpdated: '',
  realLastUpdated: '',
  title: '',
  description: '',
  publicationType: 'wpub:IssueVirtual',
};

const useStyles = makeStyles((theme: ITheme) => ({
  grid: {
    flexGrow: 1,
    padding: theme.spacing(2),
    paddingBottom: 0,
    overflowY: 'auto',
  },
  gridItem: {
    display: 'flex',
    '& > div': {
      width: '100%',
    },
  },
}));

interface IDescription {
  tabId: TabsID,
  data: Description;
  issueId?: string;
  journal?: Journal;
  onValidate(id: TabsID, flag: boolean): void;
  onChange(field: FieldId, value: string | boolean): void;
  isEditDisabled: boolean,
  submitted: boolean,
}

const disabledOnEditFields: FieldId[] = ['journalId' as FieldId, 'doi' as FieldId];
const DescriptionTab: FC<IDescription> = ({ isEditDisabled, tabId, onValidate, data, issueId, journal, onChange, submitted }) => {
  const [formMeta, setFormMeta] = useState<FormMeta>({});
  const classes = useStyles();

  const { doi, title, description, lastUpdated, realLastUpdated, firstPublished, journalId, publicationType } = data;
  const updateMeta = useCallback((field: string, fieldMeta: FieldMeta) => {
    setFormMeta(meta => ({ ...meta, [field]: fieldMeta }));
  }, [setFormMeta]);

  const validateField = useCallback((field: FieldId, value: string, error?: boolean) => {
    if (typeof error === 'boolean') {
      return updateMeta(field, { error });
    }
    const isValid = (isEditDisabled && disabledOnEditFields.includes(field)) || validationConfig[field](value);
    updateMeta(field, { error: !isValid });
  }, [setFormMeta]);

  useEffect(() => {
    if (!isEditDisabled && journal && !doi.startsWith(journal.doi)) {
      const { id, doi: journalDoi = '' } = journal || {};
      updateMeta('doi', { validated: false, error: true });
      updateMeta('journalId', { validated: true });
      onChange('doi', `${journalDoi}.`);
      onChange('journalId', id);
    }
  }, [journal]);

  useEffect(() => {
    if (!submitted) return;
    const errorMeta: FormMeta = requiredField.reduce((acc: FormMeta, field: FieldId) => {
      const isValue = data[field];
      if (!isValue) acc[field] = { error: true };
      return acc;
    }, {});

    if (!_.isEmpty(errorMeta)) {
      setFormMeta(meta => ({ ...meta, ...errorMeta }));
    }
  }, [submitted]);

  useEffect(() => {
    const isValid = !Object.values(formMeta).some(meta => meta?.validated === false || meta?.error);
    onValidate(tabId, isValid);
  }, [formMeta]);

  const selectJournal = useCallback((id: string) => {
    onChange('journalId', id);
    updateMeta('journalId', { validated: true });
  }, [onChange, updateMeta]);

  const initialDoiValue = useSelector((store: State): string | undefined =>
    (issueId ? store?.issueManage?.Issues[issueId].doi : undefined));

  const showSpecialIssue = useSelector((state: State) => getFeatureFlagByName(state, 'ENABLE_SPECIAL_ISSUE'));

  return (
    <Grid container spacing={4} className={`${classes.grid} hideHorizontalScroll`}>
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <JournalSelect
          seleniumId="modify-vi"
          disabled={isEditDisabled}
          disableClearable
          error={formMeta?.journalId?.error}
          isRequired
          selectedJournal={journalId}
          onSelectJournal={selectJournal}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <DOIField
          error={formMeta?.doi?.error}
          value={doi}
          initialValue={initialDoiValue}
          disabled={isEditDisabled || !journal}
          journalDoi={journal?.doi || ''}
          onChange={onChange}
          updateMeta={updateMeta}
          meta={formMeta?.doi}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <DatePicker
          required
          defaultValue={firstPublished}
          error={formMeta?.firstPublished?.error}
          id="firstPublished"
          label="First Published"
          onChange={onChange}
          maxDateMessage="First Published date should not be later than Last updated date"
          onBlur={validateField}
          maxDate={new Date(lastUpdated)}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <DatePicker
          defaultValue={lastUpdated}
          error={formMeta?.lastUpdated?.error}
          id="lastUpdated"
          label="Last Updated"
          onChange={onChange}
          onBlur={validateField}
          helperText={realLastUpdated ? `Last updated on ${format(new Date(realLastUpdated), PICKER_DATE_FORMAT)}` : ''}
        />
      </Grid>
      <Grid item xs={12}>
        <BaseTextField
          id="title"
          value={title}
          error={formMeta?.title?.error}
          validate={validateField}
          onChange={onChange}
          valueLimit={VI_TITLE_MAX_LENGTH}
          required
          rows={2}
          title="Title"
        />
      </Grid>
      <Grid item xs={12}>
        <BaseTextField
          id="description"
          value={description}
          error={formMeta?.description?.error}
          validate={validateField}
          onChange={onChange}
          valueLimit={50000}
          rows={5}
          title="Description"
        />
      </Grid>
      {showSpecialIssue && (
        <Grid item xs={12}>
          <FormLabel component="legend">Type:</FormLabel>
          <FormControlLabel
            control={
              <Checkbox value={publicationType === 'wpub:IssueSpecial'} checked={publicationType === 'wpub:IssueSpecial'} name="publicationType" onChange={(_, checked) => onChange('publicationType', checked ? 'wpub:IssueSpecial' : 'wpub:IssueVirtual')} />
            }
            label="Special Issue"
            id="publicationType"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default memo(DescriptionTab);
