import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Help as HelpIcon, Close as CloseIcon } from '@material-ui/icons';
import { IconButton, Modal as MuiModal } from '@material-ui/core';
import PaperLayout from 'src/app/components/layout/PaperLayout';
import { ITheme } from 'src/app/App';

const useStyles = makeStyles((theme: ITheme) => ({
  status: {
    '& svg': {
      cursor: 'pointer',
      color: theme.palette.grey[600],
      transition: 'color .2s',
      marginRight: theme.spacing(0),
      '&:hover': {
        color: theme.palette.grey[900],
      },
    },
  },
  modal: {
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem',
    alignItems: 'center',
    boxShadow: '0px 0px 1px 1px rgba(0,0,0,0.2), 0px 0 1px 1px rgba(0,0,0,0.14), 0px 0 2px 1px rgba(0,0,0,0.12)',
    ...theme.typography.h5,
  },
  layout: {
    maxWidth: '60%',
  },
  modalBody: {
    padding: '1rem',
  },
  closeButton: {
    justifyContent: 'flex-end',
  },
  helpIcon: {
    height: '1.5rem',
  },
}));

const TOCHelp = () => {
  const classes = useStyles();
  const [showHelpModal, setShowHelpModal] = useState(false);
  const closeModal = () => setShowHelpModal(false);
  return (
    <div className={classes.status}>
      <HelpIcon className={classes.helpIcon} onClick={() => setShowHelpModal(!showHelpModal)} />
      <MuiModal
        disableAutoFocus={true}
        className={classes.modal}
        open={showHelpModal}
        onBackdropClick={closeModal}
      >
        <PaperLayout className={classes.layout}>
          <div>
            <div className={classes.modalHeader}>
              <div>Building up the Table of Contents</div>
              <div>
                <IconButton
                  className={classes.closeButton}
                  onClick={closeModal}
                  data-seleniumid="modal-close-button"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className={classes.modalBody}>
              <h3>Adding/removing articles</h3>
              Articles may be added by clicking 'Add Article by DOI' and providing a valid article DOI published on WOL.
              Any Wiley user can add article DOIs from any journal published on WOL. Society Editors can only edit their journals’ VIs.
              A group of DOIs may be entered in an article field with the DOIs separated by line breaks.
              An article may be removed from a VI by clicking the cross sign against the article.

              <h3>Adding/removing headers</h3>
              Headers may be added by clicking 'Add Header' and providing plain text.
              Header max length should not exceed 500 characters.
              Orphan headers are not allowed except for the default header.
              A header may be removed by clicking the cross sign against the header.

              <h3>Ordering articles and headers</h3>
              The order in which DOIs and headers are arranged on the page determines the order on the generated page on WOL.
              Articles and headers may be rearranged by 'drag-and-drop'.

              <h3>Limits</h3>
              Any changes made to TOC will overwrite what may already be on WOL. No changes made in WOL Content Admin will be reflected here.
              Any incorrect, unpublished, or duplicate DOIs are not allowed.
              HTML tags entered in headers will display verbatim on WOL.
            </div>
          </div>
        </PaperLayout>
      </MuiModal>
    </div>
  );
};

export default TOCHelp;
