import React from 'react';
import { ITableRow } from 'src/app/components/Table/Interfaces';
import { USERS_ROLE_TITLES } from 'src/app/constants';
import { UserRole } from 'src/mock/db';

const UserRoleCell = (props: ITableRow) => {
  if (!props?.value) return null;
  const { value } = props;
  return <div>{USERS_ROLE_TITLES[value as UserRole] || ''}</div>;
};

export default UserRoleCell;
