import _ from 'lodash';
import { SUCCESS, FAILURE } from 'src/app/redux/asyncMiddleware';
import { fetchRecentJournals, postRecentJournal } from 'src/app/redux/api';

interface RecentJournalsState {
  isLoading: boolean;
  error: string | null;
  items: string[];
}

export const RECENT_JOURNALS_SIZE = 10;

export const initialState: RecentJournalsState = {
  isLoading: false,
  error: null,
  items: [],
};

export const LOAD_RECENTLY_JOURNALS = 'LOAD_RECENTLY_JOURNALS';
export const CREATE_RECENT_JOURNAL = 'CREATE_RECENT_JOURNAL';

export default (state = initialState, action: any): RecentJournalsState => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_RECENTLY_JOURNALS:
      return { ...state, error: null, isLoading: true };
    case LOAD_RECENTLY_JOURNALS + SUCCESS:
    case CREATE_RECENT_JOURNAL + SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const items = _.uniq([...payload, ...state.items]);
      return {
        items: items.length > RECENT_JOURNALS_SIZE
          ? items.slice(0, RECENT_JOURNALS_SIZE)
          : items,
        error: null,
        isLoading: false,
      };
    case LOAD_RECENTLY_JOURNALS + FAILURE:
      return { items: [], error: payload, isLoading: false };
    default:
      return state;
  }
};

export const loadRecentJournals = () => ({
  type: LOAD_RECENTLY_JOURNALS,
  asyncCall: () => fetchRecentJournals({ size: RECENT_JOURNALS_SIZE }),
  transformResult: (items: { id: string }[]) => items.map((({ id }) => id)),
});

export const createRecentJournal = (id: string) => ({
  type: CREATE_RECENT_JOURNAL,
  asyncCall: () => postRecentJournal(id),
  transformResult: (items: { id: string }[]) => items.map((({ id }) => id)),
});

export const getRecentJournals = (state: any): string[] => state.profile.recentJournals.items;
export const getRecentJournalsIsLoading = (state: any) => state.profile.recentJournals.isLoading;
