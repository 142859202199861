import React from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { debounceValidateEmail, debounceValidatePassword } from 'src/app/utils/validation';
import SvgLogo from 'src/app/components/common/SvgLogo';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { ITheme } from 'src/app/App';
import { getErrorMessage } from './redux/AuthDucks';

export interface SubmitProps {
    email: string,
    password: string,
}

interface LoginProps {
    loading?: boolean,
    handleLogin: (props: SubmitProps) => Promise<void>,
}

const useStyles = makeStyles((theme: ITheme) => ({
  wrapper: {
    backgroundColor: theme.palette.typical.white,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: '3rem',
  },
  logo: {
    width: '170px',
    marginBottom: '25px',
  },
}));

const Login: React.FC<LoginProps> = ({ loading, handleLogin }) => {
  const classes = useStyles();

  const errorMessage = useSelector(getErrorMessage);
  if (errorMessage) {
    toastr.error('Failed', errorMessage);
  }

  return (
    <div className={classes.wrapper}>
      <Container maxWidth="xs">
        <SvgLogo />
        <Form
          onSubmit={handleLogin}
          render={({ handleSubmit, submitting, hasValidationErrors }) => (
            <form onSubmit={handleSubmit}>
              <Field name="email" validate={debounceValidateEmail}>
                {({ input, meta }) => (
                  <div>
                    <TextField
                      error={meta.error && meta.modified}
                      helperText={meta.error && meta.modified ? meta.error : ''}
                      autoFocus
                      required
                      fullWidth
                      name="email"
                      label="Email"
                      margin="normal"
                      variant="outlined"
                      disabled={loading}
                      autoComplete="email"
                      inputProps={{ 'data-seleniumid': 'login-email-input' }}
                      {...input}
                    />
                  </div>
                )}
              </Field>
              <Field name="password" validate={debounceValidatePassword}>
                {({ input, meta }) => (
                  <div>
                    <TextField
                      error={meta.error && meta.modified}
                      helperText={meta.error && meta.modified ? meta.error : ''}
                      required
                      fullWidth
                      name="password"
                      type="password"
                      label="Password"
                      margin="normal"
                      variant="outlined"
                      disabled={loading}
                      autoComplete="current-password"
                      inputProps={{ 'data-seleniumid': 'login-password-input' }}
                      {...input}
                    />
                  </div>
                )}
              </Field>
              <Button
                disableElevation
                fullWidth
                type="submit"
                color="primary"
                disabled={hasValidationErrors || loading}
                size="large"
                variant="contained"
                className={classes.submit}
                data-seleniumid="login-button"
              >
                {
                  loading
                    ? <CircularProgress size={25} color="secondary" />
                    : 'Log in'
                }
              </Button>
            </form>
          )}
        />
      </Container>
    </div>
  );
};

export default Login;
