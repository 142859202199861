export default {
  authenticate: '/authenticate',
  logout: '/logout',
  journals: '/Journals',
  recentJournals: '/Profile/recentJournals',
  virtualIssues: '/VirtualIssues',
  virtualIssue: '/VirtualIssues/:id',
  virtualIssuesPublish: '/VirtualIssues/:id/publish',
  users: '/Users',
  user: '/Users/:id',
  societies: '/Societies',
  articles: '/ArticleProducts',
  TOC: '/TableOfContents/:id',
  validateDOI: 'https://onlinelibrary.wiley.com/doi/toc/',
  images: '/Images',
  image: '/Images/:id',
  features: '/FeatureFlags',
};
