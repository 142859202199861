import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[400],
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    maxWidth: theme.breakpoints.values.xl,
    textAlign: 'right',
  },
  splitter: {
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  return (
    <footer className={classes.root}>
      <div className={classes.content}>
        © Wiley, 2014–{currentYear}
        <span className={classes.splitter}>|</span>
        VIRTUAL ISSUE BUILDER
      </div>
    </footer>
  );
};

export default Footer;
