import React, { memo, useCallback, useMemo } from 'react';
import { Journal } from 'src/mock/db';
import TextField from '@material-ui/core/TextField';
import Tooltip from 'src/app/components/common/Tooltip';
import Autocomplete, { RenderGroupParams } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { ListSubheader, Typography } from '@material-ui/core';

export interface IJournalSelect {
  recentJournals: Journal[],
  allJournals: Journal[],
  isLoading: boolean,
  isAllJournalsAvailable: boolean,
  selectedJournal: string | null,
  onSelectJournal(id: string | null, recentJournals?: string[]): void,
  loadSocieties(): void,
  isRequired?: boolean;
  error?: boolean;
  className?: string;
  disableClearable?: boolean,
  disabled?: boolean,
  seleniumId: string,
}

const LOADING = 'Loading...';
const PLACEHOLDER = 'Select journal';

const useStyles = makeStyles({
  root: {
    width: '24rem',
  },
  listbox: {
    padding: '0',
  },
  option: {
    padding: '0.5rem 1rem',
  },
});

const useAutocompleteStyles = makeStyles({
  listSubheader: {
    textAlign: 'center',
    lineHeight: '1.5rem',
    position: 'relative',
  },
});

const filterOptions = (options: Journal[], inputValue: string, isAllJournalsAvailable: boolean) => {
  if (!inputValue) {
    return isAllJournalsAvailable ? options : options.filter(({ isRecent }) => isRecent);
  }

  const lowerValue = inputValue.toLowerCase();
  return options.filter((journal: Journal) => journal.id.toLowerCase().includes(lowerValue)
  || (journal.name || '').toLowerCase().includes(lowerValue)
  || (journal.productCode || '').toLowerCase().includes(lowerValue));
};

const JournalSelect = (props: IJournalSelect) => {
  const {
    allJournals,
    className = '',
    disableClearable = false,
    disabled = false,
    error = false,
    isAllJournalsAvailable,
    isLoading,
    isRequired,
    onSelectJournal,
    recentJournals,
    selectedJournal,
    seleniumId,
  } = props;

  const recentJournalsIds = useMemo(() => recentJournals.map(({ id }) => id), [recentJournals]);
  const onChange = useCallback((_: any, value: Journal | null) => {
    onSelectJournal(value ? value.id : null, recentJournalsIds);
  }, [onSelectJournal, recentJournalsIds]);

  const classes = useStyles();
  const autocompleteStyles = useAutocompleteStyles();

  const options = [
    ...(recentJournals).map((journal) => ({ ...journal, isRecent: true })),
    ...allJournals,
  ];
  const value = options.find((journal: Journal) => journal.id === selectedJournal);
  const isJournalNotFound = !!selectedJournal && !value;

  const label = isJournalNotFound
    ? selectedJournal
    : PLACEHOLDER;

  const renderGroup = (params: RenderGroupParams) => [
    <ListSubheader className={autocompleteStyles.listSubheader} key={params.key} component="div">
      {recentJournals.length ? params.group : ''}
    </ListSubheader>,
    params.children,
  ];
  return (
    <Autocomplete
      disableClearable={disableClearable}
      data-seleniumid={`${seleniumId}-select-journal`}
      value={value || null}
      getOptionSelected={(first:Journal, second:Journal) => first.id === second.id}
      getOptionLabel={option => option.name}
      onChange={onChange}
      disabled={isLoading || disabled}
      className={className}
      classes={classes}
      groupBy={({ isRecent }) => (isRecent ? 'Last Used' : 'All Journals')}
      renderGroup={renderGroup}
      options={options}
      filterOptions={(options: Journal[], { inputValue }:{ inputValue: string }) => filterOptions(options, inputValue, isAllJournalsAvailable)}
      renderOption={(option) => (
        <Tooltip title={option.id} placement="right-start">
          <Typography data-seleniumid={`${seleniumId}-select-journal-${option.id}`} noWrap>{option.name}</Typography>
        </Tooltip>
      )}
      renderInput={
        (params: any) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, 'data-seleniumid': `${seleniumId}-select-journal-input` }}
            label={isLoading ? LOADING : label}
            error={error}
            variant="outlined"
            required={isRequired}
          />
        )}
    />
  );
};

export default memo(JournalSelect);
