import React, { useMemo, useEffect, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { User, UserNonLogged } from 'src/mock/db';
import _ from 'lodash';
import Table from 'src/app/components/Table';
import { ITableColumn } from 'src/app/components/Table/Interfaces';
import {
  loadUsers,
  getUsers,
  getUserListOptions,
  updateListOptions,
  UsersState,
  getUsersTotalCount,
} from 'src/app/pages/Users/redux/UsersDucks';
import useRequest from 'src/app/utils/hooks/useRequest';
import endpoints from 'src/app/redux/api/endpoints';
import { NO_RESULTS_SEARCH_MESSAGE } from 'src/app/constants';

interface IUserList {
  email?: string;
  tableConfig: ITableColumn[];
}

const UserList: FC<IUserList> = ({ email, tableConfig: columns }) => {
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const pageOptions = useSelector(getUserListOptions);
  const usersTotalCount = useSelector(getUsersTotalCount);
  const history = useHistory();

  const pageProps = {
    size: pageOptions.rowsPerPage,
    offset: pageOptions.currentPage * pageOptions.rowsPerPage,
  };

  const params = {
    ...pageProps,
    email,
  };
  if (!email) {
    delete params.email;
  }
  const requestInfo = useRequest({
    route: { path: endpoints.users, method: 'GET', params },
    exact: true,
  });

  useEffect(
    () => {
      if (email) {
        pageProps.offset = 0;
      }
      dispatch(loadUsers(email ? {
        email,
        ...pageProps,
      } : pageProps));
    },
    [email, pageOptions.rowsPerPage, pageOptions.currentPage],
  );

  const items = useMemo(
    () => {
      const list = email
        ? users.filter((user) => user.email === email)
        : users;
      return _.orderBy(
        list,
        (user) => (user[pageOptions.sortField] ? user[pageOptions.sortField].toLowerCase() : ''),
        pageOptions.sortDir,
      );
    },
    [email, users, pageOptions],
  );

  const onRowClick = (row: User & UserNonLogged) => {
    const search = row?.id || row?.participantId || row?.email;
    const view = row?.id ? 'edit' : 'create';
    history.push(`/users/${view}/${search}`);
  };

  const onOrderChange = (field: UsersState['sortField']) => {
    const direction = (pageOptions.sortDir === 'desc' || field !== pageOptions.sortField) ? 'asc' : 'desc';
    dispatch(updateListOptions({ sortField: field, sortDir: direction }));
  };

  const onChangePage = (page: number) =>
    dispatch(updateListOptions({ currentPage: page }));

  const onChangeRowsPerPage = (val: number) => {
    dispatch(updateListOptions({ rowsPerPage: val, currentPage: 0 }));
  };

  const tableProps = {
    dataType: 'users',
    isLoading: requestInfo?.status === 'fetching',
    items,
    columns,
    error: email && items.length === 0 ? NO_RESULTS_SEARCH_MESSAGE : requestInfo?.error,
    onRowClick,
    onOrderChange,
    rowsPerPage: pageOptions.rowsPerPage,
    sortField: pageOptions.sortField,
    sortDir: pageOptions.sortDir,
    currentPage: pageOptions.currentPage,
    onChangePage,
    onChangeRowsPerPage,
    showPagination: false,
    topPaginationVisibility: true,
    totalItemsCount: usersTotalCount,
  };

  return <Table {...tableProps} />;
};

export default UserList;
