import React from 'react';

interface TabPanelProps {
  children: any;
  index: number;
  value: number;
}

const IssueTab = ({ children, value, index }: TabPanelProps) => (
  <div
    className="hideHorizontalScroll"
    style={{
      display: value === index ? 'block' : 'none',
      overflow: 'auto',
      height: '100%',
    }}
  >
    {children}
  </div>
);

export default IssueTab;
