import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ITheme } from 'src/app/App';
import PaginationActions from 'src/app/components/Table/PaginationActions';
import { TabsID } from 'src/app/pages/VirtualIssues/components/IssuesManage/common/interfaces';
import { Article as ArticleType } from 'src/mock/db';
import ArticleCell from './Cells/ArticleCell';
import HeaderCell from './Cells/HeaderCell';
import Panel from './Panel';
import { ArticleValue, CellMeta, CellValue, ICell } from './interfaces';
import { createArticles, getArticleTemplate, getDuplicatedArticleID, getHeaderTemplate, getReorderedCells, getUsedArticlesDOIs } from './utils';

export type TOC = ICell[];

const useStyles = makeStyles((theme: ITheme) => ({
  content: {
    paddingBottom: '4rem',
  },
  draggable: {
    padding: theme.spacing(0.5, 0),
  },
}));

const usePaginationStyles = makeStyles((theme: ITheme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 0,
    marginRight: theme.spacing(0.5),
  },
}));

export interface ITOC {
  tabId: TabsID,
  onValidate(id:TabsID, flag:boolean): void,
  onUpdate(id:TabsID, data:TOC): void,
  data: TOC,
  isActive: boolean,
}

const TOC: FC<ITOC> = ({ tabId, onValidate, onUpdate, data, isActive }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const paginationClasses = usePaginationStyles();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const [cells, setCells] = useState<TOC>(data);
  const classes = useStyles();
  const topRef = useRef<HTMLDivElement>(null);
  const count = cells.length;

  useEffect(() => {
    if (data.length && !_.isEqual(cells, data)) {
      setCells(data);
    }
  }, [data.length]);

  useEffect(() => {
    const isCurrentValid = !cells.some((cell) => !!cell.meta.error || cell.meta.validating);
    onValidate(tabId, isCurrentValid);
    onUpdate(tabId, cells);
  }, [cells]);

  useLayoutEffect(() => {
    if (topRef.current?.scrollIntoView) {
      topRef.current.scrollIntoView();
    }
  }, [cells.length]);

  const onChange = useCallback((id:string, value:CellValue, meta:CellMeta) => {
    setCells(prevCells => prevCells.map((cell) => (cell.id !== id ? cell : { ...cell, value, meta: { ...cell.meta, ...meta } })));
  }, [tabId]);

  const onRemove = useCallback((id: string) => {
    setCells(prevCells => {
      let newCells = prevCells.filter((cell) => cell.id !== id);
      const duplicateID = getDuplicatedArticleID(id, prevCells);
      if (duplicateID) {
        newCells = newCells.map((cell) => (cell.id !== duplicateID ? cell : { ...cell, meta: { ...cell.meta, error: null, forceValidate: true } }));
      }
      if (!newCells.length) newCells = [getHeaderTemplate(true)];

      return newCells;
    });
  }, [tabId]);

  const onCreateArticles = useCallback((id:string, dois:string[], articles:ArticleType[]) => {
    // @ts-ignore
    setCells(prevCells => prevCells.reduce((acc: TOC, cell) => acc.concat(cell.id !== id ? cell : createArticles(dois.map((doi) => ({ doi })), articles)), []));
  }, [tabId]);

  const onDragEnd = useCallback((result:any, actualPage: number) => {
    const baseInsertionIndex = actualPage === 0 ? 0 : actualPage * rowsPerPage;
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    setCells(prevCells => getReorderedCells(prevCells, result.source.index + baseInsertionIndex, result.destination.index + baseInsertionIndex));
  }, [tabId]);

  const addHeader = useCallback(() => {
    setCells(prevCells => [getHeaderTemplate(), ...prevCells]);
  }, [tabId]);

  const addArticle = useCallback(() => {
    setCells(prevCells => {
      const baseInsertionIndex = page * rowsPerPage;
      const insertionIndex = page === 0 ? baseInsertionIndex + 1 : baseInsertionIndex;
      const newCells = [...prevCells];

      const newArticle = getArticleTemplate();

      newCells.splice(insertionIndex, 0, newArticle);

      return newCells;
    });
  }, [page, rowsPerPage]);


  const usedDOIs = getUsedArticlesDOIs(cells);


  if (!isActive) return null;

  const handleChangeRowsPerPage = (event: any) => {
    const value = parseInt(event.target.value, 10);
    setRowsPerPage(value === -1 ? cells.length : value);
    setPage(0);
  };

  const isTheOnlyHeader = !!(cells?.filter(({ type }) => type === 'header')?.length === 1);

  const rowsPerPageOptions = [5, 10, 25, { label: 'All', value: -1 }];

  const tablePaginationRef = undefined;

  const filteredCells = () => cells.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


  return (
    <>
      <div className={classes.content}>
        <div ref={topRef} />
        <DragDropContext onDragEnd={(e) => onDragEnd(e, page)}>
          <Droppable droppableId="list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {filteredCells().map(({ type, value, ...cell }, i:number) => {
                  const props = { ...cell, type, onChange, onRemove, index: i };
                  return (
                    <Draggable
                      key={cell.id} draggableId={cell.id} index={i}
                    >
                      {(providedCell, snapshot) => (
                        <div
                          className={classes.draggable}
                          ref={providedCell.innerRef}
                          {...providedCell.draggableProps}
                          {...providedCell.dragHandleProps}
                        >
                          {
                            type === 'header'
                              ? (
                                <HeaderCell
                                  {...props}
                                  value={value}
                                  isTheOnlyHeader={isTheOnlyHeader}
                                  isDragging={snapshot.isDragging}
                                />
                              )
                              : (
                                <ArticleCell
                                  {...props}
                                  value={value as ArticleValue}
                                  usedDOIs={usedDOIs}
                                  onCreateArticles={onCreateArticles}
                                  isDragging={snapshot.isDragging}
                                />
                              )
                          }
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
                <TablePagination
                  classes={paginationClasses}
                  ActionsComponent={PaginationActions}
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ref={tablePaginationRef}
                />
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Panel
        onAddArticle={addArticle}
        onAddHeader={addHeader}
      />
    </>
  );
};

export default memo(TOC);
