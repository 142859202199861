import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { ITableCell } from 'src/app/components/Table/Interfaces';
import { NO_ACTION_MESSAGE } from 'src/app/constants';
import { getJournalsIsLoading, getJournal } from 'src/app/redux/reducers/journals';
import { ITheme } from 'src/app/App';

interface IJournalCell extends ITableCell {
  isChip?: boolean,
  className?: string,
  row?: any
}

const useStyle = makeStyles((theme: ITheme) => ({
  tooltip: {
    backgroundColor: theme.palette.typical.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: '1rem',
  },
  popper: {
    cursor: 'pointer',
  },
}));

const JournalCell = (props: IJournalCell) => {
  const {
    value: { id },
    isChip,
    className,
  } = props;
  const journal = useSelector(getJournal(id));
  const journalIsLoading = useSelector(getJournalsIsLoading);

  const classes = useStyle();

  let title;
  switch (true) {
    case journalIsLoading: {
      title = 'Loading...';
      break;
    }
    case !!journal: {
      title = journal?.name;
      break;
    }
    default:
      title = NO_ACTION_MESSAGE('load title');
  }

  const seleniumId = `${id}-journal`;
  const label = journal?.productCode || id;
  return (
    <Tooltip classes={classes} title={title} placement="right">
      {
        isChip
          ? <Chip label={label} data-seleniumid={seleniumId} className={className} />
          : <span data-seleniumid={seleniumId}>{label}</span>
      }
    </Tooltip>
  );
};

export default memo(JournalCell);
