import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import { FieldMeta } from '../../interfaces';

const useStyles = makeStyles(theme => ({
  errorIcon: {
    color: theme.palette.error.main,
  },
  doneIcon: {
    color: theme.palette.primary.main,
  },
}));

const DOIIcon = memo(({ meta }: { meta: FieldMeta }) => {
  const { errorIcon, doneIcon } = useStyles();
  if (meta?.error) {
    return <ErrorIcon className={errorIcon} />;
  } if (meta?.validating) {
    return <CircularProgress size={20} />;
  } if (meta?.validated) {
    return <DoneIcon className={doneIcon} />;
  }

  return <span />;
});

export default DOIIcon;
