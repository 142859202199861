import React, { FC, useState, memo } from 'react';
import Dropzone from 'react-dropzone';
import { toastr } from 'react-redux-toastr';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloudUpload as CloudUploadIcon, Cancel as CancelIcon } from '@material-ui/icons';
import { ITheme } from 'src/app/App';
import { getImage } from 'src/app/redux/api';
import { TabsID } from '../common/interfaces';


const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/png'];
const IMAGE_SIDE_SIZE = { width: 1200, height: 1200 };
const FILE_SIZE = 5242880;
export const MESSAGES = {
  bigFileSize: 'File size cannot be more then 5 Mb',
  bigImageSides: `Maximum image height ${IMAGE_SIDE_SIZE.height}px and width ${IMAGE_SIDE_SIZE.width}px`,
  moreThenOne: 'Choose one file please',
  fileFormat: 'Correct file format is .png or .jpg',
};

const useStyles = makeStyles((theme: ITheme) => ({
  'drop-block-wrapper': {
    height: `calc(100% - ${theme.spacing(4)}px)`,
  },
  'drop-block': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey['50'],
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: `1px dashed ${theme.palette.primary.main}`,
    color: theme.palette.secondary.main,
    transition: '0.2s',
    userSelect: 'none',
    '& h2': {
      marginBottom: theme.spacing(1),
    },
  },
  'drop-block_active': {
    color: theme.palette.typical.accent,
    borderColor: theme.palette.typical.accent,
    backgroundColor: theme.palette.grey['100'],
  },
  'drop-block__icon': {
    width: '2.5rem',
    height: '2.5rem',
  },
  'drop-block__info': {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.grey['400'],
  },
  'image-block-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'auto',
  },
  'image-block': {
    backgroundColor: theme.palette.grey['100'],
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'inline-block',
    borderRadius: theme.shape.borderRadius,
    '& img': {
      display: 'block',
      border: `1px solid ${theme.palette.grey['300']}`,
      width: '20rem',
      margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
  },
  'image-block__head': {
    textAlign: 'right',
    padding: theme.spacing(1),
    '& button': {
      color: theme.palette.error.light,
    },
  },
  fontColor: {
    color: theme.palette.secondary.main,
  },
}));

const DropBlock = ({ isActive }: {isActive: boolean}) => {
  const styles = useStyles();
  return (
    <div className={`${styles['drop-block']} ${isActive ? styles['drop-block_active'] : ''}`}>
      <CloudUploadIcon className={`${styles['drop-block__icon']} ${styles.fontColor}`} />
      <h2 className={styles.fontColor}>Drop a file to upload</h2>
      <span className={styles.fontColor}>or click here to choose one</span>
      <div className={styles['drop-block__info']}>
        Please upload .png or .jpg files 5 Mb max.<br /> {MESSAGES.bigImageSides}
      </div>
    </div>
  );
};

const ImageBlock = ({ file, onClickRemove }: { file: any, onClickRemove: () => void }) => {
  const styles = useStyles();
  const { id, preview } = file;
  const src = id ? getImage(id) : preview;

  return (
    <div className={styles['image-block-wrapper']}>
      <div className={styles['image-block']}>
        <div className={styles['image-block__head']}>
          <IconButton aria-label="remove cover" size="small" onClick={onClickRemove} data-seleniumid="remove-cover-btn">
            <CancelIcon />
          </IconButton>
        </div>
        <img src={src} alt="Cover preview" data-seleniumid="img-cover" />
      </div>
    </div>
  );
};

export type Cover = {
  id?: string | null,
  preview?:string,
  [key: string]: any;
}

export interface ICoverTab {
  tabId: TabsID,
  cover?: Cover;
  setCover: (id: TabsID, url?: Cover) => void;
}

const CoverTab: FC<ICoverTab> = ({ tabId, cover, setCover }) => {
  const [isZoneActive, setIsZoneActive] = useState(false);
  const styles = useStyles();

  const onDropHandler = (files: any[]) => {
    if (files.length < 1) {
      return;
    }
    if (files[0].size > FILE_SIZE) {
      toastr.warning('Incorrect file', MESSAGES.bigFileSize);
      return;
    }
    const blob = URL.createObjectURL(files[0]);
    const img = new Image();

    img.src = blob;
    img.onload = () => {
      if (img.height > IMAGE_SIDE_SIZE.height || img.width > IMAGE_SIDE_SIZE.width) {
        toastr.warning('Incorrect file', MESSAGES.bigImageSides);
        return;
      }
      setCover(tabId, Object.assign(files[0], { preview: blob }));
    };
  };

  return (
    cover
      ? (
        <ImageBlock file={cover} onClickRemove={() => setCover(tabId)} />
      )
      : (
        <Dropzone
          onDrop={onDropHandler}
          onDragEnter={() => setIsZoneActive(true)}
          onDragLeave={() => setIsZoneActive(false)}
          onDropRejected={(files) => {
            setIsZoneActive(false);
            if (files.length > 1) {
              toastr.warning('Incorrect file', MESSAGES.moreThenOne);
            }
            if (files.length === 1) {
              toastr.warning('Incorrect file', MESSAGES.fileFormat);
            }
          }}
          accept={ACCEPTED_FILE_TYPES}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={styles['drop-block-wrapper']}>
              <input {...getInputProps()} data-seleniumid="cover-input" />
              <DropBlock isActive={isZoneActive} />
            </div>
          )}
        </Dropzone>
      )
  );
};

export default memo(CoverTab);
