import { SUCCESS } from 'src/app/redux/asyncMiddleware';
import { VirtualIssue } from 'src/mock/db';
import { RootState } from 'src/app/redux/store';

export const initialState = {};

export const LOAD_DETAILED_ISSUE = 'LOAD_DETAILED_ISSUE';

export default (state = initialState, action: any) => {
  const { type, payload } = action;

  if (type === LOAD_DETAILED_ISSUE + SUCCESS) {
    return { ...state, [payload.id]: payload };
  }

  return state;
};

export const loadDetailedIssue = (id:string) => ({
  type: LOAD_DETAILED_ISSUE,
  payload: id,
});

export const updateDetailedIssues = (data: VirtualIssue) => ({
  type: LOAD_DETAILED_ISSUE + SUCCESS,
  payload: data,
});

export const getDetailedIssue = (id:string | null) => (state:RootState): VirtualIssue |null => {
  if (!id) return null;
  return state.issueManage.Issues[id] || null;
};
