import { RootState } from 'src/app/redux/store';
import { User } from 'src/mock/db';
import { isAuthorized } from 'src/app/services/auth';
import ls from 'src/app/services/localStorage';
import { TOKEN_KEY, USER_KEY } from 'src/app/constants';

interface Auth {
  isAuthorizing: boolean,
  token?: string,
  user: any,
  [key: string]: any,
}

export interface Payload {
  user?: string,
  token?: string,
  payload?: any,
  redirectUrl?: string,
}

export const initialState: Auth = {
  isAuthorizing: false,
  token: ls.getJSON(TOKEN_KEY) || '',
  user: ls.getJSON(USER_KEY) || null,
  returnUrl: null,
};

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export default (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        token: '',
        errorMessage: '',
        isAuthorizing: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: { ...payload.user },
        token: payload.token,
        isAuthorizing: false,
        returnUrl: '',
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        errorMessage: payload?.message || '',
        isAuthorizing: false,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        token: '',
        returnUrl: (payload?.returnUrl && payload.returnUrl !== '/login') ? payload.returnUrl : state.returnUrl,
      };
    case LOGOUT_SUCCESS:
    case LOGOUT_FAILURE:
      return {
        ...state,
        returnUrl: payload?.returnUrl || '',
      };
    default:
      return state;
  }
};

export const login = (creds: any) => ({ type: LOGIN_REQUEST, payload: creds });
export const loginSuccess = ({ user, token }: any) => ({ type: LOGIN_SUCCESS, payload: { user, token } });
export const loginFailure = (message:string) => ({ type: LOGIN_FAILURE, payload: { message } });

export const logout = (returnUrl = '/login') => ({ type: LOGOUT_REQUEST, payload: { returnUrl } });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutFailure = () => ({ type: LOGOUT_FAILURE });

export const getUser = (state: RootState): User => state.auth.user || {};
export const getIsAuthorizing = (state: any) => state.auth.isAuthorizing;
export const getErrorMessage = (state: any) => state.auth.errorMessage;
export const getReturnURL = (state: any) => state.auth.returnUrl;
const getToken = (state: RootState): string => state.auth.token;

export const getIsAuthorized = (state: RootState) => {
  const user = getUser(state);
  const token = getToken(state);
  return isAuthorized(token) && user.id;
};
