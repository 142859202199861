import React, { FC, memo } from 'react';
import _ from 'lodash';
import { components } from 'src/mock/generated-schema';
import { makeStyles } from '@material-ui/core/styles';
import { DEFAULT_DATE_TIME_FORMAT, stringDateToDateModifierCustomFormat } from 'src/app/utils/date';
import { ITheme } from 'src/app/App';

export interface IErrorsTab {
  events: components['schemas']['WorkflowEvent'][] | undefined;
  isActive: boolean;
}

const deliveryFailureMessageStyles = makeStyles((theme: ITheme) => ({
  time: {
    fontWeight: 'bold',
  },
  message: {
    marginBottom: theme.spacing(3),
  },
  uri: {
    color: theme.palette.grey[400],
  },
}));

interface IDeliveryFailureMessage {
  messageText: string;
  messageUri: string;
  endedAtTime : string;
  endedAtTimeInt?: number;
}

const DeliveryFailureMessage : React.ReactNode = ({ messageText, messageUri, endedAtTime }: IDeliveryFailureMessage, key : number) => {
  const classes = deliveryFailureMessageStyles();

  return (
    <div className={classes.message} key={key}>
      <div>
        <span data-seleniumid="delivery-errors-content-time" className={classes.time}>{endedAtTime}</span>
        <span data-seleniumid="delivery-errors-content-uri" className={classes.uri}> / {messageUri}</span>
      </div>
      <div data-seleniumid="delivery-errors-content-message">{messageText}</div>
    </div>
  );
};

const getErrorMessagesFromEventsDataField = (events : components['schemas']['WorkflowEvent'][]) => {
  let messages : IDeliveryFailureMessage[] = [];

  if (events?.length) {
    events.forEach((event: any) => {
      if (event?.data?.length) {
        try {
          const internalEvent = JSON.parse(event.data);
          internalEvent.messages
            .filter(({ messageLevel } : {messageLevel : string}) => messageLevel === 'fatal')
            .forEach(({ messageText, messageUri }: {messageText : string, messageUri : string}) =>
              messages.push({ messageText,
                messageUri,
                endedAtTimeInt: new Date(internalEvent.endedAtTime).getTime(),
                endedAtTime: stringDateToDateModifierCustomFormat(
                  internalEvent.endedAtTime,
                  true,
                  DEFAULT_DATE_TIME_FORMAT),
              }));
        }
        catch (e) {
          // eslint-disable-next-line no-console
          console.log('getErrorMessagesFromEventsDataField', { event, e });
        }
      }
    });
  }

  messages = _.orderBy(messages, ['endedAtTimeInt'], ['desc']);

  // @ts-ignore
  return <>{messages.length ? messages.map(DeliveryFailureMessage) : ''}</>;
};

const NO_ERRORS_FOUND_MESSAGE = 'No errors found';
const ErrorsTab: FC<IErrorsTab> = ({ isActive, events }) => (
  isActive
    ? <div data-seleniumid="delivery-errors-content">{events?.length ? getErrorMessagesFromEventsDataField(events) : NO_ERRORS_FOUND_MESSAGE}</div>
    : <div />
);

export default memo(ErrorsTab);
