import React, { useState } from 'react';
import { Route } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { validateEmailAllowEmpty } from 'src/app/utils/validation';
import SearchInput from 'src/app/components/SearchInput';
import UsersList from './components/UsersList';
import { usersTableConfig } from './components/UsersList/usersTableConfig';
import ManageUserModal from './components/ManageUser';

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: `calc(100% - ${theme.spacing(3)}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  panel: {
    margin: theme.spacing(3),
  },
}),
);

export type FormType = 'create' | 'edit';

const Index = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');

  return (
    <div className={classes.wrapper}>
      <div className={classes.panel}>
        <SearchInput
          seleniumId="users-search"
          validate={validateEmailAllowEmpty}
          placeholder="Search By Email"
          value={email}
          isAfterSearch={!!email}
          onSearch={(searchValue: string) => {
            setEmail(searchValue);
          }}
        />
      </div>
      <UsersList
        email={email}
        tableConfig={usersTableConfig}
      />
      {
        ['create', 'edit'].map((routeType) => (
          <Route
            key={routeType}
            path={`/users/${routeType}/:search`}
            render={() => <ManageUserModal />}
          />
        ))
      }
    </div>
  );
};

export default Index;
