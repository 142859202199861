import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

export const DEFAULT_DATE_FORMAT = 'd MMM yyyy';
export const DEFAULT_DATE_TIME_FORMAT = 'd MMM yyyy HH:mm:ss';

function resetISODateTZOffset(date: string) {
  const reg = /^(\d+-\d+-\d+T\d+:\d+:\d+\.*\d*)([-+][\d:]+)$/;
  let replaced = date;
  try {
    replaced = date.replace(reg, (p1, p2) => `${p2}+0000`);
  }
  catch (e) {
    // eslint-disable-next-line
    console.log('removeTZOffset', e);
  }
  return replaced;
}

export function stringDateToDateModifierCustomFormat(val = '', ignoreTzOffset = true, dateFormat = DEFAULT_DATE_FORMAT) {
  if (!val) {
    return '';
  }
  // note: we use 'parse' instead of 'new Date', because IE can't parse current format
  let date = parseISO(val);

  // we should use date with same TZ as was sent by the BE (no conversion to browser TZ)
  if (ignoreTzOffset) {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = parseISO(resetISODateTZOffset(val));
    date = new Date(date.getTime() + userTimezoneOffset);
  }
  return format(date, dateFormat);
}

export const stringDateToDateModifier = (val: string): string => stringDateToDateModifierCustomFormat(val);
