import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Journal } from 'src/mock/db';
import {
  getJournalsIsLoading,
  getJournals,
} from 'src/app/redux/reducers/journals';
import {
  getRecentJournals,
  getRecentJournalsIsLoading,
} from 'src/app/redux/reducers/profile/recentJournals';
import {
  loadSocieties,
  getSocietiesIsLoading,
} from 'src/app/pages/Societies/redux/SocietiesDucks';
import { getUser } from 'src/app/pages/Login/redux/AuthDucks';
import { USER_WILEY_TYPE } from 'src/app/constants';
import JournalSelect, { IJournalSelect } from './JournalSelect';

const getJournalsByIds = (journals: Journal[], ids: string[]) => (ids?.length
  ? _.compact(ids.map((id: string): Journal | null =>
    (journals.find((journal: Journal) => journal.id === id)) || null))
  : []);

export default (props: Omit<IJournalSelect,
    'loadSocieties' | 'recentJournals' | 'allJournals' | 'isLoading' | 'isAllJournalsAvailable' >) => {
  const dispatch = useDispatch();

  const { type } = useSelector(getUser);
  const journals = useSelector(getJournals);
  const recentJournalsIds = useSelector(getRecentJournals);
  const recentJournals = getJournalsByIds(journals, recentJournalsIds);

  const isAllJournalsAvailable = type !== USER_WILEY_TYPE;

  const recentJournalsIsLoading = useSelector(getRecentJournalsIsLoading);
  const journalsIsLoading = useSelector(getJournalsIsLoading);
  const societiesIsLoading = useSelector(getSocietiesIsLoading);
  const isLoading = recentJournalsIsLoading || journalsIsLoading || societiesIsLoading;

  return (
    <JournalSelect
      {...props}
      loadSocieties={() => dispatch(loadSocieties())}
      recentJournals={recentJournals}
      allJournals={journals}
      isLoading={isLoading}
      isAllJournalsAvailable={isAllJournalsAvailable}
    />
  );
};
