import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import cn from 'classnames';
import { ModalCloseButtonInterface } from './interfaces';

const useStyles = makeStyles(() => ({
  style: {
    justifyContent: 'flex-end',
  },
}));

export default forwardRef<HTMLButtonElement, ModalCloseButtonInterface>(({
  history,
  parentUrl,
  className,
}, ref) => {
  const classes = useStyles();

  return (
    <IconButton
      ref={ref}
      className={cn(classes.style, className)}
      onClick={() => history.push(parentUrl)}
      data-seleniumid="modal-close-button"
    >
      <CloseIcon />
    </IconButton>
  );
});
