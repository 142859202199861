import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useRouteMatch,
  generatePath,
  match as Match,
} from 'react-router';
import qs from 'query-string';

import { getQueryParams } from 'src/app/services/api';
import { getRequestInfo, removeRequestInfo } from 'src/app/redux/reducers/network';

interface Route {
  method?: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  path: Match['path'];
  params?: Match['params'];
}

interface Params {
  route?: Route;
  exact?: boolean;
  remove?: boolean;
}

const useRequest = (hookParams: Params = {}) => {
  const { route, exact = false, remove = false } = hookParams;

  const dispatch = useDispatch();
  const routeMatch = useRouteMatch();

  const { path, params } = route || routeMatch;
  const method = `${route?.method || 'GET'}`;
  let url = `${method}${generatePath(path, params)}`;

  if (method === 'GET') {
    const queryParams = getQueryParams(path, params);
    if (queryParams) url += `?${qs.stringify(queryParams, { encode: false })}`;
  }

  useEffect(() => () => {
    if (remove) dispatch(removeRequestInfo(url, exact));
  }, []);

  const requestInfo = useSelector(getRequestInfo(url, exact));
  return requestInfo;
};

export default useRequest;
