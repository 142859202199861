import React, { useEffect, useState, useMemo, FC } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Table from 'src/app/components/Table';
import { SortDirection } from 'src/app/components/Table/Interfaces';
import { loadJournals } from 'src/app/redux/reducers/journals';
import paths from 'src/app/router/paths';
import { ISociety } from 'src/mock/db';
import {
  loadSocieties,
  getSocieties,
  getSocietiesError,
  getSocietiesIsLoading,
} from './redux/SocietiesDucks';
import tableConfig from './societiesTableConfig';
import SocietyInfo from './components/SocietyInfo';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    height: '100%',
  },
  headerCell: {
    '&:first-of-type': {
      borderTopLeftRadius: theme.shape.borderRadius,
    },
    '&:last-of-type': {
      borderTopRightRadius: theme.shape.borderRadius,
    },
  },
}));

const Societies: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const societies = useSelector(getSocieties);
  const isLoading = useSelector(getSocietiesIsLoading);

  const error = useSelector(getSocietiesError);

  const [sortDir, setSortDir] = useState<SortDirection>('asc');
  const [sortField, setSortField] = useState<keyof Pick<ISociety, 'name'>>('name');

  useEffect(() => {
    dispatch(loadSocieties());
    dispatch(loadJournals());
  }, []);

  useEffect(() => setSortDir('asc'), [sortField]);

  const items = useMemo(
    () => _.orderBy(
      societies,
      [item => (item[sortField] ? item[sortField].toString().toLowerCase() : '')],
      sortDir,
    ),
    [societies, sortDir],
  );

  const onOrderChange = (field: typeof sortField) => {
    setSortField(field);
    setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
  };

  return (
    <div className={classes.wrapper}>
      <Table
        showPagination={false}
        topPaginationVisibility
        autoPage
        error={error}
        totalItemsCount={societies.length}
        columns={tableConfig}
        items={items}
        isLoading={isLoading}
        sortField={sortField}
        onOrderChange={onOrderChange}
        sortDir={sortDir}
        classes={{ headerCell: classes.headerCell }}
      />
      <Route
        path={paths.society}
        component={SocietyInfo}
      />
    </div>
  );
};

export default Societies;
