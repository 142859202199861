import { ACTIONS_KEY } from 'src/app/components/Table';
import UserStatusCell from '../UserStatusCell';
import ActionCell from '../ActionCell';
import UserRoleCell from '../UserRoleCell';

export const usersTableConfig = [
  { field: 'firstName', title: 'First Name', width: 20, sortable: true },
  { field: 'lastName', title: 'Last Name', width: 20, sortable: true },
  { field: 'email', title: 'Email', width: 20, sortable: true },
  { field: 'role', title: 'User Role', render: UserRoleCell, width: 20, sortable: true },
  { field: 'state', title: 'Active', render: UserStatusCell, width: 10, sortable: true },
  { field: ACTIONS_KEY, title: 'Actions', render: ActionCell, width: 10 },
];
