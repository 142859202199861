import { CircularProgress, InputAdornment, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CancelTokenSource } from 'axios';
import _ from 'lodash';
import debounce from 'p-debounce';
import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ITheme } from 'src/app/App';
import { DOI_VALIDATION_DELAY, NO_ACTION_MESSAGE } from 'src/app/constants';
import { getArticles } from 'src/app/redux/api';
import { saveArticles } from 'src/app/redux/reducers/articles';
import { Article as ArticleType } from 'src/mock/db';
import { DUPLICATE_ARTICLE_DOI_ERROR, EMPTY_DOI } from '../constants';
import { ArticleValue, CellMeta } from '../interfaces';
import CellWrap from './CellWrap';

const useStyles = makeStyles((theme: ITheme) => ({
  label: {
    flex: 1,
    padding: theme.spacing(1),
  },
  input: {
    backgroundColor: theme.palette.typical.white,
    width: '15rem',
  },
}));

export interface IArticleCell {
  id: string;
  usedDOIs: string[],
  index: number,
  meta: CellMeta;
  value: ArticleValue;
  isDragging: boolean,
  onChange(id:string, value:ArticleValue, meta:CellMeta): void;
  onRemove(id:string): void;
  onCreateArticles(id:string, dois:string[], articles:ArticleType[]):void
}

const ArticleCell: FC<IArticleCell> = ({ id, meta, value, onChange, onCreateArticles, onRemove, usedDOIs, isDragging, index }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onRemoveHeader = useCallback(() => onRemove(id), [id]);
  const [currentValidatingValue, setCurrentValidatingValue] = useState('');
  const [cancellation, setCancellation] = useState<CancelTokenSource | null>(null);

  useEffect(() => {
    if (currentValidatingValue !== value.input && cancellation) {
      cancellation.cancel();
      setCancellation(null);
      onChange(id, value, { validating: false });
    }
  }, [currentValidatingValue, value]);

  const fetchArticles = async (doi:string[], newValue:string) => {
    try {
      setCurrentValidatingValue(newValue);
      const [call,,, cancel] = await getArticles([...doi]);
      setCancellation(cancel);
      const articles = await call;
      dispatch(saveArticles(articles));
      onCreateArticles(id, doi, articles || []);
    }
    catch (e) {
      if (e?.response) {
        const message = typeof e === 'string' ? e : NO_ACTION_MESSAGE('search articles. ');
        onChange(id, { label: value.label, input: newValue }, { validating: false, error: message });
      }
    }
  };

  const validate = (usedDOIs:string[], { label, input }:ArticleValue) => {
    const dois = _.uniq(_.compact(input.split(/\s+/)));
    const newValue = { label, input };
    if (!dois.length) {
      onChange(id, newValue, { error: EMPTY_DOI });
      return;
    }

    const isUsed = dois.some((doi:string) => usedDOIs.includes(doi));
    if (isUsed) {
      onChange(id, newValue, { error: DUPLICATE_ARTICLE_DOI_ERROR });
      return;
    }
    onChange(id, newValue, { validating: true });
    fetchArticles(dois, input);
  };

  const validateDebounce = useRef(debounce(validate, DOI_VALIDATION_DELAY)).current;
  const { label, input } = value;

  useEffect(() => {
    if (meta.forceValidate) {
      onChange(id, value, { forceValidate: false });
      validate(usedDOIs, value);
    }
  }, [meta.forceValidate]);

  const { error, validating } = meta;

  const seleniumId = `article-cell-${index}`;

  return (
    <CellWrap
      seleniumId={seleniumId} onRemove={onRemoveHeader} error={error}
      isDragging={isDragging}
    >
      <Typography variant="body2" data-seleniumid="article-title" className={classes.label}>
        {!error && label}
      </Typography>
      <TextField
        className={classes.input}
        error={!!error}
        value={input}
        onChange={e => {
          const val = (e.target.value || '').trim();
          if (val !== input) {
            onChange(id, { label: '', input: val }, { pristine: false, error: null });
            validateDebounce(usedDOIs, { label: '', input: val });
          }
        }}
        variant="outlined"
        fullWidth
        required
        multiline
        id="article"
        label="Article DOI"
        onFocus={() => onChange(id, { label, input }, { pristine: false })}
        onBlur={() => !input && validateDebounce(usedDOIs, { label, input })}
        data-seleniumid={seleniumId}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              { validating ? <CircularProgress size={20} /> : <></> }
            </InputAdornment>
          ),
        }}
      />
    </CellWrap>
  );
};

export default memo(ArticleCell);
