import { Action } from 'redux';
import _ from 'lodash';
import { RootState } from 'src/app/redux/store';
import { SUCCESS, FAILURE } from 'src/app/redux/asyncMiddleware';

type Status = 'fetching' | 'success' | 'failure';
interface RequestInfo { status: Status; error?: string }
export type Network = { [key: string]: RequestInfo } | null;

export const SET_NETWORK: 'SET_NETWORK' = 'SET_NETWORK';
export const REMOVE_NETWORK: 'REMOVE_NETWORK' = 'REMOVE_NETWORK';

interface NetworkAction extends Action<typeof SET_NETWORK | typeof REMOVE_NETWORK> {
  url: string;
  exact?: boolean;
  payload?: any;
}

export default (state: Network = null, action: NetworkAction) => {
  const { type, url, exact, payload } = action;

  if (url) {
    if ((type.includes(SUCCESS) || type.includes(FAILURE)) && !state?.[url]) {
      return state;
    }

    const status: Status = (type.includes(SUCCESS) && 'success')
    || (type.includes(FAILURE) && 'failure')
    || (type === SET_NETWORK && payload?.status) || 'fetching';

    let error;
    if (status === 'failure') error = payload?.error || payload;

    switch (type) {
      case REMOVE_NETWORK:
        /* eslint-disable no-case-declarations */
        let key: string | null = url;
        if (!exact) {
          key = Object.keys(state || {})
            .find((k) => k.includes(url)) || null;
        }

        return key ? _.omit(state, key) : state;
        /* eslint-enable */
      default: return {
        ...state,
        [url]: { status, error },
      };
    }
  }

  return state;
};

export const getRequestInfo = (
  url: string,
  exact?: boolean,
) => (
  state: RootState,
): RequestInfo | null => {
  let key: string | null = url;
  if (!exact) {
    key = Object.keys(state?.network || {})
      .find((k) => k.includes(url)) || null;
  }

  return (state?.network && key && key in state.network)
    ? state.network[key]
    : null;
};

export const removeRequestInfo = (url: string, exact?: boolean): NetworkAction => ({
  type: REMOVE_NETWORK,
  url,
  exact,
});
