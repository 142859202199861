export const REGEX_EMAIL_VALIDATION = /[^@]+@[^@]+/;
export const REGEX_HTML_MATCH = /<\/?[a-z][\s\S]*>/i;
export const REGEX_VALIDATION_DOI = /^10.\d{4,9}\/[-._;()/:A-Z0-9<>]+$/i;
export const REGEX_VALIDATION_VI_DOI = /^10.\d{4,9}\/[-._;()/:A-Z0-9]+\.[-_A-Za-z0-9]+$/i;
export const TOKEN_KEY = 'WPP_AUTH_TOKEN';
export const USER_KEY = 'WPP_USER';

export const NO_RESULTS_MESSAGE = 'No results found';
export const NO_RESULTS_SEARCH_MESSAGE = 'No results match your search';
export const NO_ACTION_MESSAGE = (action: string) => `Sorry, we couldn't ${action}`;
export const TRY_LATER = 'Please, try again later.';

export const ROLE_NAME_LIMIT = 20;

export const USER_WILEY_TYPE = 'Wiley';
export const USER_EXTERNAL_TYPE = 'External';

export const USER_ADMIN_ROLE = 'viAdministrator' as const;
export const USER_SOCIETY_EDITOR_ROLE = 'viSocietyEditor' as const;
export const USER_EDITOR_ROLE = 'viEditor' as const;

export const USERS_ROLE_TITLES = {
  [USER_ADMIN_ROLE]: 'Admin',
  [USER_SOCIETY_EDITOR_ROLE]: 'Society Editor',
  [USER_EDITOR_ROLE]: 'Editor',
} as const;

export const VALID_SEARCH_INPUT_LENGTH = 400;
export const VALIDATION_DELAY = 500;
export const VI_TITLE_MAX_LENGTH = 256;
export const DOI_VALIDATION_DELAY = 1500;
