import React from 'react';
import { IconButton } from '@material-ui/core';
import { OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import endpoints from 'src/app/redux/api/endpoints';

const WOLButton = ({ doi } : {doi: string}) => (
  <IconButton
    aria-label="WOL Link"
    title="WOL Link"
    onClick={(e) => {
      e.stopPropagation();
      window.open(endpoints.validateDOI + doi);
    }}
    data-seleniumid="wol-link"
  >
    <OpenInNewIcon />
  </IconButton>
);

export default WOLButton;
