import { FunctionComponent } from 'react';
import { RouteProps, RouteComponentProps } from 'react-router';
import NotFound from 'src/app/pages/NotFound';
import Index from 'src/app/pages/Login';
import VirtualIssues from 'src/app/pages/VirtualIssues';
import Societies from 'src/app/pages/Societies';
import Users from 'src/app/pages/Users';
import { USER_ADMIN_ROLE } from 'src/app/constants';
import { IPaperProps } from 'src/app/components/layout/PaperLayout';
import { UserRole } from 'src/mock/db/DBSchema';
import paths from './paths';

export interface IRouteProps extends RouteProps {
  isPrivate?: boolean;
  component: FunctionComponent<RouteComponentProps<{}>>;
  path: string;
  requiredRoles?: UserRole[],
  layoutProps?: IPaperProps
}

export const routes: { [key: string]: IRouteProps } = {
  LOGIN: {
    exact: true,
    path: '/login',
    component: Index,
  },
  VI: {
    isPrivate: true,
    path: paths.VI,
    component: VirtualIssues,
  },
  SOCIETIES: {
    isPrivate: true,
    path: paths.societies,
    component: Societies,
    requiredRoles: [USER_ADMIN_ROLE],
  },
  USERS: {
    isPrivate: true,
    path: '/users',
    component: Users,
    requiredRoles: [USER_ADMIN_ROLE],
  },
  NOT_FOUND: {
    path: '*',
    component: NotFound,
  },
};
