import React, { FC, useState, useEffect } from 'react';
import { Modal } from 'src/app/components/common/Modal';
import { useSelector } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import paths from 'src/app/router/paths';
import { IdObj } from 'src/mock/db';
import { History } from 'history';
import { useHistory, useParams } from 'react-router';
import { ITheme } from 'src/app/App';
import { getSociety, getSocietiesError, getSocietiesIsLoading } from '../redux/SocietiesDucks';
import SocietyJournals from './SocietyJournals';
import SocietyUsers from './SocietyUsers';

const useTabsClasses = makeStyles((theme: ITheme) => ({
  root: {
    color: theme.palette.typical.accent,
  },
  indicator: {
    backgroundColor: theme.palette.typical.accent,
  },
  tabContainer: {
    background: theme.palette.typical.white,
    position: 'sticky',
    top: 0,
    zIndex: 50,
  },
}));

const useModalStyles = makeStyles({
  modal: {
    alignItems: 'flex-start',
    height: '100%',
  },
  body: {
    padding: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
});

interface TabPanelProps {
  children: JSX.Element;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return value === index ? children : null;
};

const SocietyInfo: FC = () => {
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const history: History = useHistory();

  const tabsClasses = useTabsClasses();
  const modalClasses = useModalStyles();

  const { tabContainer, ...restTabsClasses } = tabsClasses;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const { name, journals = [], editors = [] } = useSelector(state => getSociety(state, id)) || {};
  const isLoading = useSelector(getSocietiesIsLoading);
  const error = useSelector(getSocietiesError);

  useEffect(() => {
    if (error) {
      history.push(paths.societies);
    }
  }, [error]);

  return (
    <Modal
      data-seleniumid="society-info-modal"
      classes={modalClasses}
      title={name}
      parentUrl={paths.societies}
      showFooter={false}
      isLoading={isLoading}
    >
      <Box className={tabContainer}>
        <Tabs
          value={value}
          onChange={handleChange}
          classes={restTabsClasses}
        >
          <Tab data-seleniumid="society-info-journals-tab" label="Journals" />
          <Tab data-seleniumid="society-info-users-tab" label="Society Editors" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <SocietyJournals
          ids={journals.map((journal: IdObj) => journal.id)}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SocietyUsers
          ids={editors?.map((editor: IdObj) => editor.id)}
        />
      </TabPanel>
    </Modal>
  );
};

export default SocietyInfo;
