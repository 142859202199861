import React, { FC, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'src/app/components/Table';
import { makeStyles } from '@material-ui/core/styles';
import UserStatusCell from 'src/app/pages/Users/components/UserStatusCell';
import { loadUsersById, getUsersById } from 'src/app/pages/Users/redux/UsersDucks';
import useRequest from 'src/app/utils/hooks/useRequest';
import { ITable, SortDirection } from 'src/app/components/Table/Interfaces';

interface ISocietyUsers {
  ids: string[],
}

export const tableConfig = [
  { field: 'firstName', title: 'First Name', sortable: true },
  { field: 'lastName', title: 'Last Name', sortable: true },
  { field: 'email', title: 'Email', sortable: true },
  { field: 'state', title: 'Active', render: UserStatusCell, sortable: true },
];

const EMPTY_MESSAGE = 'There are no editors';

const useTableStyles = makeStyles({
  table: {
    tableLayout: 'auto',
  },
});

const SocietyUsers:FC<ISocietyUsers> = ({ ids = [] }) => {
  const dispatch = useDispatch();
  const users = useSelector(getUsersById(ids));
  const requestInfo = useRequest({ remove: true });
  const [sortDir, setSortDir] = useState('asc' as SortDirection);
  const [sortField, setSortField] = useState('lastName');
  const notLoadedUsersIds = useMemo(
    () => _.difference(ids, users.map((user) => user.id)),
    [ids, users],
  );

  const items = useMemo(
    () => (_.orderBy(users, sortField, sortDir)),
    [users, sortDir],
  );

  useEffect(() => {
    if (notLoadedUsersIds?.length) {
      dispatch(loadUsersById(notLoadedUsersIds));
    }
  }, []);

  useEffect(() => setSortDir('asc'), [sortField]);

  const usersIsLoading = requestInfo?.status === 'fetching';

  const classes = useTableStyles();

  const onOrderChange = (field: string) => {
    setSortField(field);
    setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
  };

  const tableProps: ITable = {
    seleniumId: 'society-view-users',
    dataType: 'societies',
    classes,
    topPaginationDisplay: false,
    totalItemsCount: users.length,
    columns: tableConfig,
    items,
    isLoading: usersIsLoading,
    sortDir,
    onOrderChange,
    sortField,
  };

  if (!users.length) tableProps.error = EMPTY_MESSAGE;

  return <Table {...tableProps} />;
};

export default SocietyUsers;
