import { isValid } from 'date-fns';
import _ from 'lodash';
import { REGEX_VALIDATION_VI_DOI, VI_TITLE_MAX_LENGTH } from 'src/app/constants';
import { Description, FieldId } from './interfaces';

export const requiredField: FieldId[] = ['doi', 'journalId', 'firstPublished', 'title'];
const validateTitle = (title: string = '') => title.length <= VI_TITLE_MAX_LENGTH && title.length > 0;
const validateDescription = (value: string = '') => {
  const { length } = value.replace(/\s+/g, '');
  return length <= 50000;
};
const validateExist = (value: string | null) => !!value;
const validateDoi = (value: string = '') => REGEX_VALIDATION_VI_DOI.test(value);
const validateFirstPublish = (value: string = '') => value && isValid(new Date(value));
const validateLastUpdated = (value: string = '') => !value || isValid(new Date(value));

export const isRequiredFieldsFilled = (descriptionFields: Description) => !requiredField.some(field => !_.get(descriptionFields, field));

export const validationConfig = {
  title: validateTitle,
  doi: validateDoi,
  journalId: validateExist,
  firstPublished: validateFirstPublish,
  description: validateDescription,
  lastUpdated: validateLastUpdated,
  realLastUpdated: validateExist,
  publicationType: () => true,
};

export const isFieldsValid = (descriptionFields: Description) => Object.entries(descriptionFields)
  .reduce((acc, [key, value]) => ({ ...acc, [key]: !validationConfig[key as keyof Description](value) }), {});
