import { REGEX_EMAIL_VALIDATION } from 'src/app/constants';
import debounce from 'p-debounce';

const validDelay = 400;

export const validateEmail = (value: string) => {
  if (!value?.length) return 'Email cannot be empty';
  if (!value.match(REGEX_EMAIL_VALIDATION)) return 'Enter a valid email';
  return '';
};
export const validateEmailAllowEmpty = (value: string) => {
  if (!value?.length) return '';
  if (!value.match(REGEX_EMAIL_VALIDATION)) return 'Enter a valid email';
  return '';
};
export const validatePassword = <Boolean>(value: String) => {
  if (!value?.length) return 'Empty password';

  return false;
};

export const debounceValidateEmail = debounce(validateEmail, validDelay);
export const debounceValidatePassword = debounce(validatePassword, validDelay);
