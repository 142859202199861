import React, { useEffect, useCallback, memo, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { matchPath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'src/app/components/Table';
import { ITableColumn } from 'src/app/components/Table/Interfaces';
import {
  loadIssues,
  getIssues,
  getIssuesError,
  getIssuesIsLoading,
  getIssuesStats,
  getIssuesTotalCount,
  IssuesStore,
} from 'src/app/pages/VirtualIssues/components/IssuesList/redux/IssuesDucks';

import { NO_RESULTS_SEARCH_MESSAGE } from 'src/app/constants';
import paths from 'src/app/router/paths';
import useDidMountEffect from 'src/app/utils/hooks/useDidMount';

export type IssuesListFilter = {
  productState?: string,
  journalId?: string,
  name?: string,
  [key: string]: any;
}

interface IIssuesList {
  tableConfig: ITableColumn[],
  filter: IssuesListFilter,
  type: IssuesStore,
  searchResultTitle: string,
}

const useStyles = makeStyles(theme => ({
  wrap: {
    flex: 1,
    display: 'flex',
    height: '100%',
    paddingTop: theme.spacing(0.25),
  },
}));

const IssuesList = (props: IIssuesList) => {
  const { filter, tableConfig, type: issuesStore, type, searchResultTitle } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const matchPathProps = useMemo(() => matchPath(
    history.location.pathname,
    { path: type === 'liveIssues' ? paths.VI : paths.VIsNonLive },
  ), [history.location]);
  const classes = useStyles();
  const items = useSelector(state => getIssues(state, issuesStore));
  const totalItemsCount = useSelector(state => getIssuesTotalCount(state, issuesStore));
  const isLoading = useSelector(state => getIssuesIsLoading(state, issuesStore));
  const error = useSelector(state => getIssuesError(state, issuesStore));
  const stats = useSelector(state => getIssuesStats(state, issuesStore));

  const { sortDir, sortField, currentPage, rowsPerPage, rowsPerPageOptions } = stats;

  const ROWS_PER_PAGE_DEFAULT = rowsPerPageOptions[0];
  const ROWS_PER_PAGE_SEARCH = rowsPerPageOptions.slice(-1)[0];

  const fetchIssues = (isFilterRequest: boolean) => dispatch(loadIssues(
    issuesStore,
    filter,
    {
      ...stats,
      currentPage: isFilterRequest ? 0 : currentPage,
      rowsPerPage: filter.name && isFilterRequest
        ? ROWS_PER_PAGE_SEARCH
        : rowsPerPage || ROWS_PER_PAGE_DEFAULT,
    },
  ));
  useEffect(() => {
    fetchIssues(true);
  }, [filter]);

  useDidMountEffect(() => {
    if (!totalItemsCount || matchPathProps?.isExact) {
      fetchIssues(false);
    }
  }, [matchPathProps?.isExact]);

  const handleChangePage = useCallback((newPage: number) => {
    dispatch(loadIssues(issuesStore, filter, { ...stats, currentPage: newPage }));
  }, [stats]);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    dispatch(loadIssues(issuesStore, filter, { ...stats, currentPage: 0, rowsPerPage: newRowsPerPage }));
  }, [stats]);

  const { location: { pathname } } = history;

  return (
    <div className={classes.wrap}>
      <Table
        topPaginationVisibility
        showPagination={false}
        error={searchResultTitle && items.length === 0 ? NO_RESULTS_SEARCH_MESSAGE : error}
        sortDir={sortDir}
        sortField={sortField}
        currentPage={currentPage}
        totalItemsCount={totalItemsCount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        dataType={type}
        columns={tableConfig}
        items={items}
        isLoading={isLoading}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onRowClick={({ id }) => history.push(`${pathname}/manage/${id}`)}
        onOrderChange={() => {}}
        additionalTopText={{ seleniumId: 'search-results-label', value: searchResultTitle }}
      />
    </div>
  );
};

export default memo(IssuesList);
