import React, { FC, useEffect, SyntheticEvent, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal as MuiModal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import cn from 'classnames';
import { useHistory } from 'react-router';
import { History } from 'history';
import PaperLayout from 'src/app/components/layout/PaperLayout';
import InfoView from 'src/app/components/InfoView';
import { SpinnerOverlay } from 'src/app/components/common/SpinnerOverlay';
import { ITheme } from 'src/app/App';
import { ModalInterface, IFooterClasses } from './interfaces';
import ModalHeader, { IHeaderClasses } from './ModalHeader';
import ModalActions, { FOOTER_KEY } from './ModalActions';

interface IModalClasses extends IHeaderClasses, IFooterClasses{
  modal?: string,
  paper?: string,
  content?: string,
  body?: string,
}

export const BODY_KEY = 'modal-body-container';
export { FOOTER_KEY };

const useStyles = makeStyles(
  ({
    palette,
    shadows,
    spacing,
  }: ITheme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    paper: {
      display: 'flex',
      backgroundColor: palette.typical.white,
      boxShadow: shadows[5],
      overflow: 'hidden',
      height: 'inherit',
      minWidth: '60% !important',
      maxWidth: '70%',
      maxHeight: '85%',
      minHeight: '10rem',
    },
    content: {
      display: 'flex',
      width: '60vw',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'hidden',
    },
    body: {
      overflowY: 'auto',
      margin: spacing(1, 0, 0.2, 0),
      padding: spacing(0, 2),
    },
  }),
);

const getHeaderClasses = (classes: IModalClasses): IHeaderClasses => {
  const { header, title, closeButton } = classes;
  return { header, title, closeButton };
};

const getFooterClasses = (classes: IModalClasses): IFooterClasses => {
  const { footer, actions, buttonPrimary, buttonSecondary, cancelButton } = classes;
  return { footer, actions, buttonPrimary, buttonSecondary, cancelButton };
};

const Modal: FC<ModalInterface> = ({
  open = true,
  title = '',
  actions = [],
  parentUrl = window.location.pathname,
  onCloseEffect,
  onMountEffect,
  children = null,
  classes: overrideClasses = {},
  showFooter = true,
  isLoading = false,
  onBackdropClick,
  disableBackdropClick = false,
  error,
  HeaderButton,
  ...rest
}) => {
  const history: History = useHistory();

  useEffect(() => {
    if (onMountEffect) {
      onMountEffect();
    }
  }, []);

  const handleCloseModal = (event: SyntheticEvent<{}, Event>) => {
    event.stopPropagation();
    history.push(parentUrl);

    if (onCloseEffect) {
      onCloseEffect();
    }
  };

  const classes: IModalClasses = useStyles({ classes: overrideClasses });

  return (
    <MuiModal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...rest}
      onBackdropClick={onBackdropClick}
      disableBackdropClick={disableBackdropClick}
    >
      <PaperLayout className={classes.paper} seleniumId="modal-wrapper">
        <SpinnerOverlay showOverlay={isLoading && !error}>
          <div className={classes.content}>
            <ModalHeader
              title={title}
              classes={getHeaderClasses(classes)}
              history={history}
              parentUrl={parentUrl}
              HeaderButton={HeaderButton}
            />
            <div className={cn(classes.body, BODY_KEY)}>{error ? <InfoView seleniumId="modal-error" title={error} /> : children}</div>
            {(showFooter && !error) && (
              <ModalActions
                actions={actions}
                classes={getFooterClasses(classes)}
                history={history}
                parentUrl={parentUrl}
                onCloseEffect={onCloseEffect}
              />
            )}
          </div>
        </SpinnerOverlay>
      </PaperLayout>
    </MuiModal>
  );
};

export default memo(Modal);
