import qs from 'query-string';
import { generatePath } from 'react-router';
import _ from 'lodash';
import axios, { CancelTokenSource } from 'axios';
import http, { REQUEST_PREFIX } from 'src/app/services/http';
import { errorsByCode, commonErrorsByStatus } from 'src/app/utils/requestErrors';
import { trimFields } from 'src/app/pages/VirtualIssues/components/IssuesManage/common/utils';


interface Request {
  url: string,
  data: object,
  ignoreError: boolean,
  [key: string]: any;
}

interface Params {
  raw: boolean;
  ignoreError: boolean;
  ignoreParams: boolean;
  isList: boolean;
  contentType: string;
  handleHeader: string;
  customPath: boolean;
  ignoreEmptyBody: boolean;
  method: 'GET' | 'POST' | 'PATCH' | 'HEAD' | 'DELETE';
  shouldTrimData: boolean;
}

type API = <T>(
  path: string,
  httpParams?: { [paramName: string]: string | number | boolean | undefined },
  params?: Partial<Params>,
) => [Promise<T>, string, any, CancelTokenSource];

export const getQueryParams = (path: string, params?: Record<string, any>) => {
  const queryParams = path.split(':').reduce((acc: Record<string, string>, pathParamName) => {
    if (acc[pathParamName]) return _.omit(acc, pathParamName);
    return acc;
  }, params || {});

  return _.isEmpty(queryParams) ? null : queryParams;
};

const handleError = (error: any) => {
  const { status, data, message: responseMessage } = error?.response || {};
  let codeDictionary;
  let dataMessage;
  if (data) {
    const { code, message } = data;
    dataMessage = message;
    codeDictionary = errorsByCode[code];
  }

  return (codeDictionary && codeDictionary(status)) || commonErrorsByStatus[status] || dataMessage || responseMessage;
};

const api: API = (
  path,
  httpParams = {},
  params = {},
) => {
  const { raw = false, method = 'GET', ignoreError = false, isList = false, contentType = '', handleHeader = '', customPath = false, ignoreParams = false, ignoreEmptyBody = false, shouldTrimData = true } = params;

  const initRequest: Request = {
    method,
    url: customPath ? path : generatePath(path, httpParams),
    data: ['POST', 'PATCH'].includes(method) && shouldTrimData ? trimFields(httpParams) : httpParams,
    ignoreError,
    responseType: contentType.includes('text') ? 'text' : 'json',
  };

  let { url } = initRequest;

  if (raw) {
    initRequest.baseURL = '';
  }

  if (method === 'GET') {
    const queryParams = getQueryParams(path, httpParams);
    if (queryParams) url += `?${qs.stringify(httpParams, { encode: false })}`;

    if (!ignoreParams) {
      initRequest.params = httpParams;
    }
  }

  if (contentType) {
    initRequest.headers = {
      'Content-Type': contentType,
    };
  }

  if (!contentType && method === 'PATCH') {
    initRequest.headers = {
      'Content-Type': 'application/merge-patch+json',
    };
  }

  const { CancelToken } = axios;
  const source = CancelToken.source();
  initRequest.cancelToken = source.token;

  return [
    http
      .request(initRequest)
      .then(res => {
        if (res && res.status) {
          if (res.status >= 200 && res.status <= 299) {
            if (isList) {
              return {
                items: res.data,
                itemsCount: parseInt(res.headers['x-total-count'] || res.data.length || 0, 10),
              };
            }

            if (handleHeader) {
              return res.headers[handleHeader];
            }

            if (_.isEmpty(res.data) && !ignoreEmptyBody) {
              return Promise.reject({
                response: {
                  data: { message: 'Empty response' },
                  status: 404,
                },
              });
            }
            return res.data;
          }
          return Promise.reject(res.data);
        }
        return Promise.reject({ response: { data: { message: 'Empty response' } } });
      })
      .catch(err => {
        const { status } = err?.response || {};
        return Promise.reject({
          response: {
            data: { message: handleError(err) },
            status,
          },
        } || err);
      }),
    `${method}${url}`,
    httpParams?.redirectUrl ?? null,
    source,
  ];
};

export const uploadFile = (path: string, file: any) =>
  axios.post(`${REQUEST_PREFIX}${path}`, file, {
    headers: { 'Content-Type': file.type, 'Content-Disposition': `filename="${file.name}"` },
  })
    .then(res => res.data)
    .catch(err => handleError(err));

export default api;
