import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ITheme } from 'src/app/App';

const useButtonStyles = makeStyles((theme: ITheme) => ({
  wrap: {
    marginTop: 5,
    marginBottom: 5,
  },
  button: {
    color: theme.palette.typical.black,
    width: 48,
    '&.selected': {
      color: theme.palette.grey[400],
    },
  },
}));

const MAX_VISIBLE_PAGES = 5;

const getRange = (totalPages: number, currentPage: number) => {
  const start = Math.max(0, currentPage + 1 - MAX_VISIBLE_PAGES);
  const end = Math.min(totalPages, start + MAX_VISIBLE_PAGES);

  return { start, end };
};

const ButtonSection = (props: any) => {
  const {
    onChangePage,
    page,
    totalPages,
  } = props;
  const classes = useButtonStyles();
  const { start, end } = getRange(totalPages, page);

  return (
    <div className={classes.wrap} data-seleniumid="pagination-actions">
      { _.times(end - start).map((i:number) => {
        const value = i + start;
        const isSelected = value === page;
        return (
          <IconButton
            data-seleniumid={`pagination-actions-btn-${value + 1}`}
            key={value}
            disabled={isSelected}
            className={cn(classes.button, { selected: isSelected })}
            onClick={(event: any) => onChangePage(event, value)}
          >
            <Icon component={() => <Typography variant="button">{value + 1}</Typography>} />
          </IconButton>
        );
      })
      }
    </div>
  );
};


const PaginationActions = (props: any) => {
  const {
    backIconButtonProps,
    count,
    nextIconButtonProps,
    onChangePage,
    page,
    rowsPerPage,
    ...rest
  } = props;

  const handleBackButtonClick = (event: any) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: any) => {
    onChangePage(event, page + 1);
  };

  const totalPages = Math.ceil(count / rowsPerPage);
  if (totalPages <= 1) return null;

  return (
    <div {...rest}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        color="inherit"
        {...backIconButtonProps}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <ButtonSection
        onChangePage={onChangePage} page={page} rowsPerPage={rowsPerPage}
        totalPages={totalPages}
      />
      <IconButton
        onClick={handleNextButtonClick}
        disabled={count !== -1 ? page >= Math.ceil(count / rowsPerPage) - 1 : false}
        color="inherit"
        {...nextIconButtonProps}
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
};

export default PaginationActions;
